import {
  SACard,
  SAIcon,
  SAIcons,
  SAIconSize,
  SASeparator,
  SAText,
} from '@saux/design-system-react';
import React, { useEffect } from 'react';
import { formatMoney, formatPhone } from '../../util/formatters';
import useIsResponsive from '../../util/useIsResponsive';
import AgencyBilledModalWrapper, {
  Alert,
  ButtonWrapper,
  DisclaimerSeparator,
  DisclaimerWrapper,
  LoaderErrorContainer,
  ModalAgentLabel,
  ModalAgentName,
  ModalBody,
  ModalCloseButton,
  ModalContainer,
  ModalDetailsLink,
  ModalDetailsRow,
  ModalDetailsTitle,
  ModalHeader,
  ModalHeaderClose,
  ModalTitle,
} from './AgencyBilledPaymentModalStyles';

export interface AgencyContactInfo {
  name: string;
  phone: string;
  email: string;
}

interface AgencyBilledPaymentModalProps {
  loading: boolean;
  error: boolean;
  agencyContactInfo: AgencyContactInfo;
  setDisplayModal: Function;
  amountDue: string | undefined;
  proceedClickHandler: Function;
  showDisclaimer: boolean;
}

interface ModalProps {
  isResponsive: boolean;
  children: React.ReactNode;
  setDisplayModal: Function;
}

interface ResponsiveModalProps {
  children: React.ReactNode;
  setDisplayModal: Function;
}

const disclaimerText =
  ": I understand that by clicking 'PROCEED', I will be transferred to One Inc to collect my payment information and a 2.5% processing fee will apply to any payments made with my credit or debit card. One Inc is a third-party payment solutions provider who will make a payment to State Auto on behalf of the insured. Payments made by ACH/eCHECK are not assessed this fee.";

export const ResponsiveModal = ({
  children,
  setDisplayModal,
}: ResponsiveModalProps) => {
  return (
    <ModalContainer>
      <ModalHeader>
        <ModalTitle
          text="Make A Payment"
          weight="bold"
          type="heading-3"
          colorVariant="default"
        />
        <ModalHeaderClose onClick={() => setDisplayModal(false)}>
          <SAIcon
            icon={SAIcons.x}
            size={SAIconSize.medium}
            colorVariant="dark"
          />
        </ModalHeaderClose>
      </ModalHeader>
      {children}
    </ModalContainer>
  );
};

export const ModalWrapper = ({
  isResponsive,
  children,
  setDisplayModal,
}: ModalProps) => {
  if (isResponsive) {
    return (
      <ResponsiveModal setDisplayModal={setDisplayModal}>
        {children}
      </ResponsiveModal>
    );
  }
  return (
    <SACard variant="simple" title="Make A Payment" width={632}>
      {children}
    </SACard>
  );
};

const AgencyBilledPaymentModal = ({
  loading,
  error,
  agencyContactInfo,
  setDisplayModal,
  amountDue,
  proceedClickHandler,
  showDisclaimer,
}: AgencyBilledPaymentModalProps) => {
  const isResponsive = useIsResponsive();

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  if (loading || error) {
    return (
      <AgencyBilledModalWrapper
        onClickHandler={(e: React.MouseEvent) => e.stopPropagation()}
      >
        <ModalWrapper
          isResponsive={isResponsive}
          setDisplayModal={setDisplayModal}
        >
          <LoaderErrorContainer>
            {loading ? 'Loading...' : 'Error.'}
          </LoaderErrorContainer>
        </ModalWrapper>
      </AgencyBilledModalWrapper>
    );
  }

  const amountDueText: string = amountDue
    ? `Payment Amount Due: ${formatMoney(amountDue)}`
    : '$0.00';

  return (
    <AgencyBilledModalWrapper
      onClickHandler={(e: React.MouseEvent) => e.stopPropagation()}
      zIndex={2147483647}
    >
      <ModalWrapper
        isResponsive={isResponsive}
        setDisplayModal={setDisplayModal}
      >
        <ModalBody>
          {!isResponsive && (
            <Alert severity="info" icon="info">
              The policies in your account are agency billed, please reach out
              your agent for any billing or payment questions or to make a
              payment.
            </Alert>
          )}
          <SAText text={amountDueText} type="heading-4" weight="bold" />
          <ModalAgentLabel
            variant="highlight-outline"
            label="Your Agency"
            fontWeight="bold"
          />
          <ModalAgentName
            text={agencyContactInfo?.name}
            type="heading-4"
            weight="bold"
            colorVariant="default"
          />
          <ModalDetailsRow>
            <ModalDetailsTitle
              text="Phone Number:"
              weight="bold"
              type="paragraph"
            />
            <ModalDetailsLink
              href={`tel:${(agencyContactInfo?.phone).replace(/[^0-9+]/g, '')}`}
            >
              {formatPhone(agencyContactInfo?.phone)}
            </ModalDetailsLink>
          </ModalDetailsRow>
          <ModalDetailsRow>
            <ModalDetailsTitle
              text={isResponsive ? 'Email:' : 'Email Address:'}
              weight="bold"
              type="paragraph"
            />
            <ModalDetailsLink href={agencyContactInfo?.email || ''}>
              {agencyContactInfo?.email}
            </ModalDetailsLink>
          </ModalDetailsRow>
        </ModalBody>
        <ButtonWrapper>
          <ModalCloseButton
            label="CANCEL"
            variant="link-large"
            color="default"
            fullWidth={false}
            onClick={() => setDisplayModal(false)}
          />
          <ModalCloseButton
            label="PROCEED"
            variant="primary"
            fullWidth={false}
            onClick={() => {
              proceedClickHandler();
              setDisplayModal(false);
            }}
          />
        </ButtonWrapper>
        {showDisclaimer && (
          <>
            <DisclaimerSeparator>
              <SASeparator horizontal display="block" />
            </DisclaimerSeparator>
            <DisclaimerWrapper>
              <SAText text="Disclaimer" type="small" weight="bold" />
              <SAText text={disclaimerText} type="small" />
            </DisclaimerWrapper>
          </>
        )}
      </ModalWrapper>
    </AgencyBilledModalWrapper>
  );
};

export default AgencyBilledPaymentModal;
