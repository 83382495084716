import { SAUXTheme, SAButton } from '@saux/design-system-react';
import styled from 'styled-components';

interface ButtonBoxProps {
  isResponsive: boolean;
}

export const ButtonBox = styled.div<ButtonBoxProps>`
  display: flex;
  flex-direction: row;
  margin-top: auto;
  margin-bottom: 20px;
  margin-left: ${(props) => (props.isResponsive ? '23px' : 'auto')};
  margin-right: 23px;
  gap: 15px;

  & > button:last-child {
    font-size: 18px;
    line-height: 23px;
    min-width: 158px;
  }
`;

export const ExitBox = styled.div`
  button {
    background-color: #ffffff;
    border: none;
  }
`;

export const FillBox = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const CancelButton = styled(SAButton)`
  color: #3b3b3b;
`;

export const HiddenAnchor = styled.a`
  display: none;
`;

export const CloseButtonWrapper = styled.div`
  ${({ theme }: { theme: SAUXTheme }) =>
    `
    display: flex;
    position: absolute;
    margin-top: 25px;
    margin-right: 15px;
    top: 0;
    right: 0;
    justify-content: flex-end;
    padding-right: ${theme.size.spacer.medium};

    p {
      cursor: pointer;
    }
  `}
`;

interface CenterProps {
  isResponsive: boolean;
}

export const Center = styled.div<CenterProps>`
  margin: auto 20px;
  display: flex;
  flex-direction: column;
  & > * {
    text-align: center;
  }

  & > svg {
    margin-bottom: ${(props) => (props.isResponsive ? 30 : 20)}px;
  }
`;

export const StandardText = styled.span`
  margin-top: 22px;
  font-size: 16px;
  line-height: 27px;
  align-content: center;
`;

export const PhoneLink = styled.a`
  text-decoration: none;
  color: hsl(205, 99%, 39%);
  font-weight: bold;
`;

export const BoldText = styled.span`
  font-weight: bold;
`;

interface SpreadRowProps {
  isResponsive: boolean;
}

export const SpreadRow = styled.div<SpreadRowProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  padding-left: ${(props) => (props.isResponsive ? 15 : 0)}px;
`;

export const DesktopModalHeader = styled.div`
  margin-top: 30px;
  margin-left: 30px;
`;
