import { SACard, SAText } from '@saux/design-system-react';
import React from 'react';
import MultiplePoliciesModal from '../multiplePoliciesModal';
import { Policy } from '../policies/Policies';
import {
  LabelTextWrapper,
  LinksContainer,
  LinkWrapper,
} from './QuickLinks.styles';
import RoadsideAssistanceModal from '../roadsideAssistanceModal';

export type QuickLink = {
  icon: JSX.Element;
  label: string;
  labelId?: string;
  onClickHandler: Function;
};

export type QuickLinksProps = {
  links: QuickLink[];
  displayPolicyModal: boolean;
  setPolicyNum: Function;
  policies: Policy[];
  policyDocOnClick: Function;
  togglePolicyModal: Function;
  displayRoadsideModal: boolean;
  toggleRoadsideModal: Function;
};

function QuickLinks({
  links,
  displayPolicyModal,
  setPolicyNum,
  policies,
  policyDocOnClick,
  togglePolicyModal,
  displayRoadsideModal,
  toggleRoadsideModal,
}: QuickLinksProps) {
  return (
    <>
      {displayRoadsideModal && (
        <RoadsideAssistanceModal toggleModalDisplay={toggleRoadsideModal} />
      )}
      {displayPolicyModal && (
        <MultiplePoliciesModal
          setPolicyNum={setPolicyNum}
          policies={policies}
          policyDocOnClick={policyDocOnClick}
          togglePolicyModal={togglePolicyModal}
        />
      )}
      <SACard variant="standard" title="Quick Links">
        <nav>
          <LinksContainer>
            {links.map((link: QuickLink) => (
              <li key={link.label}>
                <LinkWrapper>
                  {link.icon}
                  <LabelTextWrapper id={link.labelId}>
                    <SAText
                      type="standard"
                      weight="bold"
                      text={link.label}
                      onClick={() => link.onClickHandler()}
                    />
                  </LabelTextWrapper>
                </LinkWrapper>
              </li>
            ))}
          </LinksContainer>
        </nav>
      </SACard>
    </>
  );
}

export default QuickLinks;
