import { ApolloQueryResult } from 'apollo-client';
import AWSAppSyncClient from 'aws-appsync';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import ProductTour from './ProductTour';
import {
  GET_PRODUCT_TOUR_STATUS,
  GET_PRODUCT_TOUR_STATUSVariables,
} from '../../generated/GET_PRODUCT_TOUR_STATUS';
import {
  UPDATE_SPECIAL_EVENT_TRACKING,
  UPDATE_SPECIAL_EVENT_TRACKINGVariables,
} from '../../generated/UPDATE_SPECIAL_EVENT_TRACKING';

const getProductTourStatusQuery = loader(
  '../../graphql/queries/Get_Product_Tour_Status.graphql',
);
const updateProductTourStatusMutation = loader(
  '../../graphql/mutation/Update_Special_Event_Tracking.graphql',
);

interface ProductTourDataContainerProps {
  accountNumber: string;
  awsAppSyncClient: AWSAppSyncClient<any>;
  useNavigation: Function;
}

export function fetchProductTourStatus(
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string,
): Promise<ApolloQueryResult<GET_PRODUCT_TOUR_STATUS>> {
  const variables: GET_PRODUCT_TOUR_STATUSVariables = {
    account_number: accountNumber,
  } as GET_PRODUCT_TOUR_STATUSVariables;

  const queryResult: Promise<
    ApolloQueryResult<GET_PRODUCT_TOUR_STATUS>
  > = awsAppSyncClient.query({
    query: getProductTourStatusQuery,
    variables,
  }) as Promise<ApolloQueryResult<GET_PRODUCT_TOUR_STATUS>>;
  return queryResult;
}

const updateProductTourStatus = (
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string | undefined,
) => {
  const variables: UPDATE_SPECIAL_EVENT_TRACKINGVariables = {
    account_number: accountNumber,
    field_name: 'c360_product_tour',
  } as UPDATE_SPECIAL_EVENT_TRACKINGVariables;

  return awsAppSyncClient.mutate({
    mutation: updateProductTourStatusMutation,
    variables,
  }) as Promise<ApolloQueryResult<UPDATE_SPECIAL_EVENT_TRACKING>>;
};

const ProductTourDataContainer = ({
  accountNumber,
  awsAppSyncClient,
  useNavigation,
}: ProductTourDataContainerProps) => {
  const [productTourCompleted, setProductTourCompleted] = useState<
    boolean | undefined
  >();
  const [displayBanner, setDisplayBanner] = useState<boolean>(false);

  useEffect(() => {
    fetchProductTourStatus(awsAppSyncClient, accountNumber)
      .then((apolloQueryResult: ApolloQueryResult<GET_PRODUCT_TOUR_STATUS>) => {
        if (apolloQueryResult.data.specialEventTracking) {
          setProductTourCompleted(
            !!apolloQueryResult.data.specialEventTracking.c360_product_tour,
          );
          setDisplayBanner(
            !apolloQueryResult.data.specialEventTracking.c360_product_tour,
          );
        } else {
          setDisplayBanner(true);
        }
      })
      .catch((err: Error) => {
        console.error('GET_PRODUCT_TOUR_STATUS ERROR: ', err);
      });
  }, [accountNumber]);

  useEffect(() => {
    if (productTourCompleted) {
      updateProductTourStatus(awsAppSyncClient, accountNumber)
        .then(
          (queryResult: ApolloQueryResult<UPDATE_SPECIAL_EVENT_TRACKING>) => {
            if (
              queryResult?.data?.specialEventTracking?.response !== 'Success'
            ) {
              throw new Error();
            }
          },
        )
        .catch((err: Error) => {
          console.error('UPDATE_PRODUCT_TOUR_STATUS_MUTATE ERROR: ', err);
        });
    }
  }, [productTourCompleted]);

  return (
    <ProductTour
      useNavigation={useNavigation}
      productTourCompleted={productTourCompleted}
      setProductTourCompleted={setProductTourCompleted}
      displayBanner={displayBanner}
      setDisplayBanner={setDisplayBanner}
    />
  );
};

export default ProductTourDataContainer;
