import {
  SARadioGroup,
  SARadio,
  SABox,
  SAText,
  SACard,
  SAButton,
  SAIcons,
  SAIconSize,
  SAIcon,
} from '@saux/design-system-react';
import React, { useState } from 'react';
import getConfig, { Env } from '../../../aws_exports';
import {
  ButtonBox,
  DesktopModalHeader,
  CancelButton,
  FillBox,
  SpreadRow,
  ExitBox,
} from './ModalBody.styles';

interface DefaultBodyProps {
  toggleModal: Function;
  setModalBodyType: Function;
  isResponsive: boolean;
}

const config = getConfig(process.env.REACT_APP_ENV as Env);

const DefaultBody = ({
  toggleModal,
  setModalBodyType,
  isResponsive,
}: DefaultBodyProps) => {
  const [reason, setReason] = useState('mechanicalIssue');

  const modalHeader = (
    <SpreadRow isResponsive={isResponsive}>
      <SAText
        weight="bold"
        text="Let’s get you to the right place!"
        type={isResponsive ? 'heading-4' : 'heading-3'}
      />
      {isResponsive && (
        <ExitBox>
          <SAIcon
            icon={SAIcons.x}
            colorVariant="dark"
            size={SAIconSize.medium}
            onClick={(event) => {
              event.stopPropagation();
              toggleModal();
            }}
            test-attr="roadside-assistance-modal-default-exit-button"
          />
        </ExitBox>
      )}
    </SpreadRow>
  );

  return (
    <FillBox>
      {isResponsive ? (
        <SACard variant="minimal">{modalHeader}</SACard>
      ) : (
        <DesktopModalHeader>{modalHeader}</DesktopModalHeader>
      )}
      <SABox marginTop="large" marginLeft="large">
        <SARadioGroup
          value={reason}
          onChange={(event) => {
            setReason(event.target.value);
          }}
          variant="standard"
          direction="vertical"
          label="Is your vehicle disabled due to?"
          id="roadsideAssistanceModal-disabledReason"
        >
          <SAText text="Mechanical breakdown/flat tire/lock out etc" />
          <SARadio variant="standard" label="Yes" value="mechanicalIssue" />
          <SARadio variant="standard" label="No" value="other" />
        </SARadioGroup>
      </SABox>
      <ButtonBox isResponsive={isResponsive}>
        <CancelButton
          fullWidth={isResponsive}
          variant="link"
          label="Cancel"
          onClick={() => toggleModal()}
          test-attr="roadside-assistance-modal-default-cancel-button"
        />
        <SAButton
          variant="primary"
          fullWidth={isResponsive}
          label="Continue"
          onClick={() => {
            if (isResponsive && reason === 'mechanicalIssue')
              window.open(config.roadside_assist_phone, '_self');
            else if (reason === 'mechanicalIssue') setModalBodyType('quest');
            else setModalBodyType('accident');
          }}
          test-attr="roadside-assistance-modal-default-continue-button"
        />
      </ButtonBox>
    </FillBox>
  );
};

export default DefaultBody;
