import styled from 'styled-components';
import customBase from '../../util/global.theme';

export const DesktopStopPaymentModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

export const ResponsiveStopPaymentModalWrapper = styled.div`
  display: block;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
`;

export const Spacer = styled.div`
  height: 20px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  margin-bottom: -30px;

  @media screen and (min-width: ${customBase.global.breakpoints.medium}) {
    justify-content: space-around;
  }
`;

export const CancelWrapper = styled.div`
  margin-right: 30px;
`;
