import React, { useState } from 'react';
import { SAAlert, SACard, SASeparator } from '@saux/design-system-react';
import { useFeatureFlag } from '../../util/hooks';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import generateOpenOneIncURL from '../../util/openOneIncURL/generateOpenOneIncURL';
import {
  MyAgency,
  OctoCard,
  PaymentMethod,
  Policies,
  QuickLinks,
  NextPayment,
} from '..';
import {
  ResponsiveContainer,
  ResponsiveStyledMyAgency,
  StyledLeftColumn,
  StyledNextPaymentDiv,
  StyledPaymentsDiv,
  StyledOverviewContainer,
  StyledPaymentContainer,
  StyledPolicyContainer,
  StyledQuickLinks,
  StyledRightColumn,
  StyledSeparator,
} from './PageStyles';
import {
  OctoSunsetCardProps,
  PageDisplayProps,
} from '../../sharedTypes/Overview';
import AgencyBilledQuantity from '../../sharedTypes/AgencyBilledQuantity';
import OutageModal from '../outageModal/OutageModal';

const OctoSunsetCard = ({
  awsAppSyncClient,
  accountNumber,
}: OctoSunsetCardProps) => {
  const { flagDetails } = useFeatureFlag('OctoSidebarCard');
  if (!flagDetails?.enabled) {
    return <></>;
  }
  return (
    <OctoCard
      awsAppSyncClient={awsAppSyncClient}
      accountNumber={accountNumber}
    />
  );
};

const Responsive = ({
  session,
  agencyBilledQuantity,
  loading,
  error,
  lastDayToPayInfo,
}: PageDisplayProps) => {
  const [showOutageModal, setShowOutageModal] = useState<boolean>(false);
  const { flagDetails: BillingCenterOutage } = useFeatureFlag(
    'BillingCenterOutage',
  );
  const { flagDetails: SSOneINCURL } = useFeatureFlag(
    'OneINCSelfServiceMuleURL',
  );

  if (loading || error) {
    return (
      <ResponsiveContainer>
        <StyledOverviewContainer>
          <StyledLeftColumn>
            <OctoSunsetCard
              awsAppSyncClient={session.awsAppSyncClient}
              accountNumber={session.accountNumber}
            />
            <StyledPaymentContainer>
              {loading ? 'Loading...' : 'Error'}
            </StyledPaymentContainer>
          </StyledLeftColumn>
        </StyledOverviewContainer>
      </ResponsiveContainer>
    );
  }

  return (
    <>
      {showOutageModal && (
        <OutageModal
          component="Modify Payment Method"
          setModal={setShowOutageModal}
        />
      )}
      <ResponsiveContainer>
        <StyledOverviewContainer>
          <StyledLeftColumn>
            <OctoSunsetCard
              awsAppSyncClient={session.awsAppSyncClient}
              accountNumber={session.accountNumber}
            />
            <StyledPaymentContainer>
              {agencyBilledQuantity === AgencyBilledQuantity.All ? (
                <SAAlert severity="info">
                  The policies in your account are agency billed, please reach
                  out to your agent for any billing or payment questions or to
                  make a payment.
                </SAAlert>
              ) : (
                <>
                  <StyledNextPaymentDiv>
                    <SACard
                      variant="standard"
                      title="Payment"
                      actionLabel="Edit Payment Info"
                      actionLabelClickHandler={() => {
                        if (
                          BillingCenterOutage &&
                          BillingCenterOutage.enabled
                        ) {
                          setShowOutageModal(true);
                        } else {
                          generateOpenOneIncURL(
                            session.awsAppSyncClient,
                            session.accountNumber,
                            null,
                            SSOneINCURL?.enabled,
                            session.sourceSystemCode,
                          );
                        }
                        simpleGTMDataLayer({
                          event: `Overview-ModifyPaymentButton-click`,
                          event_action: 'Button Click',
                          event_category: 'Overview',
                          event_label: 'Overview Modify Payment Button Click',
                        });
                        simpleGTMDataLayer({
                          event: `Snowplow-Overview-ModifyPaymentButton-click`,
                          event_action: 'Snowplow Button Click',
                          event_category: 'Snowplow Overview',
                          event_label:
                            'Snowplow Overview Modify Payment Button Click',
                        });
                      }}
                    >
                      <StyledPaymentsDiv>
                        <NextPayment
                          accountNumber={session.accountNumber}
                          awsAppSyncClient={session.awsAppSyncClient}
                          sourceSystemCode={session.sourceSystemCode}
                          agencyBilledQuantity={agencyBilledQuantity}
                          lastDayToPayInfo={lastDayToPayInfo}
                        />
                        <StyledSeparator>
                          <SASeparator
                            horizontal
                            display={{ xs: 'block', sm: 'none' }}
                          />
                        </StyledSeparator>
                        <PaymentMethod
                          accountNumber={session.accountNumber}
                          awsAppSyncClient={session.awsAppSyncClient}
                          lastDayToPayInfo={lastDayToPayInfo}
                        />
                      </StyledPaymentsDiv>
                    </SACard>
                  </StyledNextPaymentDiv>
                </>
              )}
            </StyledPaymentContainer>
            <StyledQuickLinks>
              <QuickLinks
                accountNumber={session.accountNumber}
                awsAppSyncClient={session.awsAppSyncClient}
                claimsAppSyncClient={session.claimsAppSyncClient}
              />
            </StyledQuickLinks>
            <StyledPolicyContainer>
              <SACard
                variant="standard"
                title="Policies"
                actionLabel="VIEW ALL"
                actionLabelClickHandler={() => {
                  window.open('/policy', '_self');
                  simpleGTMDataLayer({
                    event: `Overview-ViewAllPolicies-click`,
                    event_action: 'View All Policies Click',
                    event_category: 'Overview',
                    event_label: 'Overview View All Policies Click',
                  });
                  simpleGTMDataLayer({
                    event: `Snowplow-Overview-ViewAllPolicies-click`,
                    event_action: 'Snowplow View All Policies Click',
                    event_category: 'Snowplow Overview',
                    event_label: 'Snowplow Overview View All Policies Click',
                  });
                }}
              >
                <Policies
                  accountNumber={session.accountNumber}
                  awsAppSyncClient={session.awsAppSyncClient}
                  useNavigation={session.useNavigation}
                />
              </SACard>
            </StyledPolicyContainer>
          </StyledLeftColumn>
          <StyledRightColumn>
            <ResponsiveStyledMyAgency className="overview-my-agent-tour">
              <SACard variant="standard" title="My Agency" height={276}>
                <MyAgency
                  accountNumber={session.accountNumber}
                  awsAppSyncClient={session.awsAppSyncClient}
                />
              </SACard>
            </ResponsiveStyledMyAgency>
          </StyledRightColumn>
        </StyledOverviewContainer>
      </ResponsiveContainer>
    </>
  );
};

export default Responsive;
