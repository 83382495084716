import { SAUXTheme } from '@saux/design-system-react';
import styled from 'styled-components';
import customBase from '../../util/global.theme';

export const MyAgentWrapper = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    display: flex;
    flex-direction: column;
    margin-left: ${theme.size.spacer.medium};

    @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
      margin-left: ${theme.size.spacer.xxs};
    }
  `}
`;

export const Name = styled.p`
  ${({ theme }: { theme: SAUXTheme }) => `
    font-size: ${theme.font.primary.size.large};
    font-weight: ${theme.font.primary.weight.bold};
    color: #041E41;
    margin-bottom: ${theme.size.spacer.large};
    margin-top: ${theme.size.spacer.medium};

    @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
      margin-top: 0;
      margin-bottom: ${theme.size.spacer.medium};
    }
  `}
`;

export const PhoneEmailWrapper = styled.div`
  flex-direction: column;
`;

export const PhoneWrapper = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    flex-direction: column;
    margin-bottom: ${theme.size.spacer.large};

    @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
      margin-bottom: ${theme.size.spacer.medium};
    }
  `}
`;

export const PhoneHeader = styled.p`
  ${({ theme }: { theme: SAUXTheme }) =>
    `
    font-weight: ${theme.font.primary.weight.bold};
    color: #041E41;
  `}
`;

export const PhoneNumber = styled.a`
  font-style: normal;
  color: #0173c6;
  text-decoration: none;
`;

export const EmailWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EmailHeader = styled.p`
  ${({ theme }: { theme: SAUXTheme }) => `
    font-weight: ${theme.font.primary.weight.bold};
    color: #041E41;
  `}
`;

export const Email = styled.a`
  font-style: normal;
  color: #0173c6;
  text-decoration: none;
`;
