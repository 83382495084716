import { SAText, SAIcons, SAIconSize, SAIcon } from '@saux/design-system-react';
import React from 'react';
import { ReactComponent as ColorTowTruck } from '../../../assets/images/ColorTowTruck.svg';
import {
  FillBox,
  BoldText,
  Center,
  StandardText,
  PhoneLink,
  CloseButtonWrapper,
  ExitBox,
} from './ModalBody.styles';

interface AccidentBodyProps {
  toggleModal: Function;
  isResponsive: boolean;
}

const AccidentBody = ({ toggleModal, isResponsive }: AccidentBodyProps) => {
  let messageText;
  if (isResponsive)
    messageText = (
      <StandardText>
        We&apos;re here to help, call us at <br />
        <PhoneLink href="tel:877-722-5246">1-877-SA-CLAIM</PhoneLink>.
        You&apos;ll have the option to <br />
        tow your vehicle to your preferred facility, <br />
        or, we&apos;ll help you find one
      </StandardText>
    );
  else
    messageText = (
      <StandardText>
        We&apos;re here to help, call us at <BoldText>1-877-SA-CLAIM</BoldText>.{' '}
        <br />
        You&apos;ll have the option to tow your vehicle to your <br />
        preferred facility, or, we&apos;ll help you find one.
      </StandardText>
    );
  return (
    <FillBox>
      <CloseButtonWrapper>
        <ExitBox>
          <SAIcon
            icon={SAIcons.x}
            colorVariant="dark"
            size={SAIconSize.medium}
            onClick={(event) => {
              event.stopPropagation();
              toggleModal();
            }}
            test-attr="roadside-assistance-modal-accident-exit-button"
          />
        </ExitBox>
      </CloseButtonWrapper>
      <Center isResponsive={isResponsive}>
        <ColorTowTruck title="Tow Truck" width="100%" height="100px" />
        <SAText
          text="We’re sorry you’ve been in an accident!"
          weight="bold"
          type="heading-2"
        />
        {messageText}
      </Center>
    </FillBox>
  );
};

export default AccidentBody;
