import { SAUXTheme } from '@saux/design-system-react';
import styled from 'styled-components';
import customBase from '../../util/global.theme';

export const DesktopModalBody = styled.div`
  display: none;

  @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
    display: block;
    width: 780px;
    height: 500px;

    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
`;

export const MobileModalBody = styled.div`
  ${({ theme }: { theme: SAUXTheme }) =>
    `
    display: block;
    height: 100%;
    width: 100%;
    background-color: ${theme.color.container.background};

    @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
      display: none;
    }
  `}
`;

export const CloseButtonWrapper = styled.span`
  ${({ theme }: { theme: SAUXTheme }) =>
    `
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    justify-content: flex-end;
    padding: ${theme.size.spacer.medium} ${theme.size.spacer.medium} 0px 0px;

    p {
      cursor: pointer;
    }
  `}
`;

export const TopSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  p:first-child {
    margin-bottom: 0;
  }

  @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
    width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
    flex-direction: row;
    justify-content: center;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;
