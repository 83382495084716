import { SAText } from '@saux/design-system-react';
import styled from 'styled-components';
import customBase from '../../util/global.theme';

export const PastDueCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: auto;
  margin-bottom: 25px;
  @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
    width: auto;
  }
`;

export const PastDueAmountWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PastDueToolTipTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ToolTipIconWrapper = styled.div`
  margin-right: 10px;
`;

export const PastDueHeader = styled(SAText)`
  padding-top: 2px;
`;
