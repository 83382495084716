import padStart from 'lodash.padstart';

const padZeros = (num: number | string) => padStart(num.toString(), 2, '0');

export default (
  date?: string | number | Date | null,
  defaultReturn?: string,
) => {
  const formattedDate = date instanceof Date ? date : new Date(date as string);
  if (formattedDate.toString() === 'Invalid Date') {
    return defaultReturn;
  }

  const d = formattedDate;
  return `${padZeros(String(d.getUTCMonth() + 1))}/${padZeros(
    d.getUTCDate(),
  )}/${d.getUTCFullYear()}`;
};
