import React, { useState } from 'react';
import { RoadsideModal, ModalContent } from './RoadsideAssistanceModal.styles';
import { DefaultBody, AccidentBody, QuestBody } from './modalBodies';
import useIsResponsive from '../../util/useIsResponsive';

interface RoadsideAssistanceModalProps {
  toggleModalDisplay: Function;
}

export const RoadsideAssistanceModal = ({
  toggleModalDisplay,
}: RoadsideAssistanceModalProps) => {
  const [modalBodyType, setModalBodyType] = useState<
    'default' | 'accident' | 'quest'
  >('default');
  const isResponsive = useIsResponsive();
  let modalBody: JSX.Element;
  switch (modalBodyType) {
    case 'quest':
      modalBody = <QuestBody toggleModal={toggleModalDisplay} />;
      break;
    case 'accident':
      modalBody = (
        <AccidentBody
          isResponsive={isResponsive}
          toggleModal={toggleModalDisplay}
        />
      );
      break;
    default:
      modalBody = (
        <DefaultBody
          isResponsive={isResponsive}
          toggleModal={toggleModalDisplay}
          setModalBodyType={setModalBodyType}
        />
      );
      break;
  }
  return <ModalContent modalBodyType={modalBodyType}>{modalBody}</ModalContent>;
};

const Modal = ({ toggleModalDisplay }: RoadsideAssistanceModalProps) => (
  <RoadsideModal onClickHandler={(e: React.MouseEvent) => e.stopPropagation()}>
    <RoadsideAssistanceModal toggleModalDisplay={toggleModalDisplay} />
  </RoadsideModal>
);

export default Modal;
