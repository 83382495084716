import React, { useState } from 'react';
import {
  SAAlert,
  SACard,
  SAIcon,
  SAIcons,
  SAIconSize,
} from '@saux/design-system-react';
import { useFeatureFlag } from '../../util/hooks';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import generateOpenOneIncURL from '../../util/openOneIncURL/generateOpenOneIncURL';
import {
  MyAgency,
  OctoCard,
  PaymentMethod,
  Policies,
  QuickLinks,
  NextPayment,
} from '..';
import {
  AlertHolder,
  DashLine,
  DesktopContainer,
  StyledLeftColumn,
  StyledMyAgency,
  StyledNextPaymentDiv,
  StyledOverviewContainer,
  StyledPaymentContainer,
  StyledPaymentsDiv,
  StyledPolicyContainer,
  StyledQuickLinks,
  StyledRightColumn,
  GeneralMessageModal,
  LeftGeneralMessageModalContainer,
  GeneralMessageModalWrapper,
  InfoIconSizing,
} from './PageStyles';
import {
  OctoSunsetCardProps,
  PageDisplayProps,
} from '../../sharedTypes/Overview';
import OutageModal from '../outageModal/OutageModal';
import AgencyBilledQuantity from '../../sharedTypes/AgencyBilledQuantity';

const OctoSunsetCard = ({
  awsAppSyncClient,
  accountNumber,
}: OctoSunsetCardProps) => {
  const { flagDetails } = useFeatureFlag('OctoSidebarCard');
  if (!flagDetails?.enabled) {
    return <></>;
  }
  return (
    <OctoCard
      awsAppSyncClient={awsAppSyncClient}
      accountNumber={accountNumber}
    />
  );
};

const Desktop = ({
  session,
  agencyBilledQuantity,
  isAgencyBilled,
  loading,
  error,
  lastDayToPayInfo,
  isMigrated,
}: PageDisplayProps) => {
  const [showOutageModal, setShowOutageModal] = useState<boolean>(false);
  const { flagDetails: BillingCenterOutage } = useFeatureFlag(
    'BillingCenterOutage',
  );
  const { flagDetails: SSOneINCURL } = useFeatureFlag(
    'OneINCSelfServiceMuleURL',
  );
  if (loading || error) {
    return (
      <DesktopContainer>
        <StyledOverviewContainer>
          <StyledLeftColumn>
            <StyledPaymentContainer>
              {loading ? 'Loading...' : 'Error'}
            </StyledPaymentContainer>
          </StyledLeftColumn>
        </StyledOverviewContainer>
      </DesktopContainer>
    );
  }

  return (
    <>
      {showOutageModal && (
        <OutageModal
          component="Modify Payment Method"
          setModal={setShowOutageModal}
        />
      )}
      <DesktopContainer>
        {isMigrated && (
          <GeneralMessageModal>
            <LeftGeneralMessageModalContainer>
              <GeneralMessageModalWrapper>
                <InfoIconSizing>
                  <SAIcon
                    icon={SAIcons.information}
                    size={SAIconSize.medium}
                    colorVariant="#189ecc"
                  />
                </InfoIconSizing>
                <div>
                  <div>
                    We appreciate your business! As we explained in our previous
                    correspondence, we are working closely with your State Auto
                    agent to ensure a smooth transition to Safeco Insurance. If
                    you have questions, please contact the agent listed on your
                    policy documents.
                  </div>
                </div>
              </GeneralMessageModalWrapper>
            </LeftGeneralMessageModalContainer>
          </GeneralMessageModal>
        )}
        <StyledOverviewContainer>
          <StyledLeftColumn>
            <StyledPaymentContainer>
              {agencyBilledQuantity === AgencyBilledQuantity.All && (
                <AlertHolder>
                  <SAAlert severity="info">
                    The policies in your account are agency billed, please reach
                    out to your agent for any billing or payment questions or to
                    make a payment.
                  </SAAlert>
                </AlertHolder>
              )}
              {agencyBilledQuantity === AgencyBilledQuantity.Partial && (
                <AlertHolder>
                  <SAAlert severity="info">
                    A policy on this account uses agency billing, please reach
                    out to your agent for any billing or payment questions or to
                    make a payment.
                  </SAAlert>
                </AlertHolder>
              )}
              {isAgencyBilled && <AlertHolder />}
              {!isAgencyBilled && (
                <>
                  <StyledNextPaymentDiv>
                    <SACard
                      data-testid="next-payment-card-overview-page"
                      variant="standard"
                      title="Payment"
                      actionLabel="Edit Payment Info"
                      actionLabelClickHandler={() => {
                        if (
                          BillingCenterOutage &&
                          BillingCenterOutage.enabled
                        ) {
                          setShowOutageModal(true);
                        } else {
                          generateOpenOneIncURL(
                            session.awsAppSyncClient,
                            session.accountNumber,
                            null,
                            SSOneINCURL?.enabled,
                            session.sourceSystemCode,
                          );
                        }
                        simpleGTMDataLayer({
                          event: `Overview-ModifyPaymentButton-click`,
                          event_action: 'Button Click',
                          event_category: 'Overview',
                          event_label: 'Overview Modify Payment Button Click',
                        });
                        simpleGTMDataLayer({
                          event: `Snowplow-Overview-ModifyPaymentButton-click`,
                          event_action: 'Snowplow Button Click',
                          event_category: 'Snowplow Overview',
                          event_label:
                            'Snowplow Overview Modify Payment Button Click',
                        });
                      }}
                    >
                      <StyledPaymentsDiv>
                        <NextPayment
                          accountNumber={session.accountNumber}
                          awsAppSyncClient={session.awsAppSyncClient}
                          sourceSystemCode={session.sourceSystemCode}
                          agencyBilledQuantity={agencyBilledQuantity}
                          lastDayToPayInfo={lastDayToPayInfo}
                        />
                        <DashLine />
                        <PaymentMethod
                          accountNumber={session.accountNumber}
                          awsAppSyncClient={session.awsAppSyncClient}
                          lastDayToPayInfo={lastDayToPayInfo}
                        />
                      </StyledPaymentsDiv>
                    </SACard>
                  </StyledNextPaymentDiv>
                </>
              )}
            </StyledPaymentContainer>
            <StyledPolicyContainer>
              <SACard
                data-testid="view-all-policies"
                variant="standard"
                title="Policies"
                actionLabel="VIEW ALL"
                actionLabelClickHandler={() => {
                  window.open('/policy', '_self');
                  simpleGTMDataLayer({
                    event: `Overview-ViewAllPolicies-click`,
                    event_action: 'View All Policies Click',
                    event_category: 'Overview',
                    event_label: 'Overview View All Policies Click',
                  });
                  simpleGTMDataLayer({
                    event: `Snowplow-Overview-ViewAllPolicies-click`,
                    event_action: 'Snowplow View All Policies Click',
                    event_category: 'Snowplow Overview',
                    event_label: 'Snowplow Overview View All Policies Click',
                  });
                }}
              >
                <Policies
                  accountNumber={session.accountNumber}
                  awsAppSyncClient={session.awsAppSyncClient}
                  useNavigation={session.useNavigation}
                />
              </SACard>
            </StyledPolicyContainer>
          </StyledLeftColumn>
          <StyledRightColumn>
            <OctoSunsetCard
              awsAppSyncClient={session.awsAppSyncClient}
              accountNumber={session.accountNumber}
            />
            <StyledQuickLinks>
              <QuickLinks
                accountNumber={session.accountNumber}
                awsAppSyncClient={session.awsAppSyncClient}
                claimsAppSyncClient={session.claimsAppSyncClient}
              />
            </StyledQuickLinks>
            <StyledMyAgency className="overview-my-agent-tour">
              <SACard variant="standard" title="My Agency" height={276}>
                <MyAgency
                  accountNumber={session.accountNumber}
                  awsAppSyncClient={session.awsAppSyncClient}
                />
              </SACard>
            </StyledMyAgency>
          </StyledRightColumn>
        </StyledOverviewContainer>
      </DesktopContainer>
    </>
  );
};

export default Desktop;
