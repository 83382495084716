import { DocumentNode } from 'graphql';
import { useContext, useState, useCallback } from 'react';
import { FetchResult } from '@apollo/react-hooks';
import { AppsyncClientContext } from '../ClientProvider';

export interface UseMutationProps {
  mutation: DocumentNode;
  customOnResponse?: Function;
  customOnError?: Function;
}

export interface UseMutationResponse<TDataType> {
  data: TDataType | null;
  error: Error | null;
  successWithoutData: boolean | null;
  mutationCall: Function;
}

function useMutation<TVariables, TDataType>({
  mutation,
  customOnResponse,
  customOnError,
}: UseMutationProps): UseMutationResponse<TDataType> {
  const appsyncClient = useContext(AppsyncClientContext);
  const [data, setData] = useState<TDataType | null>(null);
  const [successWithoutData, setSuccessWithoutData] = useState<boolean | null>(
    null,
  );
  const [error, setError] = useState<Error | null>(null);

  const mutationCall = useCallback((variables: TVariables): Promise<
    FetchResult<TDataType>
  > => {
    const appsyncPromise: Promise<
      FetchResult<TDataType>
    > = appsyncClient?.mutate({
      mutation,
      variables,
      fetchPolicy: 'no-cache',
    }) as Promise<FetchResult<TDataType>>;
    appsyncPromise
      .then((response: FetchResult<TDataType>) => {
        if (response.data) setData(response.data);
        else setSuccessWithoutData(true);
        if (customOnResponse) customOnResponse(response);
      })
      .catch((err: Error) => {
        setError(err);
        if (customOnError) customOnError(err);
      });
    return appsyncPromise;
  }, []);

  return {
    data,
    error,
    successWithoutData,
    mutationCall,
  };
}

export default useMutation;
