import React from 'react';
import AgencyBilledQuantity from '../../sharedTypes/AgencyBilledQuantity';
import useIsResponsive from '../../util/useIsResponsive';
import { DesktopTooltip } from '../stopPaymentTooltips';
import ResponsiveTooltip, {
  ResponsiveTooltipIcon,
} from '../stopPaymentTooltips/ResponsiveTooltip';
import {
  PastDueCardWrapper,
  PastDueHeader,
  PastDueToolTipTextWrapper,
  ToolTipIconWrapper,
} from './PastDueCardStyles';

interface PastDueCardProps {
  agencyBilledQuantity: AgencyBilledQuantity;
  displayAgencyBilledToolTip: boolean;
  setDisplayAgencyBilledToolTip: Function;
  agencyBilledToolTip: any;
}

const PastDueCard = ({
  agencyBilledQuantity,
  agencyBilledToolTip,
  displayAgencyBilledToolTip,
  setDisplayAgencyBilledToolTip,
}: PastDueCardProps) => {
  const isResponsive = useIsResponsive();

  return (
    <PastDueCardWrapper>
      <PastDueToolTipTextWrapper>
        {agencyBilledQuantity === AgencyBilledQuantity.Partial && (
          <ToolTipIconWrapper>
            {isResponsive ? (
              <>
                <ResponsiveTooltipIcon
                  setDisplayToolTip={setDisplayAgencyBilledToolTip}
                />
                <ResponsiveTooltip
                  toolTip={agencyBilledToolTip}
                  setDisplayToolTip={setDisplayAgencyBilledToolTip}
                  displayToolTip={displayAgencyBilledToolTip}
                />
              </>
            ) : (
              <DesktopTooltip
                toolTip={agencyBilledToolTip}
                setDisplayToolTip={setDisplayAgencyBilledToolTip}
                displayToolTip={displayAgencyBilledToolTip}
              />
            )}
          </ToolTipIconWrapper>
        )}
        <PastDueHeader
          type="small"
          text={
            agencyBilledQuantity === AgencyBilledQuantity.Partial
              ? `Amount is inclusive of only direct bill policy(s).`
              : `Amount is inclusive of all the direct billed policies.`
          }
        />
      </PastDueToolTipTextWrapper>
    </PastDueCardWrapper>
  );
};

export default PastDueCard;
