import React, { useState } from 'react';
import {
  SAText,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAButton,
  SARadio,
  SARadioGroup,
} from '@saux/design-system-react';
import {
  Backdrop,
  ModalCloseWrap,
  ModalSelectionWrap,
  ModalHeader,
  ModalHeaderWrap,
  ModalTextWrap,
  ModalWrap,
  ModalDoubleButtonWrap,
} from '../outageModal/OutageModalStyles';
import { Policy } from '../policies/Policies';
import { dcxConfig, Env } from '../../aws_exports';

const config = dcxConfig(process.env.REACT_APP_ENV as Env);

interface FnolRedirectModalProps {
  setModal: Function;
  policies: Policy[];
  policyDetails: PolicyDetails;
  setPolicyDetails: Function;
  customFnolUUID: string | undefined;
}

export interface PolicyDetails {
  policy_number: string | null;
  product_code: string | null;
}

const FnolRedirectModal: React.FC<FnolRedirectModalProps> = ({
  setModal,
  policies,
  policyDetails,
  setPolicyDetails,
  customFnolUUID,
}: FnolRedirectModalProps) => {
  const [productCode, setproductCode] = useState<string>('');
  const onStartClaimHandler = () => {
    setModal(false);
    if (productCode === 'Workers Comp') {
      window.open(`${config.start_a_new_worker_claim}`, '_blank');
    } else {
      window.open(`${config.start_a_new_claim}${customFnolUUID}`, '_blank');
    }
  };
  return (
    <Backdrop
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          setModal(false);
        }
      }}
    >
      <ModalWrap>
        <ModalHeader>
          <ModalTextWrap>
            <SAText text="File a New Claim" type="heading-3" />
          </ModalTextWrap>
          <ModalCloseWrap
            onClick={() => {
              setModal(false);
            }}
          >
            <SAIcon
              icon={SAIcons.x}
              size={SAIconSize.medium}
              colorVariant="dark"
            />
          </ModalCloseWrap>
        </ModalHeader>
        <ModalHeaderWrap>
          <SAText
            type="standard"
            text="Choose the policy you would like to file a new claim for:"
          />
        </ModalHeaderWrap>
        <ModalSelectionWrap>
          <SARadioGroup
            id="policySelection"
            value={policyDetails?.policy_number ?? ''}
            variant="standard"
            direction="vertical"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const polDetails = e.target.value.split('-');
              const [name, value] = [polDetails[0], polDetails[1]];
              setPolicyDetails({ product_code: name, policy_number: value });
              setproductCode(name);
            }}
          >
            {policies.map((element) => {
              return (
                <SARadio
                  key={element.number}
                  value={`${element.type}-${element.number}`}
                  name={element.type}
                  label={`${element.type} - ${element.number}`}
                  variant="standard"
                />
              );
            })}
          </SARadioGroup>
        </ModalSelectionWrap>
        <ModalDoubleButtonWrap>
          <SAButton
            label="CANCEL"
            fullWidthUnder={768}
            variant="link"
            color="black"
            onClick={() => {
              setModal(false);
            }}
          />
          <SAButton
            label="START CLAIM"
            fullWidthUnder={768}
            variant="primary"
            onClick={() => onStartClaimHandler()}
          />
        </ModalDoubleButtonWrap>
      </ModalWrap>
    </Backdrop>
  );
};

export default FnolRedirectModal;
