/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import {
  SAButton,
  SAIcon,
  SAIcons,
  SATag,
  SAText,
} from '@saux/design-system-react';
import AWSAppSyncClient from 'aws-appsync';
import React, { useEffect, useRef, useState } from 'react';
import {
  AgencyBilledPaymentModal,
  MakePaymentModal,
  StopPaymentButton,
} from '..';
import AgencyBilledQuantity from '../../sharedTypes/AgencyBilledQuantity';
import { LastDayToPayInfoProps } from '../../sharedTypes/Overview';
import { formatDate, formatMoney } from '../../util/formatters';
import customBase from '../../util/global.theme';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import { useFeatureFlag } from '../../util/hooks';
import generateOpenOneIncURL from '../../util/openOneIncURL/generateOpenOneIncURL';
import useIsResponsive from '../../util/useIsResponsive';
import OutageModal from '../outageModal/OutageModal';
import PastDueCard from '../pastDueCard/PastDueCard';
import StopPaymentModalDC from '../stopPaymentModal';
import { ResponsiveTooltip } from '../stopPaymentTooltips';
import { ToolTip } from '../stopPaymentTooltips/DesktopTooltip';
import { GET_PAYMENT_INFO_invoices_items } from './graphql/queries/__generated__/GET_PAYMENT_INFO';
import {
  AmountDue,
  AmountDueWrapper,
  Border,
  DesktopButtonWrapper,
  DesktopScheduleTextHolder,
  DueDate,
  LastDayToPayText,
  MakePaymentButtonSpacer,
  MakePaymentButtonWrapper,
  MobileScheduleTextHolder,
  MortgageePaymentWordingText,
  NextPaymentWrapper,
  NextPaymentWrapperError,
  PastDueIndicator,
  ResponsiveButtonWrapper,
  LastDayToPaySpacer,
} from './NextPayment.styles';
import { DisclaimerProps } from './DataContainer';

export interface NextPaymentProps {
  showPastDue: boolean;
  paymentInfo?: PaymentInformation;
  awsAppSyncClient: AWSAppSyncClient<any>;
  accountNumber: string;
  loading?: boolean;
  error?: Error;
  onlyMortgageeBilledInvoices: boolean;
  mortgageeInvoice: GET_PAYMENT_INFO_invoices_items | null;
  sourceSystemCode: string;
  agencyBilledQuantity: AgencyBilledQuantity;
  displayStopPaymentButton?: boolean;
  lastDayToPayInfo: LastDayToPayInfoProps;
  isMigrated: boolean;
}

export interface PaymentInformation {
  paymentAmount: string;
  paymentDueDate: string | Date | number | null | undefined;
  pastDueAmount: string;
  showDisclaimer: DisclaimerProps;
  responsiveIndicator: string;
  defaultBillingMethod: string;
  userId: string | null | undefined;
}

export interface UpcomingPaymentInfoProps {
  paymentAmount: string;
  paymentInfo: PaymentInformation | any;
  hasStoppedPayment: boolean;
  showPastDue: boolean;
  onlyMortgageeBilledInvoices: boolean;
  mortgageeInvoice: GET_PAYMENT_INFO_invoices_items | null;
  showLastDayToPay: boolean;
}

export interface Invoices {
  due_date: string | null;
  invoice_amount: number | null;
  invoice_key: string | null;
  mortgagee_flag: string | null;
}

export function makeNextPaymentClick(
  paymentInfo: PaymentInformation | undefined,
  accountNumber: string,
  awsAppSyncClient: AWSAppSyncClient<any>,
  SSOneINCURL?: boolean | null,
) {
  const userId: string | undefined | null = paymentInfo?.userId;
  generateOpenOneIncURL(
    awsAppSyncClient,
    accountNumber,
    userId,
    SSOneINCURL,
    sessionStorage?.userLOB?.includes('Commercial') ? 'GWCL' : 'GWPL',
  );
}

export const UpcomingPaymentInfo: Function = ({
  paymentInfo,
  hasStoppedPayment,
  showPastDue,
  onlyMortgageeBilledInvoices,
  mortgageeInvoice,
  showLastDayToPay,
}: UpcomingPaymentInfoProps) => {
  if (
    paymentInfo?.paymentAmount === 'PAID IN FULL' &&
    !onlyMortgageeBilledInvoices
  ) {
    return (
      <>
        <AmountDue>Paid In Full</AmountDue>
      </>
    );
  }

  if (onlyMortgageeBilledInvoices && !showLastDayToPay) {
    if (!parseFloat(mortgageeInvoice?.amount_due?.toString() ?? '0')) {
      return (
        <>
          <AmountDue>Paid In Full</AmountDue>
        </>
      );
    }

    return (
      <MortgageePaymentWordingText>
        <SAText
          text={`${formatMoney(mortgageeInvoice?.amount_due ?? '-')}\u00A0`}
          weight="bold"
          type="standard"
        />
        <SAText
          text={`has been billed to the mortgage company and ${
            mortgageeInvoice?.due_date &&
            new Date(mortgageeInvoice?.due_date).getTime() >
              new Date().getTime()
              ? 'is'
              : 'was'
          } due on`}
          weight="normal"
          type="standard"
        />
        <SAText
          text={`\u00A0${formatDate(mortgageeInvoice?.due_date ?? '-')}`}
          weight="bold"
          type="standard"
        />
        <SAText text="." weight="normal" type="standard" />
      </MortgageePaymentWordingText>
    );
  }

  if (
    paymentInfo?.responsiveIndicator &&
    paymentInfo?.responsiveIndicator?.toLowerCase() === 'no' &&
    hasStoppedPayment === false &&
    paymentInfo?.defaultBillingMethod?.toUpperCase() !== 'RESPONSIVE' &&
    !showPastDue &&
    paymentInfo?.paymentDueDate !== '-' &&
    !showLastDayToPay
  ) {
    return (
      <>
        <AmountDue>{paymentInfo?.paymentAmount ?? '-'}</AmountDue>
        <DesktopScheduleTextHolder>
          <SAText
            weight="bold"
            type="small"
            text="Scheduled for automatic draft"
          />
          <SAText type="small" text={` on ${paymentInfo?.paymentDueDate}`} />
        </DesktopScheduleTextHolder>
        <MobileScheduleTextHolder>
          <SAText
            weight="bold"
            type="standard"
            text="Scheduled for automatic draft"
          />
          <SAText type="small" text={` on ${paymentInfo?.paymentDueDate}`} />
        </MobileScheduleTextHolder>
      </>
    );
  }

  if (
    paymentInfo?.paymentDueDate !== '-' &&
    paymentInfo?.responsiveIndicator &&
    paymentInfo?.responsiveIndicator?.toLowerCase() === 'no' &&
    paymentInfo?.defaultBillingMethod?.toUpperCase() !== 'RESPONSIVE' &&
    hasStoppedPayment === true &&
    !showPastDue &&
    !showLastDayToPay
  ) {
    return (
      <>
        <AmountDue>{paymentInfo?.paymentAmount}</AmountDue>
        <DueDate>{`by ${paymentInfo?.paymentDueDate}`}</DueDate>
      </>
    );
  }

  if (
    ((paymentInfo?.responsiveIndicator &&
      paymentInfo?.responsiveIndicator?.toLowerCase() === 'yes') ||
      paymentInfo?.defaultBillingMethod?.toUpperCase() === 'RESPONSIVE') &&
    !showPastDue &&
    paymentInfo?.paymentDueDate !== '-' &&
    !showLastDayToPay
  ) {
    return (
      <>
        <AmountDue>{paymentInfo?.paymentAmount}</AmountDue>
        <DueDate>{`by ${paymentInfo?.paymentDueDate}`}</DueDate>
      </>
    );
  }

  return (
    <>
      <AmountDue>{paymentInfo?.paymentAmount}</AmountDue>
    </>
  );
};

const NextPayment = ({
  accountNumber,
  paymentInfo,
  awsAppSyncClient,
  showPastDue,
  loading,
  error,
  onlyMortgageeBilledInvoices,
  mortgageeInvoice,
  sourceSystemCode,
  agencyBilledQuantity,
  displayStopPaymentButton,
  lastDayToPayInfo,
  isMigrated,
}: NextPaymentProps) => {
  const [showDisclaimerModal, setShowDisclaimerModal] = useState<boolean>(
    false,
  );
  const [baseState, setBaseState] = useState<string>(
    '',
  );
  const [showOutageModal, setShowOutageModal] = useState<boolean>(false);
  const [whichComponent, setWhichComponent] = useState<string>('Next Payment');
  const [showStopPaymentModal, setShowStopPaymentModal] = useState<boolean>(
    displayStopPaymentButton ?? false,
  );
  const [
    showAgencyBilledPaymentModal,
    setShowAgencyBilledPaymentModal,
  ] = useState<boolean>(false);
  const [showToolTip, setShowToolTip] = useState<boolean>(false);
  const [hasStoppedPayment, setHasStoppedPayment] = useState<boolean>(false);
  const [cancelPaymentDisabled, setCancelPaymentDisabled] = useState<boolean>(
    false,
  );
  const stopPaymentToolTip = useRef<ToolTip>({ title: '', subtitle: '' });
  const agencyBilledToolTip = useRef<ToolTip>({
    title: '',
    subtitle:
      'Your account contains agency billed policies. Please reach out to your agent for any billing/payment questions or to make a payment.',
  });
  const [
    displayAgencyBilledToolTip,
    setDisplayAgencyBilledToolTip,
  ] = useState<boolean>(false);
  const { flagDetails } = useFeatureFlag('StopPaymentButton');
  const { flagDetails: BillingCenterOutage } = useFeatureFlag(
    'BillingCenterOutage',
  );
  const { flagDetails: SSOneINCURL } = useFeatureFlag(
    'OneINCSelfServiceMuleURL',
  );
  const { flagDetails: LastDayToPay } = useFeatureFlag('LastDayToPay');
  const isResponsive = useIsResponsive();

  useEffect(() => {
    if (showDisclaimerModal || showStopPaymentModal || showOutageModal) {
      document.body.style.overflow = 'hidden';
    } else if (
      !showDisclaimerModal &&
      !showStopPaymentModal &&
      !showOutageModal
    ) {
      document.body.style.overflow = 'auto';
    }
  }, [showDisclaimerModal, showStopPaymentModal, showOutageModal]);

  if (loading) {
    return (
      <NextPaymentWrapperError>
        <SAText type="standard" text="Loading..." />
      </NextPaymentWrapperError>
    );
  }

  if (error) {
    return (
      <NextPaymentWrapperError>
        <SAText type="standard" text="Error" />
      </NextPaymentWrapperError>
    );
  }

  const makePaymentButtonHandler = () => {
    if (BillingCenterOutage && BillingCenterOutage.enabled) {
      setWhichComponent('Making a Payment');
      setShowOutageModal(true);
    } else if (agencyBilledQuantity === AgencyBilledQuantity.Partial) {
      setShowAgencyBilledPaymentModal(true);
      /* eslint-disable-next-line */
    } else if (paymentInfo?.showDisclaimer.showDisclaimer === true) {
      setBaseState(paymentInfo?.showDisclaimer?.baseState);
      setShowDisclaimerModal(true);
    } else {
      makeNextPaymentClick(
        paymentInfo,
        accountNumber,
        awsAppSyncClient,
        SSOneINCURL?.enabled,
      );
    }

    simpleGTMDataLayer({
      event: 'Overview-MakePaymentButton-click',
      event_action: 'Button Click',
      event_category: 'Overview',
      event_label: 'Overview Make Payment Button Click',
    });
    simpleGTMDataLayer({
      event: 'Snowplow-Overview-MakePaymentButton-click',
      event_action: 'Snowplow Button Click',
      event_category: 'Snowplow Overview',
      event_label: 'Snowplow Overview Make Payment Button Click',
    });
  };

  const MakePaymentButton = () => {
    if (isResponsive) {
      return (
        <ResponsiveButtonWrapper>
          <MakePaymentButtonSpacer>
            <Border>
              <SAButton
                fullWidth
                fullWidthUnder={customBase.global.breakpoints.medium}
                test-attr="next-payment-button"
                label="MAKE A PAYMENT"
                variant="primary"
                onClick={makePaymentButtonHandler}
              />
            </Border>
          </MakePaymentButtonSpacer>
          {flagDetails?.enabled && (
            <StopPaymentButton
              accountNumber={accountNumber}
              awsAppSyncClient={awsAppSyncClient}
              setHasStoppedPayment={setHasStoppedPayment}
              setShowStopPaymentModal={setShowStopPaymentModal}
              setShowToolTip={setShowToolTip}
              cancelPaymentDisabled={cancelPaymentDisabled}
              setCancelPaymentDisabled={setCancelPaymentDisabled}
              toolTip={stopPaymentToolTip}
              setShowOutageModal={setShowOutageModal}
              setWhichComponent={setWhichComponent}
            />
          )}
        </ResponsiveButtonWrapper>
      );
    }

    return (
      <DesktopButtonWrapper>
        <Border>
          <SAButton
            test-attr="next-payment-button"
            label="MAKE A PAYMENT"
            variant="primary-small"
            onClick={makePaymentButtonHandler}
          />
        </Border>
        {flagDetails?.enabled && (
          <StopPaymentButton
            accountNumber={accountNumber}
            awsAppSyncClient={awsAppSyncClient}
            setHasStoppedPayment={setHasStoppedPayment}
            setShowStopPaymentModal={setShowStopPaymentModal}
            cancelPaymentDisabled={cancelPaymentDisabled}
            setCancelPaymentDisabled={setCancelPaymentDisabled}
            toolTip={stopPaymentToolTip}
            setShowOutageModal={setShowOutageModal}
            setWhichComponent={setWhichComponent}
          />
        )}
      </DesktopButtonWrapper>
    );
  };

  return (
    <>
      {showOutageModal && (
        <OutageModal component={whichComponent} setModal={setShowOutageModal} />
      )}
      {showDisclaimerModal && (
        <MakePaymentModal
          awsAppSyncClient={awsAppSyncClient}
          accountNumber={accountNumber}
          toggleModalDisplay={setShowDisclaimerModal}
          sourceSystemCode={sourceSystemCode}
          isMigrated={isMigrated}
          baseState={baseState}
        />
      )}
      {showStopPaymentModal && (
        <StopPaymentModalDC
          accountNumber={accountNumber}
          awsAppSyncClient={awsAppSyncClient}
          paymentDueDate={
            paymentInfo?.paymentDueDate
              ? paymentInfo?.paymentDueDate.toString()
              : ''
          }
          setCancelPaymentDisabled={setCancelPaymentDisabled}
          toolTip={stopPaymentToolTip}
          closeOnClickHandler={() => {
            setShowStopPaymentModal(false);
            document.body.style.overflow = 'auto';
          }}
        />
      )}
      {showAgencyBilledPaymentModal && (
        <AgencyBilledPaymentModal
          accountNumber={accountNumber}
          awsAppSyncClient={awsAppSyncClient}
          setDisplayModal={setShowAgencyBilledPaymentModal}
          amountDue={paymentInfo?.paymentAmount}
          userId={paymentInfo?.userId}
          showDisclaimer={!!paymentInfo?.showDisclaimer}
        />
      )}
      <ResponsiveTooltip
        toolTip={stopPaymentToolTip}
        setDisplayToolTip={setShowToolTip}
        displayToolTip={showToolTip}
      />
      <NextPaymentWrapper className="nextPaymentWrapper">
        <div>
          <SAText type="heading-3">Next Payment</SAText>
          <AmountDueWrapper>
            <UpcomingPaymentInfo
              paymentInfo={paymentInfo}
              hasStoppedPayment={hasStoppedPayment}
              showPastDue={showPastDue}
              onlyMortgageeBilledInvoices={onlyMortgageeBilledInvoices}
              mortgageeInvoice={mortgageeInvoice}
              showLastDayToPay={
                lastDayToPayInfo?.showLastDayToPay && LastDayToPay?.enabled
              }
            />
          </AmountDueWrapper>
          <PastDueCard
            agencyBilledQuantity={agencyBilledQuantity}
            displayAgencyBilledToolTip={displayAgencyBilledToolTip}
            setDisplayAgencyBilledToolTip={setDisplayAgencyBilledToolTip}
            agencyBilledToolTip={agencyBilledToolTip}
          />
          {showPastDue && (
            <PastDueIndicator>
              <SATag
                variant="alert"
                withBorder
                label="Past Due"
                startIcon={
                  <SAIcon
                    icon={SAIcons.alert}
                    size="16px"
                    colorVariant="alert"
                  />
                }
              />
            </PastDueIndicator>
          )}
          {lastDayToPayInfo.showLastDayToPay &&
            LastDayToPay?.enabled &&
            !showPastDue && <LastDayToPaySpacer />}
          {lastDayToPayInfo.showLastDayToPay && LastDayToPay?.enabled && (
            <LastDayToPayText>
              <SAText text="There is a past due amount of " type="small" />
              <SAText
                text={formatMoney(lastDayToPayInfo?.pastDueAmount) ?? '-'}
                weight="bold"
                type="small"
              />
              <SAText
                text=". The policy (or policies) will cancel if the payment is not received on or before "
                type="small"
              />
              <SAText
                text={`${formatDate(lastDayToPayInfo?.date, '-')}.`}
                weight="bold"
                type="small"
              />
            </LastDayToPayText>
          )}
        </div>

        <MakePaymentButtonWrapper className="makePaymentButtonWrapper">
          <MakePaymentButton />
        </MakePaymentButtonWrapper>
      </NextPaymentWrapper>
    </>
  );
};

NextPayment.defaultProps = {
  paymentInfo: undefined,
  loading: false,
  error: undefined,
};

export default NextPayment;
