import { SAUXTheme } from '@saux/design-system-react';
import styled from 'styled-components';
import customBase from '../../util/global.theme';

const ProductTourBannerWrapper = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    display: flex;
    height: 40vh;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: absolute;
    margin-top: 70px;
    left: 50%;
    transform: translate(-50%, -50%);

    @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
      margin-top: 0px;
    }

    @media screen and (max-width: ${customBase.global.breakpoints.small}px) {
      height: 65vh;
      width: 100vw;
      padding: ${theme?.size.spacer.large};
    }
`}
`;

export const BannerButton = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 5px;
  margin-right: 5px;
  margin-left: -14px;
`;

export default ProductTourBannerWrapper;
