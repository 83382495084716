import { ApolloQueryResult } from 'apollo-client';
import AWSAppSyncClient from 'aws-appsync';
import { loader } from 'graphql.macro';
import React, { useEffect, useReducer, useState } from 'react';
import getConfig, { Env } from '../../aws_exports';
import {
  GET_ALL_COVERAGES,
  GET_ALL_COVERAGESVariables,
  GET_ALL_COVERAGES_account_items_account_policies,
  GET_ALL_COVERAGES_account_items_account_policies_items_connect_coverages,
} from '../../generated/GET_ALL_COVERAGES';
import {
  GET_DOCUMENTS_BY_ACCOUNT,
  GET_DOCUMENTS_BY_ACCOUNTVariables,
  GET_DOCUMENTS_BY_ACCOUNT_account_items_account_documents_items,
} from '../../generated/GET_DOCUMENTS_BY_ACCOUNT';
import { GET_POLICIES_BY_ACCOUNT } from '../../generated/GET_POLICIES_BY_ACCOUNT';
import { useQuery } from '../../util/appsyncClient';
import { formatTitleCase } from '../../util/formatters';
import { useFeatureFlag } from '../../util/hooks';
import useDocumentDownload from '../../util/useDocumentDownload';
import { FetchDocumentProps } from '../../util/useDocumentDownload/useDocumentDownload';
import ClaimsDisabledModal from '../claimsDisabledModal/ClaimsDisabledModal';
import { FnolRedirectModal } from '..';
import {
  fetchPolicies,
  groupPoliciesForUI,
  processAccountPoliciesInformation,
} from '../policies/DataContainer';
import { Policy, PolicyType } from '../policies/Policies';
import createQuickLinks from './createQuickLinks';
import QuickLinks, { QuickLink } from './QuickLinks';

const getPolicyDocuments = loader(
  '../../graphql/queries/Get_Documents_By_Account.graphql',
);
const getAllCoverages = loader(
  '../../graphql/queries/Get_All_Coverages.graphql',
);
const getPayAsYouGo = loader(
  '../../graphql/queries/Get_Policies_PayAsYouGo.graphql',
);

export const fetchPolicyDocuments = (
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string,
): Promise<ApolloQueryResult<GET_DOCUMENTS_BY_ACCOUNT>> => {
  const variables: GET_DOCUMENTS_BY_ACCOUNTVariables = {
    account_number: accountNumber,
  } as GET_DOCUMENTS_BY_ACCOUNTVariables;
  const queryResult: Promise<
    ApolloQueryResult<GET_DOCUMENTS_BY_ACCOUNT>
  > = awsAppSyncClient.query({
    query: getPolicyDocuments,
    variables,
  }) as Promise<ApolloQueryResult<GET_DOCUMENTS_BY_ACCOUNT>>;
  return queryResult;
};

const containsRoadsideAssistanceCoverage = (
  coverages: GET_ALL_COVERAGES_account_items_account_policies_items_connect_coverages,
) => {
  let contains: boolean = false;
  const { items } = coverages;
  if (!items) return contains;
  for (let i: number = 0; i < items.length && !contains; i += 1) {
    const coverage = items[i];
    if (coverage) {
      contains = coverage.covg_cd === 'Roadside Assistance';
    }
  }

  return contains;
};

export const containsPolicyWithRoadsideAssistance = (
  policies: GET_ALL_COVERAGES_account_items_account_policies,
) => {
  let contains: boolean = false;
  const { items } = policies;
  if (!items) return contains;
  for (let i: number = 0; i < items.length && !contains; i += 1) {
    const policy = items[i];
    if (policy?.status === 'InForce' && policy?.connect_coverages) {
      contains = containsRoadsideAssistanceCoverage(policy.connect_coverages);
    }
  }
  return contains;
};

export const getCurrentAutoIDDoc = (
  documents: (GET_DOCUMENTS_BY_ACCOUNT_account_items_account_documents_items | null)[],
) => {
  return documents
    .filter((doc) => doc?.file_name?.includes('Auto ID'))
    .reduce((mostRecentDoc, currentDoc) => {
      if (!mostRecentDoc) return currentDoc;

      if (
        mostRecentDoc !== undefined &&
        mostRecentDoc.part_id &&
        currentDoc?.part_id
      ) {
        if (Number(currentDoc!.part_id) > Number(mostRecentDoc.part_id))
          return currentDoc;
      }

      return mostRecentDoc;
    });
};

export const processPolicyDoc = (
  data: GET_DOCUMENTS_BY_ACCOUNT,
  policyType: string,
  policyNum: string,
) => {
  let documentsKey: string | any = '-';

  const documentData = data.account?.items;
  if (
    documentData &&
    documentData.length > 0 &&
    documentData[0]?.account_documents
  ) {
    const documentDataContent = documentData[0]?.account_documents?.items;

    if (documentDataContent) {
      for (let i = 0; i < documentDataContent.length; i += 1) {
        if (
          formatTitleCase(documentDataContent[i]?.classification) ===
            'Policy' &&
          documentDataContent[i]?.policy_number?.toString() === policyNum
        ) {
          if (
            formatTitleCase(policyType) === 'Auto' ||
            formatTitleCase(policyType) === 'Commercial Auto' ||
            formatTitleCase(policyType) === 'Farmowners Auto'
          ) {
            // eslint-disable-next-line
            if (documentDataContent[i]) {
              if (documentDataContent[i]!.file_name?.includes('Auto ID Card')) {
                const currentAutoIDDoc = getCurrentAutoIDDoc(
                  documentDataContent,
                );

                documentsKey = currentAutoIDDoc?.documents_key;
              }
            }
          }
          if (
            formatTitleCase(policyType) === 'Home' ||
            formatTitleCase(policyType) === 'Farm & Ranch' ||
            formatTitleCase(policyType) === 'Businessowners'
          ) {
            // eslint-disable-next-line
            if (documentDataContent[i]!) {
              if (
                (documentDataContent[i]!.file_name?.indexOf('Home') !== -1 &&
                  documentDataContent[i]!.file_name?.indexOf('Declaration') !==
                    -1) ||
                (documentDataContent[i]!.file_name?.indexOf('Farm & Ranch') !==
                  -1 &&
                  documentDataContent[i]!.file_name?.indexOf('Declaration') !==
                    -1) ||
                (documentDataContent[i]!.file_name?.indexOf(
                  'Businessowners',
                ) !== -1 &&
                  documentDataContent[i]!.file_name?.indexOf('Declaration') !==
                    -1)
              ) {
                documentsKey = documentDataContent[i]!.documents_key;
              }
            }
          }
          if (
            formatTitleCase(policyType) === 'Umbrella' ||
            formatTitleCase(policyType) === 'Commercial Umbrella' ||
            formatTitleCase(policyType) === 'Farmowners Umbrella' ||
            formatTitleCase(policyType) === 'Workers Comp' ||
            formatTitleCase(policyType) === 'Commercial Package'
          ) {
            // eslint-disable-next-line
            if (documentDataContent[i]!) {
              if (
                (documentDataContent[i]!.file_name?.indexOf('Umbrella') !==
                  -1 &&
                  documentDataContent[i]!.file_name?.indexOf('Declaration') !==
                    -1) ||
                (documentDataContent[i]!.file_name?.indexOf(
                  'Commercial Umbrella',
                ) !== -1 &&
                  documentDataContent[i]!.file_name?.indexOf('Declaration') !==
                    -1) ||
                (documentDataContent[i]!.file_name?.indexOf(
                  'Farmowners Umbrella',
                ) !== -1 &&
                  documentDataContent[i]!.file_name?.indexOf('Declaration') !==
                    -1) ||
                (documentDataContent[i]!.file_name?.indexOf('Workers Comp') !==
                  -1 &&
                  documentDataContent[i]!.file_name?.indexOf('Declaration') !==
                    -1) ||
                (documentDataContent[i]!.file_name?.indexOf(
                  'Commercial Package',
                ) !== -1 &&
                  documentDataContent[i]!.file_name?.indexOf('Declaration') !==
                    -1)
              ) {
                documentsKey = documentDataContent[i]!.documents_key;
              }
            }
          }
        }
      }
    }
  }
  return {
    documentsKey,
  };
};

interface QuickLinksDataContainerProps {
  accountNumber: string;
  awsAppSyncClient: AWSAppSyncClient<any>;
  claimsAppSyncClient: AWSAppSyncClient<any>;
}

type QLState = {
  defaultPolicyNum: string | null | undefined;
  policyNum: string | null | undefined;
  userId: string | null | undefined;
  policyDoc: any;
  autoIdDoc: any;
  policies: Policy[];
  multiplePolicies: boolean;
  displayPolicyModal: boolean;
  quickLinks: QuickLink[];
  hasRoadsideAssistance: boolean;
  displayRoadsideModal: boolean;
};

type QuickLinksAction = {
  type: string;
  data?: any;
};

const QuickLinksDataContainer = ({
  accountNumber,
  awsAppSyncClient,
  claimsAppSyncClient,
}: QuickLinksDataContainerProps) => {
  const [
    showClaimsDisabledModal,
    setShowClaimsDisabledModal,
  ] = useState<boolean>(false);
  const [showFnolRedirectModal, setShowFnolRedirectModal] = useState<boolean>(
    false,
  );

  const initState = {
    defaultPolicyNum: undefined,
    policyNum: undefined,
    userId: null,
    policyDoc: undefined,
    autoIdDoc: undefined,
    policies: [],
    multiplePolicies: false,
    displayPolicyModal: false,
    quickLinks: [],
    hasRoadsideAssistance: false,
    displayRoadsideModal: false,
  };

  const reducer = (state: QLState, action: QuickLinksAction) => {
    switch (action.type) {
      case 'setDefaultPolicyNum':
        return { ...state, defaultPolicyNum: action.data };
      case 'setPolicyNum':
        return { ...state, policyNum: action.data };
      case 'setUserId':
        return { ...state, userId: action.data };
      case 'setPolicyDoc':
        return { ...state, policyDoc: action.data };
      case 'setAutoIdDoc':
        return { ...state, autoIdDoc: action.data };
      case 'setPolicies':
        return { ...state, policies: action.data };
      case 'setMultiplePolicies':
        return { ...state, multiplePolicies: action.data };
      case 'togglePolicyModal':
        return { ...state, displayPolicyModal: !state.displayPolicyModal };
      case 'setQuickLinks':
        return { ...state, quickLinks: action.data };
      case 'setHasRoadsideAssistance':
        return { ...state, hasRoadsideAssistance: action.data };
      case 'toggleRoadsideModal':
        return { ...state, displayRoadsideModal: !state.displayRoadsideModal };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initState);

  const { onDocumentClick, documentsModal } = useDocumentDownload(
    awsAppSyncClient,
    accountNumber,
  );
  const config = getConfig(process.env.REACT_APP_ENV as Env);
  const [isPayAsYouGo, setIsPayAsYouGo] = useState<boolean>(false);

  const { flagDetails: ClaimsDisabledFlag } = useFeatureFlag(
    'ClaimsDisabledFlag',
  );
  const { flagDetails: FnolRedirectModalFlag } = useFeatureFlag(
    'FnolRedirectModal',
  );

  useEffect(() => {
    fetchPolicies(accountNumber, awsAppSyncClient)
      .then((apolloQueryResult: ApolloQueryResult<GET_POLICIES_BY_ACCOUNT>) => {
        const policies =
          apolloQueryResult?.data?.account?.items &&
          apolloQueryResult.data.account.items[0] &&
          apolloQueryResult.data.account.items[0].policies?.items;
        const activePolicies = policies?.filter(
          (policy) => policy?.status?.toLowerCase() !== 'cancelled',
        );
        const processedPolicies: Policy[] = processAccountPoliciesInformation(
          apolloQueryResult.data,
        );
        const groupedPolicies: {
          policies: Policy[];
          count: number;
        } = groupPoliciesForUI(processedPolicies);
        let defaultPolicyNum: string = '';

        if (activePolicies) {
          defaultPolicyNum = activePolicies[0]?.policy_number ?? '';
        }

        if (groupedPolicies.policies.length > 1) {
          dispatch({ type: 'setMultiplePolicies', data: true });
        }

        dispatch({ type: 'setPolicies', data: groupedPolicies.policies });
        dispatch({ type: 'setDefaultPolicyNum', data: defaultPolicyNum });
        dispatch({ type: 'setPolicyNum', data: defaultPolicyNum });
        dispatch({
          type: 'setUserId',
          data: apolloQueryResult.data.me?.user_id,
        });
      })
      .catch((err: Error) => {
        console.error('FETCH POLICIES ERROR: ', err);
      });
  }, []);

  useEffect(() => {
    if (state.policyNum && state.policies.length > 0) {
      fetchPolicyDocuments(awsAppSyncClient, accountNumber)
        .then((apolloQueryResult: ApolloQueryResult<any>) => {
          const currentPolicy = state.policies.find(
            (policy: Policy) => policy.number === state.policyNum,
          );
          const autoPolicy = state.policies.find(
            (policy: Policy) =>
              (policy.type === 'Auto' ||
                policy.type === 'Commercial Auto' ||
                policy.type === 'Farmowners Auto') &&
              policy.status !== 'Cancelled',
          );

          const processedPolicyDoc = processPolicyDoc(
            apolloQueryResult.data,
            currentPolicy.type as PolicyType,
            state.policyNum as string,
          );
          const processedAutoIdDoc = processPolicyDoc(
            apolloQueryResult.data,
            autoPolicy.type as PolicyType,
            autoPolicy.number as string,
          );

          dispatch({ type: 'setPolicyDoc', data: processedPolicyDoc });
          dispatch({ type: 'setAutoIdDoc', data: processedAutoIdDoc });
        })
        .catch((err: Error) => {
          console.error('FETCH POLICY DOCS', err);
        });
    }
  }, [state.policyNum, state.policies]);

  useQuery<GET_ALL_COVERAGESVariables, GET_ALL_COVERAGES>({
    query: getAllCoverages,
    variables: {
      account_number: accountNumber,
    },
    customOnResponse: (
      apolloQueryResult: ApolloQueryResult<GET_ALL_COVERAGES>,
    ) => {
      const hasRoadsideAssistance: boolean = containsPolicyWithRoadsideAssistance(
        apolloQueryResult.data.account?.items![0]?.policies!,
      );
      if (hasRoadsideAssistance)
        dispatch({
          type: 'setHasRoadsideAssistance',
          data: hasRoadsideAssistance,
        });
    },
    customOnError: (err: Error) => {
      console.log(err);
    },
  });

  useQuery<GET_ALL_COVERAGESVariables, any>({
    query: getPayAsYouGo,
    variables: {
      account_number: accountNumber,
    },
    customOnResponse: (apolloQueryResult: ApolloQueryResult<any>) => {
      if (apolloQueryResult.data?.account?.items[0]?.policies?.items.length) {
        for (
          let x = 0;
          x < apolloQueryResult.data?.account?.items[0]?.policies?.items.length;
          x += 1
        ) {
          if (
            apolloQueryResult.data?.account?.items[0]?.policies?.items[x]
              ?.payasyougo?.items?.length > 0
          ) {
            setIsPayAsYouGo(true);
          }
        }
      }
    },
    customOnError: (err: Error) => {
      console.log(err);
    },
  });

  const createPolicyDocClickHandler = () => {
    if (state.multiplePolicies) {
      dispatch({ type: 'togglePolicyModal' });
      document.body.style.overflow = 'hidden';
    } else {
      onDocumentClick({ policy_number: state.policyNum } as FetchDocumentProps);
    }
  };

  const toggleRoadsideModal = () => dispatch({ type: 'toggleRoadsideModal' });

  const links: QuickLink[] = createQuickLinks({
    state,
    config,
    onDocumentClick,
    createPolicyDocClickHandler,
    toggleRoadsideModal,
    setShowClaimsDisabledModal,
    ClaimsDisabledFlag,
    setShowFnolRedirectModal,
    isPayAsYouGo,
  });

  return (
    <>
      {documentsModal}
      {showFnolRedirectModal &&
        !showClaimsDisabledModal &&
        FnolRedirectModalFlag?.enabled && (
          <FnolRedirectModal
            accountNumber={accountNumber}
            awsAppSyncClient={claimsAppSyncClient}
            setModal={setShowFnolRedirectModal}
            policies={state.policies ?? []}
          />
        )}
      {showClaimsDisabledModal && (
        <ClaimsDisabledModal closeModal={setShowClaimsDisabledModal} />
      )}
      <QuickLinks
        links={links}
        displayPolicyModal={state.displayPolicyModal}
        setPolicyNum={(newPolicyNum: string) =>
          dispatch({ type: 'setPolicyNum', data: newPolicyNum })
        }
        policies={state.policies}
        policyDocOnClick={onDocumentClick}
        togglePolicyModal={() => dispatch({ type: 'togglePolicyModal' })}
        displayRoadsideModal={state.displayRoadsideModal}
        toggleRoadsideModal={toggleRoadsideModal}
      />
    </>
  );
};

export default QuickLinksDataContainer;
