import { SAUXTheme } from '@saux/design-system-react';
import styled from 'styled-components';
import customBase from '../../util/global.theme';

import { ReactComponent as imgSrc } from '../../assets/images/OctoCardImg.svg';

const imageWidthResponsive = 35;
const imageWidthDesktop = 30;

export const OctoCardWrap = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    cursor: pointer;
    display: flex;
    background: #ffffff;
    box-shadow: 0px 2px 5px rgba(1, 83, 142, 0.2);
    border-radius: 4px;
    margin-bottom: ${theme?.size.spacer.large};

    @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
      margin-bottom: ${theme?.size.spacer.medium};
    }
  `}
`;

export const OctoCardLeft = styled.div`
  width: ${imageWidthResponsive}%;
  display: flex;
  align-items: flex-end;

  @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
    width: ${imageWidthDesktop}%;
  }
`;

export const OctoCardImg = styled(imgSrc)`
  display: block;
  width: 100%;
  height: auto;
`;

export const OctoCardRight = styled.div`
  display: flex;
  flex-direction: column;
  width: ${100 - imageWidthResponsive}%;
  padding: 16px;

  @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
    width: ${100 - imageWidthDesktop}%;
  }
`;

export const OctoTitle = styled.p`
  font-weight: 700;
  font-size: 19px;
  line-height: 23px;
  color: #619424;
  margin-bottom: 8px;
`;

export const OctoDescription = styled.p`
  font-size: 16px;
  line-height: 21px;
  color: #3b3b3b;
  margin-bottom: 16px;
`;

export const OctoSwitchNow = styled.p`
  padding: 10px 25px;
  border: 1px solid #0173c6;
  border-radius: 5px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #0173c6;
  margin-left: auto;
`;
