import {
  SAAlert,
  SAButton,
  SAModal,
  SATag,
  SAText,
} from '@saux/design-system-react';
import styled from 'styled-components';
import customBase from '../../util/global.theme';

const AgencyBilledModalWrapper = styled(SAModal)`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${customBase.global.breakpoints.xsmall}px) {
    overflow: auto;
  }
`;

export const LoaderErrorContainer = styled.p`
  margin: 0;
  padding: 30px;
  text-align: center;
  background-color: #ffffff;
`;

export const ModalContainer = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  padding: 0;

  @media (min-width: ${customBase.global.breakpoints.medium + 1}px) {
    padding: 30px;
    border-radius: 5px;
    width: 650px;
  }

  @media (max-width: ${customBase.global.breakpoints.medium}px) {
    width: 100%;
    height: calc(100vh);
  }
`;

export const ModalHeader = styled.div`
  @media (max-width: ${customBase.global.breakpoints.medium}px) {
    display: flex;
    padding: 16px;
    align-items: center;
    justify-content: space-between;
    height: 66px;
    border-bottom: 1px solid #d9dfeb;
  }
`;

export const ModalTitle = styled(SAText)`
  margin: 0;
  @media (max-width: ${customBase.global.breakpoints.medium}px) {
    margin-left: 4px;
  }
`;

export const ModalHeaderClose = styled.div`
  display: none;
  @media (max-width: ${customBase.global.breakpoints.medium}px) {
    cursor: pointer;
    display: block;
    width: 30px;
    height: 30px;
  }
`;

export const ModalBody = styled.div`
  @media (max-width: ${customBase.global.breakpoints.medium}px) {
    padding: 20px;
    flex-grow: 1;
  }
`;

export const Alert = styled(SAAlert)`
  margin: 0 0 24px 0;
`;

export const ModalAgentLabel = styled(SATag)`
  margin: 28px 0 16px;
`;

export const ModalAgentName = styled(SAText)`
  margin: 0 0 12px 0;
`;

export const ModalDetailsRow = styled.div`
  display: flex;
  align-items: center;
  height: 26px;
`;

export const DisclaimerSeparator = styled.div`
  margin-top: 16px;
  margin-bottom: 10px;
`;

export const DisclaimerWrapper = styled.div`
  background-color: #ffffff;
  padding: 5px;
`;

export const ModalDetailsTitle = styled(SAText)`
  margin: 0;
`;

export const ModalDetailsLink = styled.a`
  display: block;
  flex-grow: 0;
  font-size: 16px;
  color: #0173c6;
  padding: 0 8px;
  text-decoration: none;
`;

const closeBtnMargin = 20;

export const ModalCloseButton = styled(SAButton)`
  width: 139px;
  margin-top: 40px;
  @media (max-width: ${customBase.global.breakpoints.medium}px) {
    margin: ${closeBtnMargin}px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const BodyButtonWrapper = styled.div`
  @media (max-width: ${customBase.global.breakpoints.medium}px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export default AgencyBilledModalWrapper;
