import React, { useEffect, useState } from 'react';
import { loader } from 'graphql.macro';
import AWSAppSyncClient from 'aws-appsync';
import { ApolloQueryResult } from 'apollo-client';
import MyAgency, { AgencyInfo } from './MyAgency';
import {
  GET_AGENCY_INFO,
  GET_AGENCY_INFOVariables,
} from '../../generated/GET_AGENCY_INFO';

const getAgencyInfoQuery = loader(
  '../../graphql/queries/Get_Agency_Info.graphql',
);

interface MyAgencyDataContainerProps {
  accountNumber: string;
  awsAppSyncClient: AWSAppSyncClient<any>;
}

export function fetchAgencyInfo(
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string,
): Promise<ApolloQueryResult<GET_AGENCY_INFO>> {
  const variables: GET_AGENCY_INFOVariables = {
    account_number: accountNumber,
  } as GET_AGENCY_INFOVariables;

  const queryResult: Promise<
    ApolloQueryResult<GET_AGENCY_INFO>
  > = awsAppSyncClient.query({
    query: getAgencyInfoQuery,
    variables,
  }) as Promise<ApolloQueryResult<GET_AGENCY_INFO>>;
  return queryResult;
}

export function isEmpty(obj: any) {
  return Object.values(obj).every((x) => x === null);
}

export function processAgencyInfo(data: GET_AGENCY_INFO) {
  let agencyName: string | null | undefined = '-';
  let businessPhone: string | null | undefined = '-';
  let businessEmail: string | null | undefined = '-';

  const agencyInfoAccountItems = data?.account?.items;

  if (
    agencyInfoAccountItems &&
    agencyInfoAccountItems[0] &&
    agencyInfoAccountItems[0].agencyContactInfo
  ) {
    if (agencyInfoAccountItems.length === 1) {
      const queriedAgencyName =
        agencyInfoAccountItems[0]?.agencyContactInfo?.agency_name;
      const queriedBusinessPhone =
        agencyInfoAccountItems[0]?.agencyContactInfo?.business_phone;
      const queriedBusinessEmail =
        agencyInfoAccountItems[0]?.agencyContactInfo?.business_email;

      if (
        agencyInfoAccountItems[0]?.service_tier?.toLowerCase() === 'platform'
      ) {
        agencyName = 'State Auto Insurance Services';
        businessPhone = '8663647489';
        businessEmail = 'saservice@stateauto.com';
      } else {
        if (
          queriedAgencyName !== '' &&
          queriedAgencyName !== null &&
          queriedAgencyName !== undefined
        ) {
          agencyName = queriedAgencyName;
        } else agencyName = '-';

        if (
          queriedBusinessPhone !== '' &&
          queriedBusinessPhone !== null &&
          queriedBusinessPhone !== undefined
        ) {
          businessPhone = queriedBusinessPhone;
        } else businessPhone = '-';

        if (
          queriedBusinessEmail !== '' &&
          queriedBusinessEmail !== null &&
          queriedBusinessEmail !== undefined
        ) {
          businessEmail = queriedBusinessEmail;
        } else businessEmail = '-';
      }
    } else {
      agencyInfoAccountItems.forEach((agencyObj) => {
        if (!isEmpty(agencyObj?.agencyContactInfo)) {
          const queriedAgencyName = agencyObj?.agencyContactInfo?.agency_name;
          const queriedBusinessPhone =
            agencyObj?.agencyContactInfo?.business_phone;
          const queriedBusinessEmail =
            agencyObj?.agencyContactInfo?.business_email;

          if (agencyObj?.service_tier?.toLowerCase() === 'platform') {
            agencyName = 'State Auto Insurance Services';
            businessPhone = '8663647489';
            businessEmail = 'saservice@stateauto.com';
          } else {
            if (
              queriedAgencyName !== '' &&
              queriedAgencyName !== null &&
              queriedAgencyName !== undefined
            ) {
              agencyName = queriedAgencyName;
            } else agencyName = '-';

            if (
              queriedBusinessPhone !== '' &&
              queriedBusinessPhone !== null &&
              queriedBusinessPhone !== undefined
            ) {
              businessPhone = queriedBusinessPhone;
            } else businessPhone = '-';

            if (
              queriedBusinessEmail !== '' &&
              queriedBusinessEmail !== null &&
              queriedBusinessEmail !== undefined
            ) {
              businessEmail = queriedBusinessEmail;
            } else businessEmail = '-';
          }
        }
      });
    }
  }
  return {
    name: agencyName,
    phoneNumber: businessPhone,
    email: businessEmail,
  };
}

const MyAgencyDataContainer = ({
  accountNumber,
  awsAppSyncClient,
}: MyAgencyDataContainerProps) => {
  const [agencyInfo, setAgencyInfo] = useState<AgencyInfo>();
  const [error, setError] = useState<Error | undefined>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchAgencyInfo(awsAppSyncClient, accountNumber)
      .then((result: ApolloQueryResult<GET_AGENCY_INFO>) => {
        const processedAgencyInfo = processAgencyInfo(result.data);
        setAgencyInfo(processedAgencyInfo);
        setLoading(false);
      })
      .catch((err: Error) => {
        console.error('GET_AGENCY_INFO ERROR: ', err);
        setError(err);
        setLoading(false);
      });
  }, [accountNumber]);

  return <MyAgency agencyInfo={agencyInfo} loading={loading} error={error} />;
};

export default MyAgencyDataContainer;
