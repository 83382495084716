import React from 'react';
import { SAIcon, SAText, SAIcons, SAIconSize } from '@saux/design-system-react';
import {
  StyledPaymentMethodWrapper,
  FlexColumn,
  StyledBankInfoBox,
  FlexRow,
  StyledUnknownPaymentMethodType,
  StyledPaymentMethodIconWrapper,
  FlexColumnPadded,
  StyledPaymentMethodType,
  StyledPaymentMethodBankInfoDiv,
  StyledPrimaryDiv,
  StyledPlaceholder,
  PaymentMethodHeaderWrapper,
} from './PaymentMethod.styles';
import { LastDayToPayInfoProps } from '../../sharedTypes/Overview';
import { useFeatureFlag } from '../../util/hooks';

export interface PaymentMethodProps {
  paymentMethod?: PayMethod;
  loading?: boolean;
  error?: any;
  lastDayToPayInfo: LastDayToPayInfoProps;
}

export interface PayMethod {
  defaultPaymentMethod: string | null | undefined;
  paymentMethodType: string;
}

const PaymentMethod = ({
  paymentMethod,
  loading,
  error,
  lastDayToPayInfo,
}: PaymentMethodProps) => {
  const { flagDetails: LastDayToPay } = useFeatureFlag('LastDayToPay');

  if (loading) {
    return (
      <StyledPaymentMethodWrapper showLastDay={false}>
        <SAText type="standard" text="Loading..." />
      </StyledPaymentMethodWrapper>
    );
  }
  if (error) {
    return (
      <StyledPaymentMethodWrapper showLastDay={false}>
        <SAText type="standard" text="Error" />
      </StyledPaymentMethodWrapper>
    );
  }
  return (
    <StyledPaymentMethodWrapper
      className="paymentMethodWrapper"
      showLastDay={lastDayToPayInfo.showLastDayToPay && LastDayToPay?.enabled}
    >
      <FlexColumn>
        <PaymentMethodHeaderWrapper>
          <SAText type="heading-3" text="Payment Method" />
        </PaymentMethodHeaderWrapper>
        <StyledBankInfoBox>
          <FlexRow>
            {paymentMethod?.paymentMethodType === 'UNKNOWN' ? (
              <StyledUnknownPaymentMethodType>
                <SAText
                  type="standard"
                  text="No default payment method"
                  weight="bold"
                />
              </StyledUnknownPaymentMethodType>
            ) : (
              <>
                <StyledPaymentMethodIconWrapper>
                  <SAIcon
                    icon={SAIcons.bank}
                    size={SAIconSize.medium}
                    colorVariant="primary"
                  />
                </StyledPaymentMethodIconWrapper>
                <FlexColumnPadded>
                  <StyledPaymentMethodType>
                    <SAText
                      type="small"
                      text={paymentMethod?.paymentMethodType}
                      weight="bold"
                    />
                  </StyledPaymentMethodType>
                  <StyledPaymentMethodBankInfoDiv>
                    <SAText
                      type="standard"
                      text={
                        paymentMethod && paymentMethod.defaultPaymentMethod
                          ? `${paymentMethod.defaultPaymentMethod}`
                          : 'Name Unavailable'
                      }
                    />
                  </StyledPaymentMethodBankInfoDiv>
                </FlexColumnPadded>
                <StyledPrimaryDiv>
                  <SAText type="small" text="PRIMARY" weight="bold" />
                </StyledPrimaryDiv>
              </>
            )}
          </FlexRow>
        </StyledBankInfoBox>
      </FlexColumn>
      <StyledPlaceholder />
    </StyledPaymentMethodWrapper>
  );
};

PaymentMethod.defaultProps = {
  paymentMethod: {},
  loading: false,
  error: undefined,
};

export default PaymentMethod;
