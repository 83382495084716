import {
  SAIcon,
  SAIcons,
  SAIconSize,
  SASnackbar,
} from '@saux/design-system-react';
import React, { MutableRefObject } from 'react';
import useIsResponsive from '../../util/useIsResponsive';
import { ResponsiveTooltipIconWrapper } from '../nextPayment/NextPayment.styles';
import { ToolTip } from './DesktopTooltip';

interface ResponsiveTooltipProps {
  toolTip: MutableRefObject<ToolTip>;
  setDisplayToolTip: Function;
  displayToolTip: boolean;
}

interface ResponsiveTooltipIconProps {
  setDisplayToolTip: Function;
  iconSize?: string;
}

export const ResponsiveTooltipIcon = ({
  setDisplayToolTip,
  iconSize,
}: ResponsiveTooltipIconProps) => (
  <ResponsiveTooltipIconWrapper>
    <SAIcon
      size={iconSize}
      icon={SAIcons.help}
      onClick={() => setDisplayToolTip(true)}
    />
  </ResponsiveTooltipIconWrapper>
);

const ResponsiveTooltip = ({
  toolTip,
  setDisplayToolTip,
  displayToolTip,
}: ResponsiveTooltipProps) => {
  const isResponsive = useIsResponsive();
  if (isResponsive && displayToolTip) {
    return (
      <SASnackbar
        test-attr="responsive-tool-tip"
        open
        title={toolTip.current.title}
        subtitle={toolTip.current.subtitle}
        icon="information"
        zIndex={1}
        offset={[0, 85]}
        variant="snackbar-info"
        position="bottom-start"
        autoHideDuration={5000}
        onClose={() => setDisplayToolTip(false)}
      />
    );
  }

  return <></>;
};

ResponsiveTooltipIcon.defaultProps = {
  iconSize: SAIconSize.small,
};

export default ResponsiveTooltip;
