import { SAUXTheme } from '@saux/design-system-react';
import styled from 'styled-components';
import customBase from '../../util/global.theme';

export const ButtonReset = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;

export const StyledButton = styled(ButtonReset)`
  ${({ theme }: { theme: SAUXTheme }) => `
    background-color: #F4F6FA;
    border-radius: ${theme.size.radius.medium};
    font-family: ${theme.font.primary.family};
    font-size: ${theme.font.primary.size.mediumTEMP};
    margin: ${theme.size.spacer.xs};
    padding: ${theme.size.spacer.xxs};
    width: 100%;
    height: 92px;
    
    @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
      width: 350px;
    }
  `}
`;

export const StyledPoliciesList = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
    flex-direction: row;
  }
`;

export const PolicyInfo = styled.div`
  display: flex;
`;

export const IconWrapper = styled.div`
  margin: 0px 0px 10px 8px;
`;

export const StyledRightColumn = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    margin-left: auto;
    padding-right: ${theme.size.spacer.medium};
  `}
`;

export const StyledExpirationHolder = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    text-align: right;
    margin-top: -${theme.size.spacer.small};
  `}
`;

export const StyledExpDate = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
  text-align: right;
  margin-top: -${theme.size.spacer.xs};
  margin-right: -1px;
  `}
`;

export const StyledTagHolder = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
  text-transform: none;
  margin-right: -${theme.size.spacer.xs};
  margin-top: ${theme.size.spacer.xs};
  `}
`;

export const PolicyNumWrapper = styled.div`
  text-align: left;
  padding-left: 15px;
  margin-top: -4px;
`;

export const PolicyNumEffectiveDate = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledIssuedOn = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    text-align: left;
    padding-left: ${theme.size.spacer.medium};
    margin-top: -5px;
  `}
`;
