import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { SAUX360Theme, GlobalStyle } from '@saux/design-system-react';
import './index.css';
import AWSAppSyncClient from 'aws-appsync';
import { AuthOptions } from 'aws-appsync-auth-link';
import * as serviceWorker from './serviceWorker';
import Overview from './Overview';
import { dcxConfig, Env } from './aws_exports';

declare global {
  interface Window {
    renderOverview: Function;
    unmountCreatereactapp: Function;
  }
}

const awsExports = dcxConfig(process.env.REACT_APP_ENV as Env);

const auth = {
  type: awsExports.aws_appsync_authenticationType,
  jwtToken: sessionStorage.getItem('jwtToken'),
} as AuthOptions;

const awsAppSyncClient: AWSAppSyncClient<any> = new AWSAppSyncClient(
  {
    url: awsExports.aws_appsync_graphqlEndpoint,
    region: awsExports.aws_appsync_region,
    disableOffline: true,
    auth,
  },
  {
    defaultOptions: {
      query: {
        fetchPolicy: 'network-only',
      },
    },
  },
);

window.renderOverview = (containerId: string, session: any) => {
  const extendedSession = session;
  extendedSession.claimsAppSyncClient = awsAppSyncClient;

  ReactDOM.render(
    <ThemeProvider theme={SAUX360Theme}>
      <GlobalStyle />
      <Overview session={extendedSession} />
    </ThemeProvider>,
    document.getElementById(containerId),
  );
  serviceWorker.unregister();
};
