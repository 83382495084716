import React, { useEffect, useState } from 'react';
import { ApolloQueryResult } from 'apollo-client';
import AWSAppSyncClient from 'aws-appsync';
import { loader } from 'graphql.macro';
import useIsResponsive from '../../util/useIsResponsive';
import {
  GET_PAYMENT_INFO,
  GET_PAYMENT_INFOVariables,
  GET_PAYMENT_INFO_account_items_payplans_items,
  GET_PAYMENT_INFO_account_items_policies_items,
} from './graphql/queries/__generated__/GET_PAYMENT_INFO';
import AgencyBilledQuantity from '../../sharedTypes/AgencyBilledQuantity';
import { LastDayToPayInfoProps, PageProps } from '../../sharedTypes/Overview';
import Responsive from './Responsive';
import Desktop from './Desktop';

const getPaymentInfoQuery = loader(
  './graphql/queries/Get_Payment_Info.graphql',
);

const getMigratedStatus = loader(
  './graphql/queries/Has_Migrated_Policy.graphql',
);

export function fetchPaymentInfo(
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string,
): Promise<ApolloQueryResult<GET_PAYMENT_INFO>> {
  const variables: GET_PAYMENT_INFOVariables = {
    account_number: accountNumber,
  } as GET_PAYMENT_INFOVariables;

  const queryResult: Promise<
    ApolloQueryResult<GET_PAYMENT_INFO>
  > = awsAppSyncClient.query({
    query: getPaymentInfoQuery,
    variables,
  }) as Promise<ApolloQueryResult<GET_PAYMENT_INFO>>;
  return queryResult;
}

interface GetMigratedStatusProps {
  hasMigratedPolicy: {
    hasMigratedPolicy: boolean;
  };
}

export function getMigratedPolicyData(
  accountNumber: string,
  awsAppSyncClient: AWSAppSyncClient<any>,
): Promise<ApolloQueryResult<GetMigratedStatusProps>> {
  const variables: GET_PAYMENT_INFOVariables = {
    account_number: accountNumber,
  } as GET_PAYMENT_INFOVariables;
  return awsAppSyncClient.query({
    query: getMigratedStatus,
    variables,
  }) as Promise<ApolloQueryResult<GetMigratedStatusProps>>;
}

export const agencyBilledPayplansOnly = (
  payplans: (GET_PAYMENT_INFO_account_items_payplans_items | null)[],
): boolean => {
  return !!payplans.every(
    (payplan: GET_PAYMENT_INFO_account_items_payplans_items | null) =>
      payplan?.billing_method === 'Agency Bill',
  );
};

export const containsSomeAgencyBilledPayplans = (
  payplans: (GET_PAYMENT_INFO_account_items_payplans_items | null)[],
): boolean => {
  const someAgencyBilledPayplans = payplans.some(
    (payplan: GET_PAYMENT_INFO_account_items_payplans_items | null) =>
      payplan?.billing_method === 'Agency Bill',
  );

  const agencyBilledOnly = agencyBilledPayplansOnly(payplans);

  return someAgencyBilledPayplans && !agencyBilledOnly;
};

export const processAgencyBilled = (
  payplans: (GET_PAYMENT_INFO_account_items_payplans_items | null)[],
): AgencyBilledQuantity => {
  if (payplans && payplans.length > 0) {
    if (agencyBilledPayplansOnly(payplans)) {
      return AgencyBilledQuantity.All;
    }
    if (containsSomeAgencyBilledPayplans(payplans)) {
      return AgencyBilledQuantity.Partial;
    }
    return AgencyBilledQuantity.None;
  }
  return AgencyBilledQuantity.None;
};

const PagesDataContainer = ({ session }: PageProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [isAgencyBilled, setIsAgencyBilled] = useState<boolean>(false);
  const [
    agencyBilledQuantity,
    setAgencyBilledQuantity,
  ] = useState<AgencyBilledQuantity>(AgencyBilledQuantity.None);
  const isResponsive = useIsResponsive();
  const [pastDueAmount, setPastDueAmount] = useState<string>('');
  const [
    lastDayToPayInfo,
    setLastDayToPayInfo,
  ] = useState<LastDayToPayInfoProps>({
    showLastDayToPay: false,
    pastDueAmount: null,
    date: null,
  });
  const [isMigrated, setIsMigrated] = useState<boolean>(false);

  useEffect(() => {
    fetchPaymentInfo(session.awsAppSyncClient, session.accountNumber)
      .then((apolloQueryResult: ApolloQueryResult<GET_PAYMENT_INFO>) => {
        if (apolloQueryResult.data.account?.items) {
          if (
            apolloQueryResult &&
            apolloQueryResult.data &&
            apolloQueryResult.data.account &&
            apolloQueryResult.data.account.items &&
            apolloQueryResult.data.account.items[0]?.policies &&
            apolloQueryResult.data.account.items[0]?.payplans &&
            apolloQueryResult.data.account.items[0]?.policies.items &&
            apolloQueryResult.data.account.items[0]?.payplans.items
          ) {
            const policies: (GET_PAYMENT_INFO_account_items_policies_items | null)[] =
              apolloQueryResult.data.account.items[0]?.policies.items;
            const payplans: (GET_PAYMENT_INFO_account_items_payplans_items | null)[] =
              apolloQueryResult.data.account.items[0]?.payplans.items;
            const inForcePolicies = policies.filter(
              (policy: GET_PAYMENT_INFO_account_items_policies_items | null) =>
                policy && policy?.status === 'InForce',
            );
            const payplansForInForcePolicies =
              payplans.filter((payplan) =>
                inForcePolicies.some(
                  (policy) =>
                    policy &&
                    payplan &&
                    policy.policy_number === payplan.policy_number,
                ),
              ) || null;
            if (
              payplansForInForcePolicies &&
              payplansForInForcePolicies.length > 0
            ) {
              const checkProcessAgencyBilled: AgencyBilledQuantity = processAgencyBilled(
                payplansForInForcePolicies,
              );
              setPastDueAmount(
                apolloQueryResult.data.account.items[0]
                  .next_payment_no_mortgagee_billed?.pastDueAmount ?? '',
              );
              setAgencyBilledQuantity(checkProcessAgencyBilled);
            }
            const isbillingMethodAB =
              !payplansForInForcePolicies?.find(
                (payplan: any) => payplan.billing_method !== 'Agency Bill',
              ) ||
              !!payplansForInForcePolicies?.find(
                (payplan: any) => payplan.billing_method === 'Agency Bill',
              );
            setIsAgencyBilled(isbillingMethodAB);
          }
          if (
            apolloQueryResult.data.account.items[0]
              ?.next_payment_no_mortgagee_billed?.hasPastDue &&
            apolloQueryResult.data.account.items[0]
              ?.next_payment_no_mortgagee_billed?.policy?.status === 'CANCELING'
          ) {
            const date = new Date(
              apolloQueryResult.data.account.items[0]
                ?.next_payment_no_mortgagee_billed?.policy
                ?.trans_effective_date ?? new Date(),
            );
            date.setDate(date.getDate() - 1);

            setLastDayToPayInfo({
              showLastDayToPay: true,
              pastDueAmount,
              date,
            });
          }
        }
        setLoading(false);
      })
      .catch(() => {
        console.error('GET_PAYMENT_INFO ERROR');
        setError(true);
        setLoading(false);
      });
  }, [session.accountNumber, pastDueAmount]);

  useEffect(() => {
    getMigratedPolicyData(session.accountNumber, session.awsAppSyncClient)
      .then((apolloQueryResult: ApolloQueryResult<GetMigratedStatusProps>) => {
        if (
          apolloQueryResult.data &&
          apolloQueryResult.data?.hasMigratedPolicy &&
          apolloQueryResult.data?.hasMigratedPolicy?.hasMigratedPolicy
        ) {
          setIsMigrated(
            apolloQueryResult.data.hasMigratedPolicy.hasMigratedPolicy,
          );
        }
      })
      .catch((err: Error) => {
        console.error('GET POLICY MIGRATED STATUS ERROR: ', err);
      });
  }, [session.accountNumber]);

  return (
    <>
      {isResponsive ? (
        <Responsive
          session={session}
          agencyBilledQuantity={agencyBilledQuantity}
          lastDayToPayInfo={lastDayToPayInfo}
          loading={loading}
          error={error}
          isMigrated={isMigrated}
          isAgencyBilled={isAgencyBilled}
        />
      ) : (
        <Desktop
          session={session}
          agencyBilledQuantity={agencyBilledQuantity}
          isAgencyBilled={isAgencyBilled}
          lastDayToPayInfo={lastDayToPayInfo}
          loading={loading}
          error={error}
          isMigrated={isMigrated}
        />
      )}
    </>
  );
};

export default PagesDataContainer;
