import { useEffect, DependencyList } from 'react';
import useFeatureFlag from './useFeatureFlag';

interface RunFunctions {
  onEnabled?: Function;
  onDisabled?: Function;
  onError?: Function;
}

const useFeatureFlagEffect = (
  flagName: string,
  runFunctions: RunFunctions | undefined,
  depencencies: DependencyList | undefined,
) => {
  const { flagDetails, error } = useFeatureFlag(flagName);
  useEffect(
    () => {
      let cleanupFunction;
      if (flagDetails?.enabled && runFunctions?.onEnabled)
        cleanupFunction = runFunctions.onEnabled(flagDetails, error);
      else if (
        !flagDetails?.enabled &&
        flagDetails?.enabled !== undefined &&
        runFunctions?.onDisabled
      )
        cleanupFunction = runFunctions.onDisabled(flagDetails, error);
      else if (error && runFunctions?.onError)
        cleanupFunction = runFunctions.onError(flagDetails, error);
      return cleanupFunction;
    },
    depencencies ? [...depencencies, flagDetails, error] : depencencies,
  );
};

export default useFeatureFlagEffect;
