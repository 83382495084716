import styled from 'styled-components';
import customBase from '../../util/global.theme';

const ToolTipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 240px;
  width: 340px;
  background-color: #fff6e9;
  border-radius: 3px;
  border-left: 5px solid rgba(245, 165, 36, 1);
  padding: 16px 24px 24px 16px;

  @media screen and (max-width: ${customBase.global.breakpoints.small}px) {
    width: 300px;
  }
`;

export const ToolTipContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ToolTipSkipNext = styled.div`
  display: flex;
  justify-content: flex-end;
`;
export const ToolTipTitle = styled.div`
  margin: 8px 0px;
`;

export const ProductTourWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100vw;
`;

export const SkipButton = styled.div`
  margin-right: 5px;
`;

export default ToolTipWrapper;
