import { ApolloQueryResult } from 'apollo-client';
import AWSAppSyncClient from 'aws-appsync';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import {
  GET_POLICIES_BY_ACCOUNT,
  GET_POLICIES_BY_ACCOUNTVariables,
  GET_POLICIES_BY_ACCOUNT_account_items,
  GET_POLICIES_BY_ACCOUNT_account_items_policies_items,
} from '../../generated/GET_POLICIES_BY_ACCOUNT';
import Policies, { OverviewPolicies, Policy, PolicyType } from './Policies';

export interface PoliciesDataContainerProps {
  accountNumber: string;
  awsAppSyncClient: AWSAppSyncClient<any>;
  useNavigation: Function;
}

const getPoliciesByAccountQuery = loader(
  '../../graphql/queries/Get_Policies_by_Account.graphql',
);

export function fetchPolicies(
  accountNumber: string,
  awsAppSyncClient: AWSAppSyncClient<any>,
): Promise<ApolloQueryResult<GET_POLICIES_BY_ACCOUNT>> {
  const variables: GET_POLICIES_BY_ACCOUNTVariables = {
    account_number: accountNumber,
  } as GET_POLICIES_BY_ACCOUNTVariables;
  return awsAppSyncClient.query({
    query: getPoliciesByAccountQuery,
    variables,
  }) as Promise<ApolloQueryResult<GET_POLICIES_BY_ACCOUNT>>;
}

export function processAccountPoliciesInformation(
  policiesByAccount: GET_POLICIES_BY_ACCOUNT,
): Policy[] {
  const policies: Policy[] = [];
  const accountItems:
    | (GET_POLICIES_BY_ACCOUNT_account_items | null)[]
    | null
    | undefined = policiesByAccount?.account?.items;
  accountItems?.forEach(
    (accountItem: GET_POLICIES_BY_ACCOUNT_account_items | null) => {
      const accountPoliciesDetails:
        | (GET_POLICIES_BY_ACCOUNT_account_items_policies_items | null)[]
        | null
        | undefined = accountItem?.policies?.items;
      accountPoliciesDetails?.forEach(
        (
          policy: GET_POLICIES_BY_ACCOUNT_account_items_policies_items | null,
          index: number,
        ) => {
          let type: PolicyType | string;
          switch (policy?.policy_type) {
            case PolicyType.HOME:
              if (policy?.form === 'DP3') type = 'Dwelling Fire';
              else if (policy?.form === 'HO4') type = 'Renters';
              else if (policy?.form === 'HO6') type = 'Condo';
              else type = PolicyType.HOME;
              break;
            case PolicyType.AUTO:
              type = PolicyType.AUTO;
              break;
            case PolicyType.COMMERCIAL_AUTO:
              type = PolicyType.COMMERCIAL_AUTO;
              break;
            case PolicyType.COMMERCIAL_UMBRELLA:
              type = PolicyType.COMMERCIAL_UMBRELLA;
              break;
            case PolicyType.BUSINESS_OWNERS:
              type = PolicyType.BUSINESS_OWNERS;
              break;
            case PolicyType.FARM_AND_RANCH:
              type = PolicyType.FARM_AND_RANCH;
              break;
            case PolicyType.FARM_OWNERS_UMBRELLA:
              type = PolicyType.FARM_OWNERS_UMBRELLA;
              break;
            case PolicyType.FARM_OWNERS_AUTO:
              type = PolicyType.FARM_OWNERS_AUTO;
              break;
            case PolicyType.WORKERS_COMP:
              type = PolicyType.WORKERS_COMP;
              break;
            case PolicyType.COMMERCIAL_PACKAGE:
              type = PolicyType.COMMERCIAL_PACKAGE;
              break;
            case PolicyType.UMBRELLA:
              type = PolicyType.UMBRELLA;
              break;
            default:
              type = PolicyType.UNKNOWN;
              break;
          }
          policies[index] = {
            type,
            number: policy?.policy_number,
            issuedOn: policy?.issue_date,
            effective: policy?.effective_date,
            expiration: policy?.expiration_date,
            status: policy?.status,
          };
        },
      );
    },
  );
  return policies;
}

export const hasBeenExpiredLessThan90Days = (
  expirationDate: string,
): boolean => {
  const currentDate = new Date().getTime();
  const ninetyDays = 90 * 24 * 60 * 60 * 1000;
  const invalidationDate = new Date(expirationDate).getTime() + ninetyDays;

  return currentDate < invalidationDate;
};

export const filterPoliciesBySelectedType = (
  policiesByAccount: Policy[],
  type: PolicyType,
) => policiesByAccount.filter((policy: Policy) => policy.type === type);

export const groupPoliciesByType = (
  policiesByAccount: Policy[],
): Policy[][] => {
  const autoPolicies: Policy[] = filterPoliciesBySelectedType(
    policiesByAccount,
    PolicyType.AUTO,
  );
  const commercialPolicies: Policy[] = filterPoliciesBySelectedType(
    policiesByAccount,
    PolicyType.COMMERCIAL_AUTO,
  );
  const homePolicies: Policy[] = policiesByAccount.filter(
    (policy: Policy) =>
      policy.type === PolicyType.HOME ||
      policy.type === 'Dwelling Fire' ||
      policy.type === 'Renters' ||
      policy.type === 'Condo',
  );
  const umbrellaPolicies: Policy[] = filterPoliciesBySelectedType(
    policiesByAccount,
    PolicyType.UMBRELLA,
  );
  const commercialUmbrella: Policy[] = filterPoliciesBySelectedType(
    policiesByAccount,
    PolicyType.COMMERCIAL_UMBRELLA,
  );
  const farmAndRanch: Policy[] = filterPoliciesBySelectedType(
    policiesByAccount,
    PolicyType.FARM_AND_RANCH,
  );
  const farmUmbrella: Policy[] = filterPoliciesBySelectedType(
    policiesByAccount,
    PolicyType.FARM_OWNERS_UMBRELLA,
  );
  const farmOwnersAuto: Policy[] = filterPoliciesBySelectedType(
    policiesByAccount,
    PolicyType.FARM_OWNERS_AUTO,
  );
  const workersComp: Policy[] = filterPoliciesBySelectedType(
    policiesByAccount,
    PolicyType.WORKERS_COMP,
  );
  const businessOwners: Policy[] = filterPoliciesBySelectedType(
    policiesByAccount,
    PolicyType.BUSINESS_OWNERS,
  );
  const commercialPackage: Policy[] = filterPoliciesBySelectedType(
    policiesByAccount,
    PolicyType.COMMERCIAL_PACKAGE,
  );

  // the order matters here
  const groupedPoliciesByType: Policy[][] = [
    autoPolicies,
    homePolicies,
    umbrellaPolicies,
    commercialPolicies,
    commercialUmbrella,
    farmAndRanch,
    farmUmbrella,
    farmOwnersAuto,
    workersComp,
    businessOwners,
    commercialPackage,
  ];

  return groupedPoliciesByType;
};

export const addPoliciesByStatus = (
  policies: Policy[],
  status: string,
  orderedPolicies: Policy[],
) => {
  const filteredPolicies: Policy[] = policies.filter(
    (policy: Policy) => policy.status?.toUpperCase() === status.toUpperCase(),
  );
  return orderedPolicies.concat(filteredPolicies);
};

export const organizePoliciesByStatus = (
  groupedPoliciesByType: Policy[][],
): Policy[] => {
  let cancelledPolicies: Policy[] = [];
  let expiredPolicies: Policy[] = [];

  let groupedPoliciesByStatus: Policy[] = groupedPoliciesByType
    .map((policiesByType: Policy[]) => {
      let orderedPolicies: Policy[] = [];

      orderedPolicies = addPoliciesByStatus(
        policiesByType,
        'INFORCE',
        orderedPolicies,
      );
      orderedPolicies = addPoliciesByStatus(
        policiesByType,
        'CANCELING',
        orderedPolicies,
      );
      orderedPolicies = addPoliciesByStatus(
        policiesByType,
        'RENEWAL',
        orderedPolicies,
      );
      orderedPolicies = addPoliciesByStatus(
        policiesByType,
        'SCHEDULED',
        orderedPolicies,
      );
      orderedPolicies = addPoliciesByStatus(
        policiesByType,
        'RESCINDED',
        orderedPolicies,
      );
      cancelledPolicies = cancelledPolicies.concat(
        policiesByType.filter(
          (policy: Policy) =>
            policy.status?.toUpperCase() === 'CANCELLED' &&
            policy.expiration &&
            hasBeenExpiredLessThan90Days(policy.expiration),
        ),
      );

      expiredPolicies = expiredPolicies.concat(
        policiesByType.filter(
          (policy: Policy) =>
            policy.status?.toUpperCase() === 'EXPIRED' &&
            policy.expiration &&
            hasBeenExpiredLessThan90Days(policy.expiration),
        ),
      );

      return orderedPolicies;
    })
    .flat();

  groupedPoliciesByStatus = groupedPoliciesByStatus.concat(cancelledPolicies);
  groupedPoliciesByStatus = groupedPoliciesByStatus.concat(expiredPolicies);

  return groupedPoliciesByStatus;
};

export function groupPoliciesForUI(
  policiesByAccount: Policy[],
): { policies: Policy[]; count: number } {
  const groupedPoliciesByType: Policy[][] = groupPoliciesByType(
    policiesByAccount,
  );
  const groupedPolicies: Policy[] = organizePoliciesByStatus(
    groupedPoliciesByType,
  );

  return { policies: groupedPolicies, count: groupedPolicies.length };
}

export const pairPoliciesUp = (groupedPolicies: Policy[]): Policy[][] => {
  const uiPolicies: Policy[][] = [];
  let i: number = 0;

  do {
    const leftPolicy: Policy | undefined = groupedPolicies.pop();
    const rightPolicy: Policy | undefined = groupedPolicies.pop();

    if (leftPolicy && rightPolicy) {
      uiPolicies[i] = [leftPolicy, rightPolicy];
    } else if (leftPolicy && !rightPolicy) {
      uiPolicies[i] = [leftPolicy];
    }

    i += 1;
  } while (groupedPolicies.length > 0);

  return uiPolicies;
};

const PoliciesDataContainer = ({
  accountNumber,
  awsAppSyncClient,
  useNavigation,
}: PoliciesDataContainerProps) => {
  const [policies, setPolicies] = useState<OverviewPolicies>(
    {} as OverviewPolicies,
  );
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    fetchPolicies(accountNumber, awsAppSyncClient)
      .then((apolloQueryResult: ApolloQueryResult<GET_POLICIES_BY_ACCOUNT>) => {
        const policiesByAccount: GET_POLICIES_BY_ACCOUNT =
          apolloQueryResult.data;
        const fetchedPolicies: Policy[] = processAccountPoliciesInformation(
          policiesByAccount,
        );
        const groupedPolicies: {
          policies: Policy[];
          count: number;
        } = groupPoliciesForUI(fetchedPolicies);
        const uiPolicies: OverviewPolicies = {
          policies: pairPoliciesUp(groupedPolicies.policies.reverse()),
          accountNumber,
          count: groupedPolicies.count,
        } as OverviewPolicies;
        setPolicies(uiPolicies);
        setLoading(false);
      })
      .catch((err: Error) => {
        console.error('FETCH_POLICIES_BY_ACCOUNT ERROR: ', err);
        setError(err);
        setLoading(false);
      });
  }, [accountNumber]);
  return (
    <>
      <Policies
        policies={policies}
        loading={loading}
        error={error}
        useNavigation={useNavigation}
      />
    </>
  );
};

export default PoliciesDataContainer;
