import { ApolloQueryResult } from 'apollo-client';
import AWSAppSyncClient from 'aws-appsync';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { useFeatureFlag } from '../../util/hooks';
import generateOpenOneIncURL from '../../util/openOneIncURL/generateOpenOneIncURL';
import AgencyBilledPaymentModal, {
  AgencyContactInfo,
} from './AgencyBilledPaymentModal';
import {
  GET_AGENCY_CONTACT_INFO,
  GET_AGENCY_CONTACT_INFOVariables,
} from './graphql/queries/__generated__/GET_AGENCY_CONTACT_INFO';

export const getAgencyInfoQuery = loader(
  './graphql/queries/Get_Agency_Contact_Info.graphql',
);

interface AgencyBilledPaymentModalDataContainerProps {
  accountNumber: string;
  awsAppSyncClient: AWSAppSyncClient<any>;
  setDisplayModal: Function;
  amountDue: string | undefined;
  userId: string | null | undefined;
  showDisclaimer: boolean;
}

export const fetchAgencyInfo = (
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string,
): Promise<ApolloQueryResult<GET_AGENCY_CONTACT_INFO>> => {
  const variables: GET_AGENCY_CONTACT_INFOVariables = {
    account_number: accountNumber,
  } as GET_AGENCY_CONTACT_INFOVariables;

  const queryResult: Promise<
    ApolloQueryResult<GET_AGENCY_CONTACT_INFO>
  > = awsAppSyncClient.query({
    query: getAgencyInfoQuery,
    variables,
  }) as Promise<ApolloQueryResult<GET_AGENCY_CONTACT_INFO>>;

  return queryResult;
};

export const processAgencyInfo = (
  data: GET_AGENCY_CONTACT_INFO,
): AgencyContactInfo | undefined => {
  const agencyInfoAccountItems = data?.account?.items;

  if (
    agencyInfoAccountItems &&
    agencyInfoAccountItems[0] &&
    agencyInfoAccountItems[0].service_tier?.toLowerCase() !== 'platform' &&
    agencyInfoAccountItems[0].agencyContactInfo &&
    agencyInfoAccountItems[0].agencyContactInfo.agency_name &&
    agencyInfoAccountItems[0].agencyContactInfo.business_phone &&
    agencyInfoAccountItems[0].agencyContactInfo.business_email
  ) {
    return {
      name: agencyInfoAccountItems[0].agencyContactInfo.agency_name,
      phone: agencyInfoAccountItems[0].agencyContactInfo.business_phone,
      email: agencyInfoAccountItems[0].agencyContactInfo.business_email,
    };
  }

  return undefined;
};

const AgencyBilledPaymentModalDataContainer = ({
  awsAppSyncClient,
  accountNumber,
  setDisplayModal,
  amountDue,
  userId,
  showDisclaimer,
}: AgencyBilledPaymentModalDataContainerProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [agencyContactInfo, setAgencyContactInfo] = useState<AgencyContactInfo>(
    {
      name: 'State Auto Insurance Services',
      phone: '8663647489',
      email: 'saservice@stateauto.com',
    },
  );
  const { flagDetails: SSOneINCURL } = useFeatureFlag(
    'OneINCSelfServiceMuleURL',
  );

  useEffect(() => {
    fetchAgencyInfo(awsAppSyncClient, accountNumber)
      .then((result: ApolloQueryResult<GET_AGENCY_CONTACT_INFO>) => {
        setLoading(false);
        const processedAgencyInfo:
          | AgencyContactInfo
          | undefined = processAgencyInfo(result.data);

        if (processedAgencyInfo) setAgencyContactInfo(processedAgencyInfo);
      })
      .catch((err: Error) => {
        setLoading(false);
        setError(true);
        console.error('GET_AGENCY_CONTACT_INFO_ERROR: ', err);
      });
  }, [accountNumber]);

  const proceedClickHandler = () =>
    generateOpenOneIncURL(
      awsAppSyncClient,
      accountNumber,
      userId,
      SSOneINCURL?.enabled,
      sessionStorage?.userLOB?.includes('Commercial') ? 'GWCL' : 'GWPL',
    );

  return (
    <AgencyBilledPaymentModal
      loading={loading}
      error={error}
      agencyContactInfo={agencyContactInfo}
      setDisplayModal={setDisplayModal}
      amountDue={amountDue}
      proceedClickHandler={proceedClickHandler}
      showDisclaimer={showDisclaimer}
    />
  );
};

export default AgencyBilledPaymentModalDataContainer;
