import { SAButton, SACard, SAModal, SAText } from '@saux/design-system-react';
import React, { useState } from 'react';
import { ReactComponent as SpinnerLight } from '../../assets/images/spinner-light.svg';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import useIsResponsive from '../../util/useIsResponsive';
import {
  ButtonWrapper,
  CancelWrapper,
  DesktopStopPaymentModalWrapper,
  ResponsiveStopPaymentModalWrapper,
  Spacer,
} from './StopPaymentModal.styles';
import stopPaymentModalText from './stopPaymentModalText';

interface StopPaymentModalProps {
  paymentDueDate: string;
  hasMultiplePolicies: boolean;
  closeOnClickHandler: any;
  confirmOnClickHandler: any;
}

export const LoadingAnimation: Function = () => {
  return <SpinnerLight />;
};

const StopPaymentModal = ({
  paymentDueDate,
  hasMultiplePolicies,
  closeOnClickHandler,
  confirmOnClickHandler,
}: StopPaymentModalProps) => {
  const isResponsive = useIsResponsive();
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const Wrapper = isResponsive
    ? ResponsiveStopPaymentModalWrapper
    : DesktopStopPaymentModalWrapper;
  const height = isResponsive ? window.screen.height : 370;
  const width = isResponsive ? window.screen.width : 590;
  const modalText = stopPaymentModalText(paymentDueDate, hasMultiplePolicies);

  return (
    <SAModal
      onClickHandler={(e: React.MouseEvent) => e.stopPropagation()}
      zIndex={2147483647}
    >
      <Wrapper
        test-attr={
          isResponsive
            ? 'responsive-stop-payment-wrapper'
            : 'desktop-stop-payment-wrapper'
        }
      >
        <SACard
          variant="simple"
          title="Stop Payment Notice"
          height={height}
          width={width}
        >
          <SAText text={modalText.paymentStopped} />
          <Spacer />
          <SAText text={modalText.legalNotice} />
          <ButtonWrapper>
            <CancelWrapper>
              <SAButton
                label="CANCEL"
                test-attr="cancel-payment-button"
                variant="link-large"
                onClick={() => {
                  closeOnClickHandler();
                  simpleGTMDataLayer({
                    event: `Overview-StopPaymentModalCancel-click`,
                    event_action: 'Button Click',
                    event_category: 'Overview',
                    event_label: 'Overview Stop Payment Cancel Button Click',
                  });
                  simpleGTMDataLayer({
                    event: `Snowplow-Overview-StopPaymentModalCancel-click`,
                    event_action: 'Snowplow Button Click',
                    event_category: 'Snowplow Overview',
                    event_label:
                      'Snowplow Overview Stop Payment Cancel Button Click',
                  });
                }}
              />
            </CancelWrapper>
            <SAButton
              label="CONFIRM"
              variant="primary-large"
              test-attr="confirm-stop-payment-button"
              onClick={() => {
                confirmOnClickHandler();
                simpleGTMDataLayer({
                  event: `Overview-StopPaymentModalConfirm-click`,
                  event_action: 'Button Click',
                  event_category: 'Overview',
                  event_label: 'Overview Stop Payment Confirm Button Click',
                });
                simpleGTMDataLayer({
                  event: `Snowplow-Overview-StopPaymentModalConfirm-click`,
                  event_action: 'Snowplow Button Click',
                  event_category: 'Snowplow Overview',
                  event_label:
                    'Snowplow Overview Stop Payment Confirm Button Click',
                });
                setShowSpinner(true);
              }}
            >
              {showSpinner && <LoadingAnimation />}
            </SAButton>
          </ButtonWrapper>
        </SACard>
      </Wrapper>
    </SAModal>
  );
};

export default StopPaymentModal;
