import React, { createContext } from 'react';
import AWSAppSyncClient from 'aws-appsync';

interface ClientProviderProps {
  awsAppSyncClient: AWSAppSyncClient<any>;
  children: any;
}

export const AppsyncClientContext = createContext<AWSAppSyncClient<any> | null>(
  null,
);

const ClientProvider = ({
  awsAppSyncClient,
  children,
}: ClientProviderProps) => {
  return (
    <AppsyncClientContext.Provider value={awsAppSyncClient}>
      {children}
    </AppsyncClientContext.Provider>
  );
};

export default ClientProvider;
