import { useState, useEffect } from 'react';
import customBase from '../global.theme';

const useIsResponsive = () => {
  const [isResponsive, setIsResponsive] = useState<boolean>(
    window.innerWidth < customBase.global.breakpoints.medium,
  );

  useEffect(() => {
    const listenerFunction = () => {
      const currentResponsiveness =
        window.innerWidth < customBase.global.breakpoints.medium;
      if (currentResponsiveness !== isResponsive)
        setIsResponsive(currentResponsiveness);
    };
    window.addEventListener('resize', listenerFunction);
    return () => {
      window.removeEventListener('resize', listenerFunction);
    };
  }, [isResponsive]);

  return isResponsive;
};

export default useIsResponsive;
