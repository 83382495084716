import { SAIcons, SAIconSize, SAText, SAIcon } from '@saux/design-system-react';
import React from 'react';
import { ReactComponent as CarBreakdown } from '../../../assets/images/CarBreakdown.svg';
import {
  Center,
  FillBox,
  StandardText,
  BoldText,
  CloseButtonWrapper,
  ExitBox,
} from './ModalBody.styles';

interface QuestModalProps {
  toggleModal: Function;
}

const QuestModal = ({ toggleModal }: QuestModalProps) => {
  return (
    <FillBox>
      <CloseButtonWrapper>
        <ExitBox>
          <SAIcon
            icon={SAIcons.x}
            colorVariant="dark"
            size={SAIconSize.medium}
            onClick={(event) => {
              event.stopPropagation();
              toggleModal();
            }}
            test-attr="roadside-assistance-modal-quest-exit-button"
          />
        </ExitBox>
      </CloseButtonWrapper>
      <Center isResponsive={false}>
        <CarBreakdown title="Car Breakdown" width="100%" height="100px" />
        <SAText
          text="We’re sorry you had a car breakdown!"
          weight="bold"
          type="heading-2"
        />
        <StandardText>
          We&apos;re here to help, call us at <BoldText>844-546-5393</BoldText>.
        </StandardText>
      </Center>
    </FillBox>
  );
};

export default QuestModal;
