// These should be viewed as ranges
const customBase = {
  global: {
    breakpoints: {
      xsmall: 375,
      small: 576,
      medium: 768,
      large: 992,
      xlarge: 1200,
    },
    spacers: {
      xxsmall: 10,
      med_large: 40,
      large: 60,
    },
  },
};

export default customBase;
