import { SAIcon, SAIcons, SAIconSize } from '@saux/design-system-react';
import React from 'react';
import { FetchDocumentProps } from '../../util/useDocumentDownload/useDocumentDownload';
import { Policy } from '../policies/Policies';
import { QuickLink } from './QuickLinks';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import { useFeatureFlag } from '../../util/hooks';

interface CreateQuickLinksProps {
  state: any;
  config: any;
  onDocumentClick: Function;
  createPolicyDocClickHandler: Function;
  toggleRoadsideModal: Function;
  setShowClaimsDisabledModal: Function;
  ClaimsDisabledFlag: any;
  setShowFnolRedirectModal: Function;
  isPayAsYouGo: boolean;
}

const createQuickLinks = ({
  state,
  config,
  onDocumentClick,
  createPolicyDocClickHandler,
  toggleRoadsideModal,
  setShowClaimsDisabledModal,
  ClaimsDisabledFlag,
  setShowFnolRedirectModal,
  isPayAsYouGo,
}: CreateQuickLinksProps): QuickLink[] => {
  let links: any[] = [];
  const autoPolicy: Policy | undefined = state.policies.find(
    (policy: Policy) =>
      (policy.type === 'Auto' ||
        policy.type === 'Commercial Auto' ||
        policy.type === 'Farmowners Auto') &&
      policy.status !== 'Cancelled',
  );
  const wcPolicy: Policy | undefined = state.policies.find(
    (policy: Policy) =>
      policy.type === 'Workers Comp' && policy.status !== 'Cancelled',
  );

  const { flagDetails: FnolRedirectModalFlag } = useFeatureFlag(
    'FnolRedirectModal',
  );
  const { flagDetails: WCPayrollReports } = useFeatureFlag('WCPayrollReports');
  const { flagDetails: ReportPayrollInAMP } = useFeatureFlag(
    'ReportPayrollInAMP',
  );

  links = [
    {
      icon: <SAIcon icon={SAIcons.document} size={SAIconSize.medium} />,
      label: 'Policy Documents',
      labelId: 'policy-docs-quick-link',
      onClickHandler: () => {
        createPolicyDocClickHandler();
        simpleGTMDataLayer({
          event: `Overview-PolicyDocsQuickLink-click`,
          event_action: 'Quick Link Click',
          event_category: 'Overview',
          event_label: 'Overview Policy Docs Quick Link Click',
        });
        simpleGTMDataLayer({
          event: `Snowplow-Overview-PolicyDocsQuickLink-click`,
          event_action: 'Snowplow Quick Link Click',
          event_category: 'Snowplow Overview',
          event_label: 'Snowplow Overview Policy Docs Quick Link Click',
        });
      },
    },
    {
      icon: <SAIcon icon={SAIcons.claim} size={SAIconSize.medium} />,
      label: 'Submit a Claim',
      labelId: 'submit-claim-quick-link',
      onClickHandler: () => {
        if (ClaimsDisabledFlag?.enabled) {
          setShowClaimsDisabledModal(true);
        } else {
          if (FnolRedirectModalFlag?.enabled) setShowFnolRedirectModal(true);
          else window.open(config.fnol_url, '_blank');
          simpleGTMDataLayer({
            event: 'Overview-SubmitClaimQuickLink-click',
            event_action: 'Quick Link Click',
            event_category: 'Overview',
            event_label: 'Overview Submit Claim Quick Link Click',
          });
          simpleGTMDataLayer({
            event: 'Snowplow-Overview-SubmitClaimQuickLink-click',
            event_action: 'Snowplow Quick Link Click',
            event_category: 'Snowplow Overview',
            event_label: 'Snowplow Overview Submit Claim Quick Link Click',
          });
        }
      },
    },
    {
      icon: <SAIcon icon={SAIcons.billing} size={SAIconSize.medium} />,
      label: 'Additional Billing',
      labelId: 'additional-billing-quick-link',
      onClickHandler: () => {
        window.open('/billing', '_self');
        simpleGTMDataLayer({
          event: `Overview-AdditionalBillingQuickLink-click`,
          event_action: 'Quick Link Click',
          event_category: 'Overview',
          event_label: 'Overview Additional Billing Quick Link Click',
        });
        simpleGTMDataLayer({
          event: `Snowplow-Overview-AdditionalBillingQuickLink-click`,
          event_action: 'Snowplow Quick Link Click',
          event_category: 'Snowplow Overview',
          event_label: 'Snowplow Overview Additional Billing Quick Link Click',
        });
      },
    },
  ];

  if (autoPolicy) {
    links.unshift({
      icon: <SAIcon icon={SAIcons.idCard} size={SAIconSize.medium} />,
      label: 'ID Cards',
      labelId: 'id-cards-quick-link',
      onClickHandler: () => {
        onDocumentClick({
          document_key: state.autoIdDoc.documentsKey,
        } as FetchDocumentProps);
        simpleGTMDataLayer({
          event: `Overview-IDCardsQuickLink-click`,
          event_action: 'Quick Link Click',
          event_category: 'Overview',
          event_label: 'Overview ID Cards Quick Link Click',
        });
        simpleGTMDataLayer({
          event: `Snowplow-Overview-IDCardsQuickLink-click`,
          event_action: 'Snowplow Quick Link Click',
          event_category: 'Snowplow Overview',
          event_label: 'Snowplow Overview ID Cards Quick Link Click',
        });
      },
    });

    if (state.hasRoadsideAssistance)
      links.splice(2, 0, {
        icon: (
          <SAIcon icon={SAIcons.roadsideAssistance} size={SAIconSize.medium} />
        ),
        label: 'Call Roadside Assistance',
        labelId: 'roadside-assist-quick-link',
        onClickHandler: () => {
          toggleRoadsideModal();
          simpleGTMDataLayer({
            event: `Overview-RoadsideAssistanceQuickLink-click`,
            event_action: 'Quick Link Click',
            event_category: 'Overview',
            event_label: 'Overview Roadside Assistance Quick Link Click',
          });
          simpleGTMDataLayer({
            event: `Snowplow-Overview-RoadsideAssistanceQuickLink-click`,
            event_action: 'Snowplow Quick Link Click',
            event_category: 'Snowplow Overview',
            event_label:
              'Snowplow Overview Roadside Assistance Quick Link Click',
          });
        },
      });
  }

  if (
    wcPolicy &&
    isPayAsYouGo &&
    WCPayrollReports &&
    WCPayrollReports?.enabled
  ) {
    links.splice(0, 0, {
      icon: <SAIcon icon={SAIcons.shield} size={SAIconSize.medium} />,
      label: 'WC Payroll Reports',
      labelId: 'wc-payroll-reports-quick-link',
      onClickHandler: () => {
        if (ReportPayrollInAMP && ReportPayrollInAMP?.enabled) {
          window.open(config.amp_url, '_blank');
        } else window.open('/report-payroll?payg=true', '_self');
      },
    });
  }

  return links;
};

export default createQuickLinks;
