import React, { FC } from 'react';
import {
  SAIcon,
  SAText,
  SAIcons,
  SAButton,
  SAIconSize,
} from '@saux/design-system-react';
import {
  Backdrop,
  ModalWrap,
  ModalHeader,
  ModalTextWrap,
  ModalCloseWrap,
  ModalButtonWrap,
  ModalContentWrap,
} from './OutageModalStyles';

interface OutageModalProps {
  component: string;
  setModal: Function;
}

const OutageModal: FC<OutageModalProps> = ({
  component,
  setModal,
}: OutageModalProps) => (
  <Backdrop
    onClick={(e) => {
      if (e.target === e.currentTarget) {
        setModal(false);
      }
    }}
  >
    <ModalWrap>
      <ModalHeader>
        <ModalTextWrap>
          <SAText text={`${component} is Unavailable`} type="heading-3" />
        </ModalTextWrap>
        <ModalCloseWrap
          onClick={() => {
            setModal(false);
          }}
        >
          <SAIcon
            icon={SAIcons.x}
            size={SAIconSize.medium}
            colorVariant="dark"
          />
        </ModalCloseWrap>
      </ModalHeader>
      <ModalContentWrap>
        <SAText
          type="standard"
          text={`Unfortunately, due to server maintenance, ${component.toLowerCase()} is currently unavailable. Please check back in 24 to 48 hours.`}
        />
      </ModalContentWrap>
      <ModalButtonWrap>
        <SAButton
          label="CLOSE"
          fullWidthUnder={768}
          variant="primary"
          onClick={() => {
            setModal(false);
          }}
        />
      </ModalButtonWrap>
    </ModalWrap>
  </Backdrop>
);

export default OutageModal;
