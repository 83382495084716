import { SAAlert, SAButton, SAText } from '@saux/design-system-react';
import React from 'react';
import useIsResponsive from '../../util/useIsResponsive';
import ProductTourBannerWrapper, {
  BannerButton,
} from './ProductTourBanner.styles';

export interface ProductTourBannerProps {
  closeClickHandler: ProductTourCloseClickHandler;
  learnClickHandler: ProductTourLearnClickHandler;
}

export interface ProductTourCloseClickHandler {
  (displayBanner: boolean, productTourCompleted: boolean): void;
}

export interface ProductTourLearnClickHandler {
  (displayBanner: boolean): void;
}

const ProductTourBanner = ({
  closeClickHandler,
  learnClickHandler,
}: ProductTourBannerProps) => {
  const isResponsive = useIsResponsive();

  const MobileAlert = () => (
    <SAAlert
      severity="info"
      closeable
      onClose={() => {
        closeClickHandler(false, true);
      }}
    >
      <SAText
        type="paragraph"
        text="Check out the top features to maximize your insurance experience with us."
      />
      <BannerButton>
        <SAButton
          variant="secondary-link-medium-inverse"
          label="test"
          onClick={() => {
            learnClickHandler(false);
          }}
          className="sa-action-button"
          test-attr="product-tour-banner-learn-button"
        >
          LEARN
        </SAButton>
      </BannerButton>
    </SAAlert>
  );

  const DesktopAlert = () => (
    <SAAlert
      severity="info"
      closeable
      onClose={() => {
        closeClickHandler(false, true);
      }}
      actionLabel="LEARN"
      onActionClick={() => {
        learnClickHandler(false);
      }}
    >
      <SAText
        type="paragraph"
        text="Check out the top features to maximize your insurance experience with us."
      />
    </SAAlert>
  );

  return (
    <ProductTourBannerWrapper>
      {isResponsive ? (
        <MobileAlert test-attr="responsive-product-tour-banner" />
      ) : (
        <DesktopAlert test-attr="desktop-product-tour-banner" />
      )}
    </ProductTourBannerWrapper>
  );
};

export default ProductTourBanner;
