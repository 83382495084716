import { SAIcon, SAIcons, SAIconSize, SAText } from '@saux/design-system-react';
import React, { MutableRefObject } from 'react';
import { ReactComponent as InfoIconWhiteOut } from '../../assets/images/InfoIconWhiteOut.svg';
import {
  DesktopToolTipWrapper,
  FullSizeToolTip,
  InfoIconWhiteOutHolder,
  ToolTipHolder,
} from '../nextPayment/NextPayment.styles';

export interface ToolTip {
  title: string;
  subtitle: string;
}

interface DesktopTooltipProps {
  toolTip: MutableRefObject<ToolTip>;
  setDisplayToolTip: Function;
  displayToolTip: boolean;
  iconSize?: string;
}

const DesktopTooltip = ({
  toolTip,
  setDisplayToolTip,
  displayToolTip,
  iconSize,
}: DesktopTooltipProps) => {
  return (
    <DesktopToolTipWrapper
      test-attr="desktop-tooltip-wrapper"
      onMouseOver={() => {
        setDisplayToolTip(true);
        const siblingSACard: any = document.querySelector(
          '[data-test-id=next-payment-card-overview-page]',
        );
        if (siblingSACard) {
          siblingSACard.style.overflow = 'visible';
        }
      }}
      onMouseLeave={() => {
        setDisplayToolTip(false);
        const siblingSACard: any = document.querySelector(
          '[data-test-id=next-payment-card-overview-page]',
        );
        if (siblingSACard) {
          siblingSACard.style.overflow = 'hidden';
        }
      }}
    >
      <FullSizeToolTip>
        <SAIcon size={iconSize ?? SAIconSize.small} icon={SAIcons.help} />
      </FullSizeToolTip>
      {displayToolTip && (
        <ToolTipHolder>
          <InfoIconWhiteOutHolder>
            <InfoIconWhiteOut />
          </InfoIconWhiteOutHolder>
          <SAText
            type="paragraph"
            colorVariant="highlight-foreground"
            text={toolTip.current.subtitle}
          />
        </ToolTipHolder>
      )}
    </DesktopToolTipWrapper>
  );
};

DesktopTooltip.defaultProps = {
  iconSize: SAIconSize.small,
};

export default DesktopTooltip;
