/* eslint-disable */
const stopPaymentModalText = (
  paymentDueDate: string,
  multiplePolicies: boolean,
) => {
  if (multiplePolicies) {
    return {
      paymentStopped: `The payment will be stopped for the payment due on ${paymentDueDate}. If the payment is not made on the due date, and in the full amount, the policy is at risk for being canceled. `,
      legalNotice:
        'Legal notice for these policies will be mailed and posted on the Customer Connect account, ' +
        'which will state the exact date and time of the cancellation. To keep these policies in force, ' +
        'payment must be received prior to the cancellation effective date, ' +
        'stated on the Notice of Cancellation for Non-Payment of Premium.',
    };
  }
  return {
    paymentStopped: `The payment will be stopped for the payment due on ${paymentDueDate}. If the payment is not made on the due date, and in the full amount, the policy is at risk for being canceled.`,
    legalNotice:
      'Legal notice for the policy will be mailed and posted on the Customer Connect account, ' +
      'which will state the exact date and time of the cancellation. To keep the policy in force, ' +
      'payment must be received prior to the cancellation effective date, ' +
      'stated on the Notice of Cancellation for Non-Payment of Premium.',
  };
};

export default stopPaymentModalText;
