import { SAUXTheme } from '@saux/design-system-react';
import styled from 'styled-components';

export const LinksContainer = styled.ul`
  ${({ theme }: { theme: SAUXTheme }) => `
      list-style: none;
      > li {
        margin-top: ${theme.size.spacer.small};
        &:first-child {
          margin-top: 0;
        }
      }
  `};
`;

export const LinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  *:hover {
    cursor: pointer;
  }
`;

export const LabelTextWrapper = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    margin-left: ${theme.size.spacer.medium};
    color: #041E41;
  `}
`;
