import {
  SACard,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAModal,
  SATag,
  SAText,
} from '@saux/design-system-react';
import React from 'react';
import { formatDate } from '../../util/formatters';
import { FetchDocumentProps } from '../../util/useDocumentDownload/useDocumentDownload';
import {
  matchedIconToPolicyType,
  matchedTagToPolicyStatus,
  Policy,
} from '../policies/Policies';
import {
  StyledButton,
  StyledExpDate,
  StyledExpirationHolder,
  StyledIssuedOn,
  StyledRightColumn,
  StyledTagHolder,
} from '../policies/Policies.styles';
import {
  CloseButtonWrapper,
  DesktopModalBody,
  MobileModalBody,
  TopSectionWrapper,
  ButtonContainer,
  ButtonWrapper,
} from './MultiplePoliciesModal.styles';

interface MultiplePoliciesModalProps {
  policies: Policy[];
  setPolicyNum: Function;
  policyDocOnClick: Function;
  togglePolicyModal: Function;
}

const MultiplePoliciesModal = ({
  policies,
  setPolicyNum,
  policyDocOnClick,
  togglePolicyModal,
}: MultiplePoliciesModalProps) => {
  const renderModalBody = () => (
    <SACard variant="minimal">
      <CloseButtonWrapper className="policy-ql-modal-close-button">
        <SAIcon
          icon={SAIcons.x}
          size={SAIconSize.medium}
          colorVariant="dark"
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();
            togglePolicyModal();
            document.body.style.overflow = 'auto';
          }}
        />
      </CloseButtonWrapper>
      <TopSectionWrapper>
        <SAText
          type="paragraph"
          text="Download Policy Document"
          weight="bold"
        />
        <SAText
          type="paragraph"
          text="Select the policy to download the document"
        />
      </TopSectionWrapper>
      <ButtonContainer>
        <ButtonWrapper>
          {policies.map((policy: Policy) => (
            <StyledButton
              id={`ql-card-${policy.number}`}
              key={policy.number}
              onClick={() => {
                setPolicyNum(policy.number);
                policyDocOnClick({
                  policy_number: policy.number,
                } as FetchDocumentProps);
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ flexDirection: 'column' }}>
                  <div
                    style={{
                      marginLeft: '8px',
                      marginTop: '14px',
                      marginBottom: '10px',
                    }}
                  >
                    {matchedIconToPolicyType(policy)}
                  </div>
                </div>
                <div style={{ flexDirection: 'column' }}>
                  <div
                    style={{
                      textAlign: 'left',
                      paddingLeft: '15px',
                      paddingTop: '10px',
                      margin: '0px',
                    }}
                  >
                    <SAText
                      type="standard"
                      text={policy.number}
                      weight="bold"
                    />
                  </div>
                  <StyledIssuedOn>
                    <SAText
                      type="small"
                      text={`${
                        policy.status?.toLowerCase() === 'inforce'
                          ? `Effective on ${formatDate(policy.effective)}`
                          : `Issued on ${formatDate(policy.issuedOn)}`
                      }`}
                    />
                  </StyledIssuedOn>
                </div>
                <StyledRightColumn>
                  <StyledExpirationHolder>
                    <SAText type="small" text="Expiration" />
                  </StyledExpirationHolder>
                  <StyledExpDate>
                    <SAText
                      type="small"
                      text={policy.expiration}
                      weight="bold"
                    />
                  </StyledExpDate>
                  <StyledTagHolder>
                    <SATag
                      variant={matchedTagToPolicyStatus(policy)}
                      label={`${policy.status}`}
                    />
                  </StyledTagHolder>
                </StyledRightColumn>
              </div>
            </StyledButton>
          ))}
        </ButtonWrapper>
      </ButtonContainer>
    </SACard>
  );

  return (
    <SAModal onClickHandler={(e: React.MouseEvent) => e.stopPropagation()}>
      <DesktopModalBody id="multiple-policy-modal-desktop">
        {renderModalBody()}
      </DesktopModalBody>
      <MobileModalBody id="multiple-policy-modal-responsive">
        {renderModalBody()}
      </MobileModalBody>
    </SAModal>
  );
};

export default MultiplePoliciesModal;
