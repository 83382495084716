import {
  SAButton,
  SACard,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAModal,
  SAText,
} from '@saux/design-system-react';
import { ApolloQueryResult } from 'apollo-client';
import AWSAppSyncClient from 'aws-appsync';
import { DocumentNode } from 'graphql';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { isFirefox, isIE, isSafari } from 'react-device-detect';
import { ReactComponent as Loading } from '../../assets/images/Loading.svg';
import {
  DesktopModalBody,
  MobileModalBody,
} from '../../components/multiplePoliciesModal/MultiplePoliciesModal.styles';
import {
  GET_ACCOUNT_DOCUMENT,
  GET_ACCOUNT_DOCUMENTVariables,
} from '../../generated/GET_ACCOUNT_DOCUMENT';
import {
  GET_POLICY_DOCUMENT,
  GET_POLICY_DOCUMENTVariables,
} from '../../generated/GET_POLICY_DOCUMENT';
import {
  Box,
  Close,
  Content,
  LoadingHolder,
  Spacer,
  StyledFooter,
  Title,
} from './useDocumentDownloadStyles';

export interface FetchDocumentProps extends Headers {
  document_key?: string;
  communication_id?: string;
  invoice_number?: string;
  policy_number?: string;
  snapshot_id?: string;
}

export default (
  awsAppSyncClient: AWSAppSyncClient<any>,
  account_number: string,
) => {
  const [showModal, setShowModal] = useState(false);
  const [showLoadingMsg, setLoadingMsg] = useState(true);
  const [documentError, setDocumentError] = useState<null | Error>(null);
  const closeModal = () => {
    setDocumentError(null);
    setLoadingMsg(true);
    setShowModal(false);
  };

  const policyDocQuery: DocumentNode = loader(
    '../../graphql/queries/Get_Policy_Document.graphql',
  );
  const accountDocQuery: DocumentNode = loader(
    '../../graphql/queries/Get_Account_Document.graphql',
  );

  const fetchPolicyDoc = (
    accountNumber: string,
    fetchDocumentProps: FetchDocumentProps,
  ): Promise<ApolloQueryResult<GET_POLICY_DOCUMENT>> => {
    const variables: GET_POLICY_DOCUMENTVariables = {
      account_number: accountNumber,
      ...fetchDocumentProps,
    };
    const queryResult: Promise<
      ApolloQueryResult<GET_POLICY_DOCUMENT>
    > = awsAppSyncClient.query({
      query: policyDocQuery,
      variables,
      fetchPolicy: 'no-cache',
    }) as Promise<ApolloQueryResult<GET_POLICY_DOCUMENT>>;
    return queryResult;
  };

  const fetchAccountDoc = (
    accountNumber: string,
    fetchDocumentProps: FetchDocumentProps,
  ): Promise<ApolloQueryResult<GET_ACCOUNT_DOCUMENT>> => {
    const variables: GET_ACCOUNT_DOCUMENTVariables = {
      account_number: accountNumber,
      ...fetchDocumentProps,
    };
    const queryResult: Promise<
      ApolloQueryResult<GET_ACCOUNT_DOCUMENT>
    > = awsAppSyncClient.query({
      query: accountDocQuery,
      variables,
      fetchPolicy: 'no-cache',
    }) as Promise<ApolloQueryResult<GET_ACCOUNT_DOCUMENT>>;
    return queryResult;
  };

  const onDocumentClick = (fetchDocumentProps: FetchDocumentProps) => {
    setShowModal(true);

    if (fetchDocumentProps.document_key) {
      fetchAccountDoc(account_number, fetchDocumentProps)
        .then((data) => {
          setDocumentError(null);

          setShowModal(false);
          const url = data.data.account?.items
            ? data.data.account?.items[0]?.account_document?.document?.url
            : '';

          if (url && !isFirefox && !isSafari) {
            if (window.screen.width <= 750) {
              window.location.assign(url);
            } else {
              const newWindow = window.open('about:blank');
              const p = newWindow?.document.body.appendChild(
                document.createElement('p'),
              ) as any;

              p.innerHTML = 'Loading...';

              p?.setAttribute(
                'style',
                'font-size: 32px;display: inline-block;margin-top: 50px;',
              );
              newWindow?.location.replace(url);
            }
          } else if (url && (isFirefox || isSafari)) {
            window.location.assign(url);
          }
        })
        .catch(() => {
          setDocumentError(new Error('Failed to open window'));
        });
    } else {
      fetchPolicyDoc(account_number, fetchDocumentProps)
        .then((data) => {
          setDocumentError(null);
          setShowModal(false);
          const url = data.data.account?.items
            ? data.data.account?.items[0]?.policy?.document?.url
            : undefined;

          if (url && !isFirefox && !isSafari) {
            if (window.screen.width <= 750) {
              window.location.assign(url);
            } else {
              const newWindow = window.open('about:blank');
              const p = newWindow?.document.body.appendChild(
                document.createElement('p'),
              ) as any;

              p.innerHTML = 'Loading...';

              p?.setAttribute(
                'style',
                'font-size: 32px;display: inline-block;margin-top: 50px;',
              );
              newWindow?.location.replace(url);
            }
          } else if (url && (isFirefox || isSafari)) {
            window.location.assign(url);
          }
        })
        .catch(() => {
          setDocumentError(new Error('Failed to open window'));
        });
    }
  };

  const renderModalBody = () => (
    <SACard variant="minimal">
      {!documentError && !isIE && (
        <>
          <Content>
            {showLoadingMsg && (
              <LoadingHolder>
                <Loading title="Loading" width="100%" height="150px" />
              </LoadingHolder>
            )}
            <Box>{showLoadingMsg && 'Preparing your document...'}</Box>
          </Content>
        </>
      )}

      {documentError && (
        <>
          <Title>
            <SAIcon icon={SAIcons.alert} size={SAIconSize.large} />
            <Spacer />
            <SAText
              type="heading-2"
              weight="bold"
              text="Download Policy Documents"
            />
          </Title>
          <Content>
            <SAText
              type="heading-3"
              weight="normal"
              text="Error preparing document."
            />
          </Content>
        </>
      )}

      {isIE && (
        <>
          <Title>
            <SAIcon icon={SAIcons.alert} size={SAIconSize.large} />
            <Spacer />
            <SAText
              type="heading-2"
              weight="bold"
              text="Download Policy Documents"
            />
          </Title>
          <Content>
            <SAText
              type="heading-3"
              weight="normal"
              text="Downloading documents is currently not supported in Internet Explorer"
            />
            <SAText
              type="heading-3"
              weight="normal"
              text="Please use a more current browser such a Microsoft Edge, Google Chrome or Firefox."
            />
          </Content>
        </>
      )}

      <StyledFooter>
        <Close>
          <SAButton
            label="CLOSE"
            onClick={() => closeModal()}
            variant="primary"
          />
        </Close>
      </StyledFooter>
    </SACard>
  );

  const renderDocumentsModal = () => (
    <SAModal onClickHandler={(e: React.MouseEvent) => e.stopPropagation()}>
      <DesktopModalBody id="multiple-policy-modal-desktop">
        {renderModalBody()}
      </DesktopModalBody>
      <MobileModalBody id="multiple-policy-modal-responsive">
        {renderModalBody()}
      </MobileModalBody>
    </SAModal>
  );

  let documentsModal: JSX.Element = <div />;
  if (showModal) {
    documentsModal = renderDocumentsModal();
  }
  return {
    onDocumentClick,
    documentsModal,
  };
};
