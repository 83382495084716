import {
  SAIcon,
  SAIcons,
  SAIconSize,
  SAPagination,
  SATag,
  SAText,
} from '@saux/design-system-react';
import React, { ReactElement, useState } from 'react';
import { ReactComponent as Condo } from '../../assets/images/Condo.svg';
import { ReactComponent as DwellingFire } from '../../assets/images/DwellingFire.svg';
import { ReactComponent as Renters } from '../../assets/images/Renters.svg';
import { formatDate } from '../../util/formatters';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import {
  IconWrapper,
  PolicyInfo,
  PolicyNumEffectiveDate,
  PolicyNumWrapper,
  StyledButton,
  StyledExpDate,
  StyledExpirationHolder,
  StyledIssuedOn,
  StyledPoliciesList,
  StyledRightColumn,
  StyledTagHolder,
} from './Policies.styles';

interface PoliciesProps {
  policies?: OverviewPolicies;
  loading?: boolean;
  error?: Error;
  useNavigation: Function;
}

export enum PolicyType {
  HOME = 'Home',
  AUTO = 'Auto',
  UMBRELLA = 'Umbrella',
  UNKNOWN = 'Unknown',
  COMMERCIAL_AUTO = 'Commercial Auto',
  COMMERCIAL_UMBRELLA = 'Commercial Umbrella',
  FARM_AND_RANCH = 'Farm & Ranch',
  FARM_OWNERS_UMBRELLA = 'Farmowners Umbrella',
  FARM_OWNERS_AUTO = 'Farmowners Auto',
  WORKERS_COMP = 'Workers Comp',
  BUSINESS_OWNERS = 'Businessowners',
  COMMERCIAL_PACKAGE = 'Commercial Package',
}

export interface Policy {
  type: PolicyType | string | undefined;
  number: string | undefined;
  issuedOn: string | undefined;
  effective: string | undefined;
  expiration: string | undefined;
  status: string | undefined;
}

export interface OverviewPolicies {
  accountNumber: string;
  policies: Policy[][];
  count: number;
}

export const matchedIconToPolicyType = (
  policy: Policy,
): ReactElement | undefined => {
  switch (policy.type) {
    case PolicyType.AUTO:
    case PolicyType.COMMERCIAL_AUTO:
    case PolicyType.FARM_OWNERS_AUTO:
      return (
        <SAIcon
          icon={SAIcons.auto}
          size={SAIconSize.medium}
          colorVariant="primary"
        />
      );
    case PolicyType.HOME:
    case PolicyType.BUSINESS_OWNERS:
    case PolicyType.FARM_AND_RANCH:
      return (
        <SAIcon
          icon={SAIcons.home}
          size={SAIconSize.medium}
          colorVariant="primary"
        />
      );
    case PolicyType.UMBRELLA:
    case PolicyType.COMMERCIAL_UMBRELLA:
    case PolicyType.FARM_OWNERS_UMBRELLA:
    case PolicyType.WORKERS_COMP:
    case PolicyType.COMMERCIAL_PACKAGE:
      return (
        <SAIcon
          icon={SAIcons.umbrella}
          size={SAIconSize.medium}
          colorVariant="primary"
        />
      );
    case 'Dwelling Fire':
      return <DwellingFire className="dwelling-fire-icon" />;
    case 'Renters':
      return <Renters className="renters-icon" />;
    case 'Condo':
      return <Condo className="condo-icon" />;
    default:
      return undefined;
  }
};

export const matchedTagToPolicyStatus = (policy: Policy) => {
  switch (policy.status?.toLowerCase()) {
    case 'cancelled':
      return 'alert-outline';
    case 'expired':
      return 'alert-outline';
    case 'canceling':
      return 'primary-outline';
    case 'bound':
      return 'secondary-outline';
    case 'renewal':
      return 'secondary-outline';
    case 'inforce':
      return 'secondary-outline';
    case 'rescinded':
      return 'secondary-outline';
    default:
      return 'standard-outline';
  }
};

enum PaginationNavigation {
  FIRST = 'First',
  NEXT = 'Next',
  PREVIOUS = 'Previous',
  LAST = 'Last',
}

const Policies = ({
  policies,
  loading,
  error,
  useNavigation,
}: PoliciesProps) => {
  const [selectedPageIndex, setSelectedPageIndex] = useState<number>(0);
  const [
    policyPaginationNav,
    setPolicyPaginationNav,
  ] = useState<PaginationNavigation>(PaginationNavigation.FIRST);
  if (loading) {
    return <SAText type="standard" text="Loading..." />;
  }
  if (error) {
    return <SAText type="standard" text="Error" />;
  }
  if (
    !policies ||
    policies.count === 0 ||
    !policies.policies ||
    policies.policies.length === 0
  ) {
    return <SAText type="standard" text="No policies exist for your account" />;
  }
  const totalPages = Math.trunc(policies.count / 2 / 3) + 1;
  const policyList: Policy[][] = policies.policies;
  let currentPolicies: Policy[][];
  function policyPageChange(newCurrentPageIndex: number) {
    if (newCurrentPageIndex === 0) {
      setPolicyPaginationNav(PaginationNavigation.FIRST);
    } else if (totalPages - 1 === newCurrentPageIndex) {
      setPolicyPaginationNav(PaginationNavigation.LAST);
    } else if (selectedPageIndex < newCurrentPageIndex) {
      setPolicyPaginationNav(PaginationNavigation.NEXT);
    } else if (selectedPageIndex > newCurrentPageIndex) {
      setPolicyPaginationNav(PaginationNavigation.PREVIOUS);
    }
    setSelectedPageIndex(newCurrentPageIndex);
  }
  let startIndex: number = 0;
  let lastIndex: number = 3;
  switch (policyPaginationNav) {
    case PaginationNavigation.LAST:
      startIndex = (totalPages - 1) * 2 + 1;
      currentPolicies = policyList.slice(startIndex);
      break;
    case PaginationNavigation.PREVIOUS:
    case PaginationNavigation.NEXT:
      startIndex = selectedPageIndex * 2 + 1;
      lastIndex = startIndex + 3;
      currentPolicies = policyList.slice(startIndex, lastIndex);
      break;
    case PaginationNavigation.FIRST:
    default:
      currentPolicies = policyList.slice(startIndex, lastIndex);
      break;
  }

  const capitalizeString = (str: string | undefined) => {
    if (str) {
      const capitalizedStr = str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
      return capitalizedStr
    }
    return str
  }

  return (
    <div className="policies-component">
      <div style={{ flexDirection: 'column' }}>
        {currentPolicies?.map((pairedPolicies: Policy[]) => (
          <StyledPoliciesList key={pairedPolicies[0].number}>
            {pairedPolicies?.map((policy: Policy) => (
              <StyledButton
                key={policy.number}
                id={`overview-policy-number-${policy.number}`}
                onClick={() => {
                  if (policy?.number) {
                    sessionStorage.setItem(
                      'selectedPolicyNumber',
                      policy.number,
                    );

                    useNavigation('/policy');
                  }

                  simpleGTMDataLayer({
                    event: `Overview-PolicyContainer-click`,
                    event_action: 'Policy Link Click',
                    event_category: 'Overview',
                    event_label: 'Overview Policy Container Click',
                  });
                  simpleGTMDataLayer({
                    event: `Snowplow-Overview-PolicyContainer-click`,
                    event_action: 'Snowplow Policy Link Click',
                    event_category: 'Snowplow Overview',
                    event_label: 'Snowplow Overview Policy Container Click',
                  });
                }}
              >
                <PolicyInfo>
                  <IconWrapper>{matchedIconToPolicyType(policy)}</IconWrapper>
                  <PolicyNumEffectiveDate>
                    <PolicyNumWrapper>
                      <SAText
                        type="standard"
                        text={policy.number}
                        weight="bold"
                      />
                    </PolicyNumWrapper>
                    <StyledIssuedOn>
                      <SAText
                        type="small"
                        text={`${
                          policy.status?.toLowerCase() === 'inforce'
                            ? `Effective on ${formatDate(policy.effective)}`
                            : `Issued on ${formatDate(policy.issuedOn)}`
                        }`}
                      />
                    </StyledIssuedOn>
                  </PolicyNumEffectiveDate>
                  <StyledRightColumn>
                    <StyledExpirationHolder>
                      <SAText type="small" text="Expiration" />
                    </StyledExpirationHolder>
                    <StyledExpDate>
                      <SAText
                        type="small"
                        text={formatDate(policy.expiration)}
                        weight="bold"
                      />
                    </StyledExpDate>
                    <StyledTagHolder>
                      <SATag
                        variant={matchedTagToPolicyStatus(policy)}
                        label={`${
                          policy.status === 'InForce' || policy.status === 'RESCINDED'
                            ? 'In Force'
                            : capitalizeString(policy.status)
                        }`}
                      />
                    </StyledTagHolder>
                  </StyledRightColumn>
                </PolicyInfo>
              </StyledButton>
            ))}
          </StyledPoliciesList>
        ))}
      </div>
      <div
        className="policies-pagination"
        style={{
          display: 'flex',
          flexDirection: 'row',
          position: 'relative',
          bottom: -10,
          left: 425,
        }}
      >
        <div style={{ flexDirection: 'column', right: 0 }}>
          <SAPagination
            currentPageIndex={selectedPageIndex}
            totalItems={policies.count}
            itemsPerPage={6}
            onChange={policyPageChange}
          />
        </div>
      </div>
    </div>
  );
};

Policies.defaultProps = {
  policies: undefined,
  loading: false,
  error: undefined,
};

export default Policies;
