import { SAUXTheme } from '@saux/design-system-react';
import styled from 'styled-components';
import customBase from '../../util/global.theme';

// z-index of 2147483601 is one unit above the freshchat icon
export const Modal = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      z-index: 2147483601;
      overflow-y: hidden;
      width: 100vw;
      height: 100vh;
      background-color: ${theme?.color.primary.foreground};
      border-radius: ${theme?.size.radius.small};
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.3s ease-out;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      touch-action: none;
      padding: 35px;
      
      @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
        width: 600px;
        height: 300px;
      }
      `;
  }}
`;

export const Backdrop = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(4, 30, 65, 0.6);
  z-index: 2147483601;
  overflow-y: hidden;
  touch-action: none;
`;

export const BottomContentWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: auto;
`;

export const TitleWrapper = styled.div`
  margin-bottom: 8px;
`;

export const SubTitleWrapper = styled.div`
  text-align: left;
`;

export const ToolTipHolder = styled.div`
  position: absolute;
`;
