import styled from 'styled-components';
import customBase from '../../util/global.theme';

const breakPoint = customBase.global.breakpoints.medium;

export const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  background-color: rgba(4, 30, 65, 0.75);
  z-index: 9999;
`;

export const ModalWrap = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  @media screen and (min-width: ${breakPoint + 1}px) {
    border-radius: 4px;
    width: 470px;
  }
  @media screen and (max-width: ${breakPoint}px) {
    width: 100%;
    height: 100%;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  @media screen and (max-width: ${breakPoint}px) {
    border-bottom: 1px solid #d2dbe7;
  }
`;

export const ModalTextWrap = styled.div`
  padding: 30px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  @media screen and (max-width: ${breakPoint}px) {
    padding: 24px 20px;
  }
`;

export const ModalCloseWrap = styled.div`
  padding: 20px;
  cursor: pointer;
  width: 70px;
  height: 70px;
  align-self: flex-start;
`;

export const ModalContentWrap = styled.div`
  padding: 5px 35px 50px;
  @media screen and (max-width: ${breakPoint}px) {
    padding-top: 30px;
  }
`;

export const ModalHeaderWrap = styled.div`
  padding: 5px 35px 20px;
  @media screen and (max-width: ${breakPoint}px) {
    padding-top: 30px;
  }
`;

export const ModalSelectionWrap = styled.div`
  padding: 0px 35px 50px;
  @media screen and (max-width: ${breakPoint}px) {
    padding-top: 30px;
  }
`;

export const ModalButtonWrap = styled.div`
  margin-top: auto;
  margin-left: auto;
  padding: 0px 30px 30px 30px;
  @media screen and (max-width: ${breakPoint}px) {
    margin-left: 0px;
    padding: 0px 30px 100px 30px;
  }
`;

export const ModalDoubleButtonWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 0px;
  margin-top: auto;
  padding: 30px 30px 100px 30px;
  @media screen and (min-width: ${breakPoint}px) {
    padding: 30px 30px 30px 130px;
  }
`;
