import React from 'react';
import {
  OctoCardImg,
  OctoCardLeft,
  OctoCardRight,
  OctoCardWrap,
  OctoDescription,
  OctoSwitchNow,
  OctoTitle,
} from './OctoCard.styles';

interface OctoCardProps {
  error: boolean;
  displayOctoCard: boolean;
}

const OctoCard = ({ error, displayOctoCard }: OctoCardProps) => {
  const handleClick = () => {
    window.open('http://stateauto.com/switch', '_blank');
  };

  if (error || !displayOctoCard) {
    return <></>;
  }

  return (
    <OctoCardWrap onClick={() => handleClick()}>
      <OctoCardLeft>
        <OctoCardImg />
      </OctoCardLeft>
      <OctoCardRight>
        <OctoTitle>
          Switch to the Tag today and keep your driving score up-to-date!
        </OctoTitle>
        <OctoDescription>
          We&#39;ll discontinue support for the Dongle device&nbsp;
          <b>beginning January 1, 2022.</b>
        </OctoDescription>
        <OctoSwitchNow>SWITCH NOW</OctoSwitchNow>
      </OctoCardRight>
    </OctoCardWrap>
  );
};

export default OctoCard;
