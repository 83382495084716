import { SAUXTheme } from '@saux/design-system-react';
import styled from 'styled-components';
import customBase from '../../util/global.theme';

export const LastDayToPayText = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
  max-width: 350px;
  small {
    color: #7A5212;
  }
  height: 40px;
  margin: ${theme.size.spacer.small} -90px ${theme.size.spacer.medium} 0px;
  @media screen and (max-width: ${customBase.global.breakpoints.medium}px) {
    margin: ${theme.size.spacer.medium} 0px ${theme.size.spacer.large} 0px;
  }
  `}
`;

export const LastDayToPaySpacer = styled.div`
  height: 30px;
`;

export const NextPaymentWrapper = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    display: flex;
    flex-direction: column;
    padding-left: ${theme.size.spacer.medium};
    min-height: 161px;
    
    @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
      padding-left: 0px;
      min-width: 360px;
      justify-content: space-between;
    }
  `}
`;

export const NextPaymentWrapperError = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
    display: flex;
    flex-direction: column;
    min-width: 300px;
    min-height: 161px;

    @media screen and (max-width: ${customBase.global.breakpoints.medium}px) {
      flex-direction: row;
      justify-content: space-between;
      padding-left: ${theme.size.spacer.large};
    }
  `;
  }}
`;

export const AmountDueWrapper = styled.div`
  min-width: 132px;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
`;

export const AmountDue = styled.p`
  ${({ theme }: { theme: SAUXTheme }) => `
    font-size: 40px;
    font-weight: ${theme.font.primary.weight.bold};
    color: ${theme.colors.accentBlue900}
  `}
`;

export const DueDate = styled.p`
  ${({ theme }: { theme: SAUXTheme }) => `
    font-size: ${theme.font.primary.size.mediumTEMP};
  `}
`;

export const MakePaymentButtonWrapper = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    display: flex;
    justify-content: left;
    @media screen and (max-width: ${customBase.global.breakpoints.small}px) {
      width: auto;
      margin-right: ${theme.size.spacer.small};
    }
`}
`;

export const Border = styled.div`
  border-bottom: 1px solid transparent;
`;

export const MakePaymentButtonSpacer = styled.div`
  margin-bottom: 19px;
`;

export const ResponsiveButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const DesktopButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 15px;
`;

export const PastDueHeader = styled.p`
  ${({ theme }: { theme: SAUXTheme }) => `
    color: #041E41;
    font-size: ${theme.font.primary.size.smallTEMP};
    font-weight: ${theme.font.primary.weight.bold};
  `}
`;

export const PastDueIndicator = styled.div`
  display: flex;
`;

export const ResponsiveTooltipIconWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const DesktopScheduleTextHolder = styled.div`
  display: none;
  @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
    display: flex;
    flex-direction: row;
    gap: 5px;
  }
`;

export const MobileScheduleTextHolder = styled.div`
  display: block;
  @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
    display: none;
  }
`;

export const DesktopToolTipWrapper = styled.div`
  position: relative;
  &:hover {
    cursor: help;
  }
`;

export const FullSizeToolTip = styled.div`
  margin-top: 6px;
`;

export const InfoIconWhiteOutHolder = styled.div`
  padding: 7px 12px 0px 0px;
`;

export const ToolTipHolder = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
  position: absolute;
  margin-top: -41px;
  margin-left: 24px;
  width: 275px;
  display: flex;
  flex-direction: row;
  background-color: #3e5572;
  border-radius: ${theme.size.radius.small};
  color: #fff;
  padding: 11px 12px 13px 12px;
`}
`;

export const MortgageePaymentWordingText = styled.div`
  margin: 6px 12px 0px 0px;
  @media screen and (min-width: ${customBase.global.breakpoints.small}px) {
    margin: 0px 12px 0px 0px;
  }
`;
