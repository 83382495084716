import { SAButton, SAText } from '@saux/design-system-react';
import React, { useState } from 'react';
import Joyride, {
  ACTIONS,
  CallBackProps,
  EVENTS,
  Step,
  TooltipRenderProps,
} from 'react-joyride';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import { useFeatureFlag } from '../../util/hooks';
import useIsResponsive from '../../util/useIsResponsive';
import ProductTourBanner, {
  ProductTourCloseClickHandler,
  ProductTourLearnClickHandler,
} from '../productTourBanner/ProductTourBanner';
import ToolTipWrapper, {
  ProductTourWrapper,
  SkipButton,
  ToolTipContent,
  ToolTipSkipNext,
  ToolTipTitle,
} from './ProductTour.styles';

export interface ProductTourState {
  stepIndex: number;
  run: boolean;
}

interface StepContent {
  title: string;
  body: string;
}

interface TourProps {
  useNavigation: Function;
  productTourCompleted: boolean | undefined;
  setProductTourCompleted: Function;
  displayBanner: boolean;
  setDisplayBanner: Function;
}

interface ProductTourSession {
  currentStep: number;
  totalSteps: number;
  displayTour: boolean;
  currentPage: string;
}

const updateProductTourSession = (updateData: any) =>
  sessionStorage.setItem('productTourSession', JSON.stringify(updateData));

const stepText: { [key: string]: StepContent } = {
  StopPayment: {
    title: 'Need to put your upcoming payment on hold?',
    body: 'You can stop payment on the Overview and Billing pages.',
  },
  MyAgent: {
    title: 'Need to make a change to your policy?',
    body: "You can find your agent's contact information on the Overview page.",
  },
};

// The reason for Tooltip and handleJoyrideCallback to be closures
// is for testing purposes. It is very challenging to unit test Joyride

export const Tooltip = (
  tour: ProductTourState,
  productTourSession: ProductTourSession,
) => {
  return ({
    continuous,
    step,
    closeProps,
    primaryProps,
    tooltipProps,
  }: TooltipRenderProps) => {
    const { title } = stepText[step.content as string];
    const { body } = stepText[step.content as string];

    const currentStep: string = (tour.stepIndex + 1).toString();
    const totalSteps: string = productTourSession.totalSteps.toString();

    return (
      <ToolTipWrapper {...tooltipProps}>
        <ToolTipContent>
          <SAText
            text={`${currentStep}/${totalSteps}`}
            type="standard"
            weight="bold"
          />
          <ToolTipTitle>
            <SAText text={title} type="heading-3" weight="bold" />
          </ToolTipTitle>
          <SAText text={body} type="paragraph" />
        </ToolTipContent>
        {continuous && (
          <ToolTipSkipNext>
            <SkipButton>
              <SAButton variant="link-medium" label="SKIP" {...closeProps} />
            </SkipButton>
            <SAButton variant="medium" label="NEXT" {...primaryProps} />
          </ToolTipSkipNext>
        )}
      </ToolTipWrapper>
    );
  };
};

export const handleJoyrideCallback = (
  productTourSession: ProductTourSession,
  tour: ProductTourState,
  setTour: Function,
  setProductTourCompleted: Function,
) => {
  return (data: CallBackProps) => {
    const { action, index, type } = data;

    if (type === EVENTS.STEP_AFTER) {
      // Update state to advance the tour
      if (action !== ACTIONS.CLOSE) {
        updateProductTourSession({
          ...productTourSession,
          currentStep: productTourSession.currentStep + 1,
        });
        setTour({
          ...tour,
          stepIndex: index + 1,
        });
        simpleGTMDataLayer({
          event: `NextButton-click`,
          event_action: 'Next Button Click',
          event_category: 'Next',
          event_label: 'Next Button Click',
          event_category_snowplow: 'Snowplow Next',
        });
      }

      if (action === ACTIONS.CLOSE) {
        document.body.style.overflow = 'auto';
        setProductTourCompleted(true);
        updateProductTourSession({
          ...productTourSession,
          displayTour: false,
        });
        setTour({
          ...tour,
          run: false,
        });
        simpleGTMDataLayer({
          event: `SkipButton-click`,
          event_action: 'Skip Button Click',
          event_category: 'Skip',
          event_label: 'Skip Button Click',
          event_category_snowplow: 'Snowplow Skip',
        });
      }
    }
  };
};

const ProductTour = ({
  useNavigation,
  productTourCompleted,
  setProductTourCompleted,
  displayBanner,
  setDisplayBanner,
}: TourProps) => {
  const productTourSession: ProductTourSession | string = JSON.parse(
    sessionStorage.getItem('productTourSession') ?? '""',
  );
  const { flagDetails } = useFeatureFlag('ProductTour');
  const isResponsive = useIsResponsive();
  const [tour, setTour] = useState<ProductTourState>({
    stepIndex:
      typeof productTourSession !== 'string'
        ? productTourSession.currentStep
        : 0,
    run:
      typeof productTourSession !== 'string'
        ? productTourSession.displayTour
        : false,
  });

  if (
    productTourSession &&
    typeof productTourSession !== 'string' &&
    productTourCompleted !== true &&
    flagDetails?.enabled
  ) {
    const bannerCloseClickHandler: ProductTourCloseClickHandler = (
      shouldDisplayBanner: boolean,
      completed: boolean,
    ) => {
      setDisplayBanner(shouldDisplayBanner);
      setProductTourCompleted(completed);
      simpleGTMDataLayer({
        event: `CloseButton-click`,
        event_action: 'Close Button Click',
        event_category: 'Close',
        event_label: 'Close Button Click',
        event_category_snowplow: 'Snowplow Close',
      });
    };

    const bannerLearnClickHandler: ProductTourLearnClickHandler = (
      shouldDisplayBanner: boolean,
    ) => {
      setDisplayBanner(shouldDisplayBanner);
      updateProductTourSession({
        ...productTourSession,
        displayTour: true,
      });
      setTour({
        ...tour,
        run: true,
      });
      simpleGTMDataLayer({
        event: `LearnButton-click`,
        event_action: 'Learn Button Click',
        event_category: 'Learn',
        event_label: 'Learn Button Click',
        event_category_snowplow: 'Snowplow Learn',
      });
    };

    const steps: Step[] = [
      {
        target: '.overview-stop-payment-tour',
        content: 'StopPayment',
        placement: isResponsive ? 'auto' : 'bottom',
        disableBeacon: true,
      },
      {
        target: '.overview-my-agent-tour',
        content: 'MyAgent',
        placement: 'auto',
        disableBeacon: true,
      },
    ];

    if (displayBanner) {
      return (
        <ProductTourWrapper test-attr="product-tour-banner">
          <ProductTourBanner
            closeClickHandler={bannerCloseClickHandler}
            learnClickHandler={bannerLearnClickHandler}
          />
        </ProductTourWrapper>
      );
    }

    if (tour.run) {
      document.body.style.overflow = 'hidden';
    }

    if (tour.stepIndex > steps.length - 1 && tour.run) {
      updateProductTourSession({
        ...productTourSession,
        currentPage: 'policy',
        currentStep: tour.stepIndex,
      });

      useNavigation('/policy');
    }

    return (
      <Joyride
        test-attr="product-tour-joyride"
        steps={steps}
        tooltipComponent={Tooltip(tour, productTourSession)}
        run={tour.run}
        continuous
        stepIndex={tour.stepIndex}
        callback={handleJoyrideCallback(
          productTourSession,
          tour,
          setTour,
          setProductTourCompleted,
        )}
        styles={{
          options: {
            zIndex: 10000,
            overlayColor: 'rgba(4, 30, 65, 0.6)',
            arrowColor: 'rgb(255, 246, 233)',
          },
        }}
      />
    );
  }

  return <></>;
};

export default ProductTour;
