import { SAUXTheme } from '@saux/design-system-react';
import styled from 'styled-components';
import customBase from '../../util/global.theme';

export const DesktopContainer = styled.div`
  display: none;

  @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
    display: block;
  }
`;

export const DashLine = styled.div`
  border-right-style: dotted;
  color: #d9dfeb;
  margin-left: 50px;
`;

export const ResponsiveContainer = styled.div`
  display: block;

  @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
    display: none;
  }
`;

export const AlertHolder = styled.div`
  width: auto;
  min-width: 775px;
`;

export const StyledPaymentContainer = styled.div`
  display: flex;
  justify-content: stretch;
  > article {
    &:first-child {
      flex-grow: 1;
      width: 450px;
    }
    &:last-child {
      flex-grow: 1;
    }
  }
  flex-direction: row;
  max-width: 813px;
  @media (min-width: 0px) and (max-width: ${customBase.global.breakpoints
      .medium}px) {
    height: auto;
    margin-right: 0px;
    margin-bottom: 0px;
    flex-direction: column;
    width: 100%;
    max-height: 1200px;
  }
  @media (min-width: ${customBase.global.breakpoints
      .medium}px) and (max-width: ${customBase.global.breakpoints.large}px) {
    width: 100%;
    height: auto;
    margin-right: 0px;
    margin-bottom: 0px;
    width: 100%;
  }
`;

export const StyledSeparator = styled.div`
  padding: 15px;
  @media (min-width: ${customBase.global.breakpoints.medium}px) {
    color: grey;
  }
`;

export const StyledPaymentsDiv = styled.div`
  height: auto;
  width: auto;
  display: flex;
  flex-direction: column;

  @media (min-width: ${customBase.global.breakpoints.medium}px) {
    display: flex;
    flex-direction: row;
  }
`;

export const StyledNextPaymentDiv = styled.div`
  height: auto;
  width: auto;
  display: flex;
  flex-direction: column;
  @media (min-width: ${customBase.global.breakpoints.medium}px) {
    width: 100%;
    min-width: 813px;
  }
`;

export const StyledOverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  @media (min-width: ${customBase.global.breakpoints.medium}px) {
    flex-direction: row;
  }
`;

export const StyledPaymentMethod = styled.div`
  border-top: 1px dashed #a5a7a9;
  width: auto;
  height: 155px;

  @media (min-width: ${customBase.global.breakpoints.medium}px) {
    border-top: none;
  }
`;

export const StyledPolicyContainer = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    width: auto;
    height: auto;
    margin-top: ${theme?.size.spacer.large};

    @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
      margin-top: ${theme?.size.spacer.medium};
    }
  `}
`;

export const StyledRightColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1;
  margin-left: 0px;
  width: 100%;

  @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
    width: 95%;
    margin-left: 20px;
  }
`;

export const StyledLeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0;
  margin-right: none;
  margin-left: none;
`;

export const StyledQuickLinks = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    margin-top: ${theme?.size.spacer.large};
    margin-bottom: 0px;
    width: auto;

    @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
      height: auto;
      max-width: 813px;
      margin-top: 0px;
    }
  `}
`;

export const StyledMyAgency = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    margin-top: ${theme?.size.spacer.medium};
    max-width: 485px;
    width: auto;

    @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
      height: auto;
    }
  `}
`;

export const ResponsiveStyledMyAgency = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    margin-top: ${theme?.size.spacer.large};
  `}
`;

export const GeneralMessageModal = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const LeftGeneralMessageModalContainer = styled.div`
  display: inline;
  flex-direction: row;
  background-color: #ffffff;
`;

export const GeneralMessageModalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid #b5e6f0;
  border-radius: 5px;
  background: #e2f5f9;
  border-left: 5px solid #189ecc;
  color: #005c86;
  align-items: center;
  padding: 15px 20px;
`;

export const InfoIconSizing = styled.div`
  padding-right: 20px;
`;
