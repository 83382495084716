/* eslint-disable */
import axios, { AxiosRequestConfig } from 'axios';

export default async (config: AxiosRequestConfig) => {
  const jwtToken: string | null = sessionStorage.getItem('jwtToken');
  if (config.headers) {
    config.headers.Authorization = jwtToken;
  } else {
    config.headers = {
      Authorization: jwtToken,
    };
  }
  return await axios(config);
};
