import { ApolloQueryResult } from 'apollo-client';
import AWSAppSyncClient from 'aws-appsync';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import {
  GET_OCTO_CARD,
  GET_OCTO_CARDVariables,
  GET_OCTO_CARD_ACCOUNT_ITEMS_POLICIES_ITEMS,
  GET_OCTO_CARD_ACCOUNT_ITEMS_POLICIES_ITEMS_INSUREDASSETS_ITEMS,
} from '../../generated/GET_OCTO_CARD';
import OctoCard from './OctoCard';

interface OctoModalDataContainerProps {
  awsAppSyncClient: AWSAppSyncClient<any>;
  accountNumber: string | undefined;
}

const getOctoModalQuery = loader('../../graphql/queries/Get_Octo_Card.graphql');

const getCompletedOctoModal = (
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string | undefined,
) => {
  const variables: GET_OCTO_CARDVariables = {
    account_number: accountNumber,
  } as GET_OCTO_CARDVariables;

  return awsAppSyncClient.query({
    query: getOctoModalQuery,
    variables,
  }) as Promise<ApolloQueryResult<GET_OCTO_CARD>>;
};

export const containsOctoTelematics = (
  items: (GET_OCTO_CARD_ACCOUNT_ITEMS_POLICIES_ITEMS | null)[],
) => {
  let result: boolean = false;

  items.forEach((item: GET_OCTO_CARD_ACCOUNT_ITEMS_POLICIES_ITEMS | null) => {
    item?.insuredassets?.items?.forEach(
      (
        asset: GET_OCTO_CARD_ACCOUNT_ITEMS_POLICIES_ITEMS_INSUREDASSETS_ITEMS | null,
      ) => {
        if (asset?.telematics_vendor === 'OCTO') {
          result = true;
        }
      },
    );
  });

  return result;
};

const DataContainer = ({
  awsAppSyncClient,
  accountNumber,
}: OctoModalDataContainerProps) => {
  const [displayOctoCard, setDisplayOctoCard] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    getCompletedOctoModal(awsAppSyncClient, accountNumber)
      .then((queryResult: ApolloQueryResult<GET_OCTO_CARD>) => {
        let hasOctoTelematics = false;

        if (queryResult?.data?.account?.items[0]?.policies?.items) {
          hasOctoTelematics = containsOctoTelematics(
            queryResult.data.account.items[0].policies.items,
          );
        }

        setDisplayOctoCard(hasOctoTelematics);
      })
      .catch((err: Error) => {
        console.error('GET_OCTO_CARD ERROR: ', err);
        setError(true);
        setDisplayOctoCard(false);
      });
  }, [accountNumber]);

  return <OctoCard error={error} displayOctoCard={displayOctoCard} />;
};

export default DataContainer;
