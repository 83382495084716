import { SAUXTheme, SAModal } from '@saux/design-system-react';
import styled from 'styled-components';
import customBase from '../../util/global.theme';

interface ModalContentProps {
  modalBodyType: 'default' | 'accident' | 'quest';
  theme: SAUXTheme;
}

export const ModalContent = styled.div<ModalContentProps>`
  ${({ theme }: { theme: SAUXTheme }) =>
    `
    display: block;
    height: 100%;
    width: 100%;
    background-color: ${theme.color.container.background};
  `}

  @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
    ${(props) => {
      let height;
      switch (props.modalBodyType) {
        case 'accident':
          height = 309;
          break;
        case 'quest':
          height = 263;
          break;
        default:
          height = 293;
          break;
      }
      return `
    width: 500px;
    height: ${height}px;
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  `;
    }}
  }
`;

export const RoadsideModal = styled(SAModal)`
  z-index: 2147483647;
  color: #fcebeb;
`;
