import { ApolloQueryResult, FetchResult } from '@apollo/react-hooks';
import AWSAppSyncClient from 'aws-appsync';
import { DocumentNode } from 'graphql';
import { loader } from 'graphql.macro';
import { isFirefox, isSafari } from 'react-device-detect';
import {
  GET_PAYMENT_INFO,
  GET_PAYMENT_INFOVariables,
} from '../../generated/GET_PAYMENT_INFO';
import {
  GET_PAYMENT_URL,
  GET_PAYMENT_URLVariables,
} from '../../generated/GET_PAYMENT_URL';

const getPaymentUrlMutation: DocumentNode = loader(
  '../../graphql/mutation/Get_Payment_Url.graphql',
);
const getUserID: DocumentNode = loader('./graphql/queries/Get_User_Id.graphql');

export function fetchUserID(
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string,
): Promise<ApolloQueryResult<GET_PAYMENT_INFO>> {
  const variables: GET_PAYMENT_INFOVariables = {
    account_number: accountNumber,
  } as GET_PAYMENT_INFOVariables;

  const queryResult: Promise<
    ApolloQueryResult<GET_PAYMENT_INFO>
  > = awsAppSyncClient.query({ query: getUserID, variables }) as Promise<
    ApolloQueryResult<GET_PAYMENT_INFO>
  >;
  return queryResult;
}

export default (
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string,
  userID?: string | null | undefined,
  SSOneINCURL?: boolean | null,
  sourceSystemCode?: string | null | undefined,
) => {
  const requestedBy: Promise<any> | any =
    userID ||
    fetchUserID(awsAppSyncClient, accountNumber).then((data) => {
      return data?.data?.me?.user_id ?? '';
    });
  const variables: GET_PAYMENT_URLVariables = {
    account_number: accountNumber,
    role: 'Customer360',
    pay_my_bill: true,
    mortgageeBillIndicator: false,
    requested_by: requestedBy,
    lob: sourceSystemCode === 'GWPL' ? 'pl' : 'cl',
    awsURLEnabled: !SSOneINCURL,
  } as GET_PAYMENT_URLVariables;
  const mutationResult: Promise<
    FetchResult<GET_PAYMENT_URL>
  > = awsAppSyncClient.mutate({
    mutation: getPaymentUrlMutation,
    fetchPolicy: 'no-cache',
    variables,
  }) as Promise<FetchResult<GET_PAYMENT_URL>>;
  mutationResult
    .then((fetchResult: FetchResult<GET_PAYMENT_URL>) => {
      const oneIncURLRedirect =
        fetchResult?.data?.getOneIncPaymentUrl?.payment_portal_redirect_url;
      if (oneIncURLRedirect) {
        if (!isFirefox && !isSafari) {
          if (window.screen.width <= 750) {
            window.location.assign(oneIncURLRedirect);
          } else {
            window.open(oneIncURLRedirect, '_blank');
          }
        } else if (isFirefox || isSafari) {
          window.location.assign(oneIncURLRedirect);
        }
      } else {
        console.error('payment_portal_redirect_url not returned');
        alert(
          'We’re unable to reach our payment processor right now. We apologize for the inconvenience.',
        );
      }
    })
    .catch((error: Error) => {
      console.error('Get OneInc URL ERROR: ', error);
      alert(
        'We’re unable to reach our payment processor right now. We apologize for the inconvenience.',
      );
    });
};
