import { SAUXTheme } from '@saux/design-system-react';
import styled from 'styled-components';
import customBase from '../../util/global.theme';

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledPaymentMethodWrapper = styled.div`
  ${({ showLastDay }: { showLastDay: boolean }) => `
  padding: 0px 15px;
  min-height: 100px;
  @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
    height: ${showLastDay ? '208px' : '192px'};
  }
`}
`;

export const PaymentMethodHeaderWrapper = styled.div`
  padding-bottom: 10px;
`;

export const FlexColumn = styled.div`
  flex-direction: column;
`;

export const FlexColumnPadded = styled(FlexColumn)`
  padding-left: 10px;
`;

export const StyledBankInfoBox = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    width: auto;
    height: 65px;
    background-color: #f6f8fb;
    border-radius: ${theme.size.radius.medium};
    margin-bottom: ${theme.size.spacer.medium};
  `}
`;

export const StyledPaymentMethodIconWrapper = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    margin: 20px 0px ${theme.size.spacer.small} ${theme.size.spacer.medium};
  `}
`;

export const StyledPlaceholder = styled.div`
  height: 0px;
  width: 0px;
  @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
    width: 100%;
    height: 203px;
  }
`;

export const StyledPrimaryDiv = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    margin-left: auto; 
    padding-right: ${theme.size.spacer.medium};
    padding-top: 13px;
    @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
      margin-left: 0px; 
      padding-left: ${theme.size.spacer.small};
    }
  `}
`;

export const StyledPaymentMethodBankInfoDiv = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    width: 100px;
    padding-left: ${theme.size.spacer.small};
    @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
      width: 170px;
    }
  `}
`;

export const StyledPaymentMethodType = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
    padding: ${theme.size.spacer.small} 0px 0px ${theme.size.spacer.small};
  `}
`;

export const StyledUnknownPaymentMethodType = styled.div`
  padding: 19px;
  min-width: 338px;
  @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
    min-width: 300px;
    text-align: center;
  }
`;
