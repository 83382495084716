import { ApolloQueryResult } from 'apollo-client';
import AWSAppSyncClient from 'aws-appsync';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import {
  GET_DEFAULT_PAYMENT_METHOD,
  GET_DEFAULT_PAYMENT_METHOD_account,
  GET_DEFAULT_PAYMENT_METHOD_account_items,
  GET_DEFAULT_PAYMENT_METHOD_account_items_payplans,
  GET_DEFAULT_PAYMENT_METHOD_account_items_payplans_items,
  GET_DEFAULT_PAYMENT_METHOD_variables,
} from '../../generated/GET_DEFAULT_PAYMENT_METHOD';
import { LastDayToPayInfoProps } from '../../sharedTypes/Overview';
import PaymentMethod, { PayMethod } from './PaymentMethod';

const getPaymentMethodQuery = loader(
  '../../graphql/queries/Get_Default_Payment_Method.graphql',
);

interface PaymentMethodDataContainerProps {
  accountNumber: string;
  awsAppSyncClient: AWSAppSyncClient<any>;
  lastDayToPayInfo: LastDayToPayInfoProps;
}

export function fetchPaymentMethod(
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string,
): Promise<ApolloQueryResult<GET_DEFAULT_PAYMENT_METHOD>> {
  const variables: GET_DEFAULT_PAYMENT_METHOD_variables = {
    account_number: accountNumber,
  } as GET_DEFAULT_PAYMENT_METHOD_variables;
  const queryResult: Promise<
    ApolloQueryResult<GET_DEFAULT_PAYMENT_METHOD>
  > = awsAppSyncClient.query({
    query: getPaymentMethodQuery,
    variables,
  }) as Promise<ApolloQueryResult<GET_DEFAULT_PAYMENT_METHOD>>;
  return queryResult;
}

export function transformData(
  data: GET_DEFAULT_PAYMENT_METHOD | undefined,
): GET_DEFAULT_PAYMENT_METHOD_account_items_payplans_items | null {
  const modelInsuredAccountConnection:
    | GET_DEFAULT_PAYMENT_METHOD_account
    | null
    | undefined = data?.account;
  const insuredAccounts:
    | (GET_DEFAULT_PAYMENT_METHOD_account_items | null)[]
    | null
    | undefined = modelInsuredAccountConnection?.items;
  const modelPayPlanConnection: GET_DEFAULT_PAYMENT_METHOD_account_items_payplans | null =
    insuredAccounts && insuredAccounts[0] ? insuredAccounts[0].payplans : null;
  const payPlans:
    | (GET_DEFAULT_PAYMENT_METHOD_account_items_payplans_items | null)[]
    | null
    | undefined = modelPayPlanConnection?.items;
  const defaultPaymentMethod: string | null | undefined =
    payPlans && payPlans[0] ? payPlans[0].default_payment_method : 'UNKNOWN';
  const paymentMethodType: string | null | undefined =
    payPlans && payPlans[0] ? payPlans[0].payment_method_type : 'UNKNOWN';
  return {
    default_payment_method: defaultPaymentMethod,
    payment_method_type: paymentMethodType,
  } as GET_DEFAULT_PAYMENT_METHOD_account_items_payplans_items | null;
}

export function processPaymentMethodType(
  accountPayPlan: GET_DEFAULT_PAYMENT_METHOD_account_items_payplans_items | null,
): PayMethod {
  const defaultPaymentMethod: string | null | undefined =
    accountPayPlan?.default_payment_method;
  let paymentMethodType: string = 'UNKNOWN';
  switch (accountPayPlan?.payment_method_type) {
    case 'ACH':
      paymentMethodType = 'Bank Account';
      break;
    case 'CREDITCARD':
      paymentMethodType = 'Credit Card';
      break;
    default:
      break;
  }
  return {
    defaultPaymentMethod,
    paymentMethodType,
  } as PayMethod;
}

const PaymentMethodDataContainer = ({
  accountNumber,
  awsAppSyncClient,
  lastDayToPayInfo,
}: PaymentMethodDataContainerProps) => {
  const [paymentMethod, setPaymentMethod] = useState<PayMethod>();
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchPaymentMethod(awsAppSyncClient, accountNumber)
      .then(
        (apolloQueryResult: ApolloQueryResult<GET_DEFAULT_PAYMENT_METHOD>) => {
          const accountPayPlan: GET_DEFAULT_PAYMENT_METHOD_account_items_payplans_items | null = transformData(
            apolloQueryResult.data,
          );
          const accountPaymentMethod: PayMethod = processPaymentMethodType(
            accountPayPlan,
          );
          setPaymentMethod(accountPaymentMethod);
          setLoading(false);
        },
      )
      .catch((err: Error) => {
        console.error('GET_DEFAULT_PAYMENT_METHOD ERROR: ', err);
        setError(err);
        setLoading(false);
      });
  }, [accountNumber]);

  return (
    <>
      <PaymentMethod
        paymentMethod={paymentMethod}
        loading={loading}
        error={error}
        lastDayToPayInfo={lastDayToPayInfo}
      />
    </>
  );
};

export default PaymentMethodDataContainer;
