import { SAText } from '@saux/design-system-react';
import React from 'react';
import { formatPhone, formatTitleCase } from '../../util/formatters';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import {
  Email,
  EmailHeader,
  EmailWrapper,
  MyAgentWrapper,
  Name,
  PhoneEmailWrapper,
  PhoneHeader,
  PhoneNumber,
  PhoneWrapper,
} from './MyAgency.styles';

interface MyAgencyProps {
  agencyInfo?: AgencyInfo | undefined;
  loading?: boolean;
  error?: Error | undefined;
}

export interface AgencyInfo {
  name: string | null | undefined;
  phoneNumber: string | null | undefined;
  email: string | null | undefined;
}

const MyAgency = ({ agencyInfo, loading, error }: MyAgencyProps) => {
  if (loading) {
    return <SAText type="standard" text="Loading..." />;
  }

  if (error) {
    return <SAText type="standard" text="Error" />;
  }
  const agentPhoneNumberLink: string = `tel:${agencyInfo?.phoneNumber}`;
  const agentEmailLink: string = `mailto:${agencyInfo?.email}`;

  return (
    <MyAgentWrapper className="myAgentWrapper">
      <Name>{formatTitleCase(agencyInfo?.name)}</Name>
      <PhoneEmailWrapper>
        <PhoneWrapper
          onClick={() => {
            simpleGTMDataLayer({
              event: `Overview-MyAgentNumber-click`,
              event_action: 'My Agent Phone Click',
              event_category: 'Overview',
              event_label: 'Overview My Agent Number Click',
            });
            simpleGTMDataLayer({
              event: `Snowplow-Overview-MyAgentNumber-click`,
              event_action: 'Snowplow My Agent Phone Click',
              event_category: 'Snowplow Overview',
              event_label: 'Snowplow Overview My Agent Number Click',
            });
          }}
        >
          <PhoneHeader>Phone No.</PhoneHeader>
          {agencyInfo?.phoneNumber === '-' ? (
            <PhoneNumber className="agent-phone-number">
              {agencyInfo?.phoneNumber}
            </PhoneNumber>
          ) : (
            <PhoneNumber
              className="agent-phone-number"
              href={agentPhoneNumberLink}
            >
              {formatPhone(agencyInfo?.phoneNumber)}
            </PhoneNumber>
          )}
        </PhoneWrapper>
        {agencyInfo?.email === '-' ? (
          <span />
        ) : (
          <EmailWrapper
            onClick={() => {
              simpleGTMDataLayer({
                event: `Overview-MyAgentEmail-click`,
                event_action: 'My Agent Email Click',
                event_category: 'Overview',
                event_label: 'Overview My Agent Email Click',
              });
              simpleGTMDataLayer({
                event: `Snowplow-Overview-MyAgentEmail-click`,
                event_action: 'Snowplow My Agent Email Click',
                event_category: 'Snowplow Overview',
                event_label: 'Snowplow Overview My Agent Email Click',
              });
            }}
          >
            <EmailHeader>Email</EmailHeader>
            <Email className="agent-email-address" href={agentEmailLink}>
              {agencyInfo?.email}
            </Email>
          </EmailWrapper>
        )}
      </PhoneEmailWrapper>
    </MyAgentWrapper>
  );
};

MyAgency.defaultProps = {
  agencyInfo: undefined,
  loading: false,
  error: undefined,
};

export default MyAgency;
