import React from 'react';
import ClientProvider from './util/appsyncClient';
import { PageProps } from './sharedTypes/Overview';
import { Pages, ProductTour } from './components';

const Overview = ({ session }: PageProps) => {
  const { accountNumber, awsAppSyncClient, useNavigation } = session;

  return (
    <ClientProvider awsAppSyncClient={session.awsAppSyncClient}>
      <ProductTour
        accountNumber={accountNumber}
        awsAppSyncClient={awsAppSyncClient}
        useNavigation={useNavigation}
      />
      <Pages session={session} />
    </ClientProvider>
  );
};

export default Overview;
