import { SAUXTheme } from '@saux/design-system-react';
import styled from 'styled-components';
import customBase from '../../util/global.theme';

export const ModalBody = styled.div`
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  @media screen and (max-width: ${customBase.global.breakpoints.medium}px) {
    width: 80%;
    height: 75%;
  }
  @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
    width: 780px;
    height: 500px;
  }
`;

export const Title = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
    margin: 25px ${theme?.size?.spacer?.medium} 0px ${theme?.size?.spacer?.medium};
    `;
  }}
`;

export const Content = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
    margin: ${theme?.size?.spacer?.large} ${theme?.size?.spacer?.medium} 0px ${theme?.size?.spacer?.medium};
    `;
  }}
`;

export const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const Close = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
    margin: 50px 22px ${theme?.size?.spacer?.medium} 0px;
    `;
  }}
`;

export const StyledFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
