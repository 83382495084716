import React, { useEffect, useState } from 'react';
import { loader } from 'graphql.macro';
import AWSAppSyncClient from 'aws-appsync';
import { ApolloQueryResult } from 'apollo-client';
import { Policy } from '../policies/Policies';
import FnolRedirectModal, { PolicyDetails } from './FnolRedirectModal';

const generateFnolUuidMutation = loader('./graphql/Generate_FNOL_UUID.graphql');

interface FnolRedirectModalDataContainerProps {
  accountNumber: string;
  awsAppSyncClient: AWSAppSyncClient<any>;
  setModal: Function;
  policies: Policy[];
}

export function generateFnolUUID(
  awsAppSyncClient: AWSAppSyncClient<any>,
  account_number: string,
  policy_number: string,
  policy_name: string,
): Promise<ApolloQueryResult<any>> {
  const variables = {
    account_number,
    policy_number,
    policy_name,
  };

  const queryResult: Promise<ApolloQueryResult<any>> = awsAppSyncClient.mutate({
    mutation: generateFnolUuidMutation,
    variables,
  }) as Promise<ApolloQueryResult<any>>;
  return queryResult;
}

const FnolRedirectModalDataContainer = ({
  accountNumber,
  awsAppSyncClient,
  setModal,
  policies,
}: FnolRedirectModalDataContainerProps) => {
  const [policyDetails, setPolicyDetails] = useState<PolicyDetails>({
    product_code: null,
    policy_number: null,
  });
  const [customFnolUUID, setCustomFnolUUID] = useState<string | undefined>();

  useEffect(() => {
    if (policyDetails.policy_number && policyDetails.product_code) {
      generateFnolUUID(
        awsAppSyncClient,
        accountNumber,
        policyDetails.policy_number,
        policyDetails.product_code,
      )
        .then((result: ApolloQueryResult<any>) => {
          setCustomFnolUUID(
            !result?.data?.fnolUuid?.hasErrors && result?.data?.fnolUuid?.uuid
              ? `uuid=${result?.data?.fnolUuid?.uuid}`
              : ' ',
          );
        })
        .catch(() => {
          console.error('ERROR GENERATING CUSTOM REDIRECT URL');
        });
    }
  }, [policyDetails]);

  return (
    <FnolRedirectModal
      setModal={setModal}
      policies={policies}
      policyDetails={policyDetails}
      setPolicyDetails={setPolicyDetails}
      customFnolUUID={customFnolUUID}
    />
  );
};

export default FnolRedirectModalDataContainer;
