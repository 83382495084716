const formatter = new Intl.NumberFormat('en-US', {
  currency: 'USD',
  style: 'currency',
});

export default (amount: number | string | undefined | null) => {
  if (amount === undefined || amount === null) {
    return null;
  }

  if (amount === 'PAID IN FULL') {
    return '$0.00';
  }

  let numAmount = Number(amount.toString().replace(/[$,]/g, ''));

  if (Number.isNaN(numAmount)) {
    return null;
  }

  if (numAmount === 0) {
    numAmount = Math.abs(numAmount);
  }

  return numAmount < 0
    ? `(${formatter.format(Math.abs(numAmount))})`
    : formatter.format(numAmount);
};
