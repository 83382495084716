import React, { FC } from 'react';
import { SAButton, SAText } from '@saux/design-system-react';
import {
  Backdrop,
  BottomContentWrapper,
  Modal,
  SubTitleWrapper,
  TitleWrapper,
} from './ClaimsDisabledModalStyles';

interface ClaimsDisabledModalProps {
  closeModal: Function;
}

const ClaimsDisabledModal: FC<ClaimsDisabledModalProps> = ({
  closeModal,
}: ClaimsDisabledModalProps) => (
  <Backdrop
    onClick={(e: React.MouseEvent) => {
      e.stopPropagation();
      closeModal(false);
    }}
  >
    <Modal
      onClick={(e: React.MouseEvent) => e.stopPropagation()}
      onTouchMove={(e: React.TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <TitleWrapper>
        <SAText type="heading-2" text="Claims is Currently Unavailable" />
      </TitleWrapper>
      <SubTitleWrapper>
        <SAText
          type="standard"
          colorVariant="default"
          text="Unfortunately, due to server maintenance, submitting and managing a claim online is currently unavailable. In the meantime, please call our Claims Department at "
        />
        <SAText
          test-attr="call-sa-claim-phone-number"
          type="standard"
          weight="bold"
          colorVariant="link"
          text="1-877-SA-CLAIM"
          onClick={() => window.open('tel:18777225246', '_self')}
        />
        <SAText
          type="standard"
          colorVariant="default"
          text=" to submit a claim or check back in 24 to 48 hours."
        />
      </SubTitleWrapper>
      <BottomContentWrapper>
        <SAButton
          test-attr="close-claims-disabled-modal-button"
          label="CLOSE"
          variant="primary"
          fullWidthUnder={576}
          onClick={() => {
            closeModal(false);
          }}
        />
      </BottomContentWrapper>
    </Modal>
  </Backdrop>
);

export default ClaimsDisabledModal;
